import React, { useState } from 'react';
import '../css/search.css';

const Search = ({ inputPlaceholder, initialData, setData, filterCallback }) => {
  const [searchInput, setSearchInput] = useState('');

  const handleSearch = (event) => {
    setSearchInput(event.target.value);
  };

  const search = (event) => {
    event.preventDefault();
    if (!searchInput) {
      setData(initialData);
    }
    if(searchInput === ''){
      setData(initialData);
    }
    let filtered = initialData.filter((element) => {
      return filterCallback(element, searchInput)
      }
    );
    if (filtered.length !== initialData.length) {
      setData(filtered);
    }
  };

  const resetSearch = () => {
    setSearchInput('');
    setData(initialData);
  };

  return (
    <div className="search-container form-container">
      <form onSubmit={search}>
        <label>Search</label>
        <div>
          <div className="relative-pos">
            <input
              placeholder={inputPlaceholder}
              className="search-input"
              onChange={handleSearch}
            />
            <img src="/static/img/search.webp" type="submit" onClick={search} />
          </div>
          <button className="secondary-btn" type="reset" onClick={resetSearch}>
            Reset Search
          </button>
        </div>
      </form>
    </div>
  );
};

export default Search;
