import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import * as sessionsApi from '../../server/api/sessions';
import * as usersApi from '../../server/api/users.js';

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const loggedOutOnly = ['/signup', '/login', '/account-recovery'];
  const [user, setUser] = useState();
  const [email, setEmail] = useState('');
  const [displayName, setDisplayName] = useState('');
  const [userAvatar, setUserAvatar] = useState('');
  const [isMod, setIsMod] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [error, setError] = useState();
  const [authInfo, setAuthInfo] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingInitial, setLoadingInitial] = useState(true);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (error) setError(null);
  }, [location.pathname]);

  useEffect(() => {
    usersApi
      .getCurrentUser()
      .then((user) => {
        setUser(user);
      })
      .catch((err) => console.error(err))
      .finally(() => setLoadingInitial(false));
  }, []);

  useEffect(() => {
    usersApi
      .getProfileData()
      .then(({ username, email, avatar, mod, admin }) => {
        setEmail(email);
        setDisplayName(username);
        setUserAvatar(avatar);
        setIsAdmin(admin);
        setIsMod(mod);
      })
      .catch((err) => console.error(err));
  }, [user]);

  const login = (loginData) => {
    setLoading(true);
    sessionsApi
      .login(loginData)
      .then((data) => {
        if (data.success) {
          setUser(data.user);
          setAuthInfo({});
          if (loginData.redirect && loginData.redirect != '' && !loggedOutOnly.includes(loginData.redirect)) {
            history.push(loginData.redirect);
          } else {
            history.push('/');
          }
        }
        else {
          setAuthInfo(data);
        }
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const signup = (signupData) => {
    setLoading(true);

    usersApi
      .signup(signupData)
      .then((user) => {
        setUser(user);
        history.push('/login');
      })
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  };

  const logout = () => {
    sessionsApi.logout().then(() => setUser(undefined));
  };

  const memoedValue = useMemo(
    () => ({
      user,
      email,
      displayName,
      setDisplayName,
      userAvatar,
      isMod,
      isAdmin,
      loading,
      error,
      authInfo,
      login,
      signup,
      logout,
    }),
    [user, email, displayName, setDisplayName, userAvatar, isMod, isAdmin, loading, error]
  );

  return (
    <AuthContext.Provider value={memoedValue}>
      {!loadingInitial && children}
    </AuthContext.Provider>
  );
};

export default function useAuth() {
  return useContext(AuthContext);
}
