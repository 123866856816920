import React, { useState } from 'react';
import '../css/toggle-switch.css';

const ToggleSwitch = ({ onChange, checked, value, disabled }) => {
  const [isToggled, setIsToggled] = useState(checked);
  const onToggle = (event) => {
    onChange(event);
    setIsToggled(!isToggled);
  };

  return (
    <label className="toggle-switch">
      <input
        className={disabled ? 'disabled' : ''}
        type="checkbox"
        checked={checked}
        onChange={onToggle}
        value={value}
        disabled={disabled}
      />
      <span className="switch" />
    </label>
  );
};

export default ToggleSwitch;
