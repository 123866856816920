import React, { useEffect, useMemo, useState } from 'react';
import { getRandomNumber } from '../../../util/random-number.js';
import '../../css/emote-wall.css';

const ScreenSaverEmote = React.memo((props) => {
  const { eId, emote, maxSize, containerWidth, containerHeight, speed } = props;
  const { size, positionX, positionY, rotationSpeed, rotationDirection } = useMemo(() => ({
    size: getRandomNumber(maxSize / 2, maxSize),
    positionX: getRandomNumber(0, containerWidth - maxSize),
    positionY: getRandomNumber(0, containerHeight - maxSize),
    rotationSpeed: getRandomNumber(-5, 5),
    rotationDirection: Math.random() < 0.5 ? -1 : 1,
  }), [maxSize, containerWidth, containerHeight]);

  const [position, setPosition] = useState({ x: positionX, y: positionY });
  const [direction, setDirection] = useState({ x: getRandomNumber(-5, 5), y: getRandomNumber(-5, 5) });
  const [isFading, setIsFading] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition((prevPosition) => {
        let { x, y } = prevPosition;
        let { x: dirX, y: dirY } = direction;

        x += dirX;
        y += dirY;

        if (x < 0 || x > containerWidth - size) {
          dirX = -dirX;
          setDirection({ ...direction, x: dirX });
        }

        if (y < 0 || y > containerHeight - size) {
          dirY = -dirY;
          setDirection({ ...direction, y: dirY });
        }

        return { x, y };
      });
    }, 10);

    return () => {
      clearInterval(interval);
    };
  }, [containerWidth, containerHeight, size, direction]);

  useEffect(() => {
    const fadeTimeout = setTimeout(() => {
      setIsFading(true);
    }, (speed * 1000) - 1000);

    const removeTimeout = setTimeout(() => {
      document.getElementById(`emote_${eId}`).remove();
    }, speed * 1000);

    return () => {
      clearTimeout(fadeTimeout);
      clearTimeout(removeTimeout);
    };
  }, [eId, speed]);

  return (
    <div
      id={`emote_${eId}`}
      className={`emote-container screensaver ${isFading ? 'fade-out' : ''}`}
      style={{
        top: `${position.y}px`,
        left: `${position.x}px`,
        position: 'absolute',
        willChange: 'transform, opacity',
        transition: 'top 0.1s linear, left 0.1s linear, opacity 1s ease-out'
      }}
    >
      <img
        className="emote"
        src={emote}
        alt=""
        style={{
          animationDuration: `${Math.abs(rotationSpeed)}s`,
          animationIterationCount: 'infinite',
          animationName: `rotateEmote${rotationDirection < 0 ? 'Left' : 'Right'}`,
          maxWidth: `${size}px`,
          maxHeight: `${size}px`,
        }}
      />
    </div>
  );
});

ScreenSaverEmote.displayName = 'ScreenSaverEmote';

export default ScreenSaverEmote;