import React from 'react';
import '../css/card.css';

const Card = ({ title, lineClass, children }) => {
  return (
    <div className="card">
      {title && (
        <span className={'line ' + lineClass}>
          <h2>
            <span className="card-title">{title}</span>
          </h2>
        </span>
      )}
      <div className="card-body">{children}</div>
    </div>
  );
};

export default Card;
