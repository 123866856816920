import React from 'react';
import '../css/link-overlay.css';
import { useParams } from 'react-router-dom';

const LinkOverlay = ({ id = 'link-overlay', username = null }) => {
  if (!username) username = useParams().username;

  return (
    <div className="overlay-container" id={id}>
      <div className="flex-row powerchat-link">
        <img src='/static/img/powerchat-full-text-logo.png' width={300} />
        <div className="username">{'/' + username}</div>
      </div>
    </div>
  );
};

export default LinkOverlay;
