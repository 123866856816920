import React, { useMemo, useRef, useState } from 'react';
import { getRandomNumber } from '../../../util/random-number.js';
import ExplosionEmote from './explosion-emote.jsx';

const FireworksEmote = (props) => {
  const { eId, emote, maxSize, containerWidth, containerHeight, speed, containerRef } = props;
  
  const [showExplosion, setShowExplosion] = useState(false);
  const emoteElementRef = useRef(null);

  const emoteStyles = useMemo(() => {
    const size = getRandomNumber(maxSize / 2, maxSize);
    const startingPositionX = containerWidth / 2 - size / 2; 
    const startingPositionY = containerHeight - size; 
    const middlePositionY = containerHeight / 2 - size / 2; 
    const animationSpeed = `${speed}s`;

    return {
      size,
      startingPositionX,
      startingPositionY,
      middlePositionY,
      animationSpeed
    };
  }, [maxSize, containerWidth, containerHeight, speed]);

  const handleAnimationEnd = (e) => {
    if (e.target === emoteElementRef.current) {
      setShowExplosion(true);
      // Remove the FireworksEmote from the DOM
      e.target.remove();
    }
  };

  return (
    <div>
      <div
        ref={emoteElementRef}
        id={`emote_${eId}`}
        className="emote-container fireworks"
        onAnimationEnd={handleAnimationEnd}
        style={{
          maxWidth: `${emoteStyles.size}px`,
          maxHeight: `${emoteStyles.size}px`,
          left: `${emoteStyles.startingPositionX}px`,
          bottom: `0px`,
          animationDuration: emoteStyles.animationSpeed,
          animationName: 'fireworksLiftoff',
          position: 'absolute'
        }}
      >
        <img
          className="emote"
          src={emote}
          alt=""
          style={{
            maxWidth: `${emoteStyles.size}px`,
            maxHeight: `${emoteStyles.size}px`,
            animationDuration: `${speed}s`,
            animationIterationCount: 'infinite',
            animationName: 'fadeAndGrow'
          }}
        />
      </div>
      {showExplosion && <ExplosionEmote eId={eId} emote={emote} maxSize={maxSize} containerWidth={containerWidth} containerHeight={containerHeight} speed={speed} containerRef={containerRef} />}
    </div>
  );  
};

export default FireworksEmote;
