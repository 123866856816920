import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/external-integrations.css';
import '../css/settings-form.css';
import CurrencyInput from 'react-currency-input-field';
import FormAlert from './form-alert';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faInfoCircle,
  faCheckSquare,
  faTimesSquare,
  faChevronDown,
  faChevronUp,
  faLink,
  faLinkSlash,
  faUpRightFromSquare
} from '@fortawesome/free-solid-svg-icons';
import FadeLoader from 'react-spinners/FadeLoader';
import ToggleSwitch from './toggle-switch';

// External Integrations = Streaming platform links + external tts links
const ExternalIntegrations = ({ updateData, isMod = false, ...props }) => {
  const [alertState, setAlertState] = useState({
    trovo: {
      message: '',
      success: true,
      show: false,
    },
    kick: {
      message: '',
      success: true,
      show: false,
    },
    cozy: {
      message: '',
      success: true,
      show: false,
    },
    robotstreamer: {
      message: '',
      success: true,
      show: false,
    },
    twitch: {
      message: '',
      success: true,
      show: false,
    },
    youtube: {
      message: '',
      success: true,
      show: false,
    },
    rumble: {
      message: '',
      success: true,
      show: false,
    },
    dlive: {
      message: '',
      success: true,
      show: false,
    },
  });
  const [formData, setFormData] = useState({
    ...props.robotstreamerSettings,
    ...props.cozySettings,
    streamlabsLinked: !!props.slAccessToken,
    trovoLinked: !!props.trovoSettings && !!props.trovoSettings.channelId,
    ...props.trovoSettings,
    kickLinked: !!props.kickSettings && !!props.kickSettings.kickChannelId,
    ...props.kickSettings,
    twitchLinked: !!props.twitchSettings && !!props.twitchSettings.twitchId,
    ...props.twitchSettings, 
    youtubeLinked: !!props.youtubeSettings && !!props.youtubeSettings.channelId,
    ...props.youtubeSettings,
    dliveLinked: !!props.dliveSettings && !!props.dliveSettings.dliveUsername,
    ...props.dliveSettings,
    ...props.overlaySettings,
    rumbleLinked: !!props.rumbleSettings && !!props.rumbleSettings.rumbleChannelName,
    ...props.rumbleSettings,
  });

  const [tooltip, setTooltip] = useState('');
  const [showOptions, setShowOptions] = useState({
    trovo: false,
    kick: false, 
    cozy: false,
    twitch:false,
    youtube: false,
    rumble: false,
    dlive: false,
    robotstreamer: false,
  });
  const [loading, setLoading] = useState({
    rumble: false,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const triggerAlert = (section, message, success = true) => {
    setAlertState({
      ...alertState,
      [section]: {
        message,
        success,
      },
    });
  };

  const handleToggle = (name) => {
    const value = formData[name] ? false : true;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const saveDlive = async (event) => {
    event.preventDefault();
    try {
      await axios.put('/api/dlive-settings', formData);
      updateData(formData);
      triggerAlert('dlive', 'Dlive settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('dlive', 'Could not update Dlive settings', false);
    }
  };

  const saveYoutubeSettings = async (event) => {
    event.preventDefault();
    try {
      await axios.put('/api/youtube-settings', formData);
      
      updateData(formData);
      triggerAlert('youtube', 'YouTube settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('youtube', 'Could not update YouTube settings', false);
    }
  };

  const saveTrovo = async (event) => {
    event.preventDefault();
    try {
      await axios.put('/api/trovo-settings', formData);

      updateData(formData);
      triggerAlert('trovo', 'Trovo settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('trovo', 'Could not update Trovo settings', false);
    }
  };

  const [isKickSaving, setIsKickSaving] = useState(false);
  const saveKick = async (event) => {
    event.preventDefault();
    setIsKickSaving(true); // Disable the submit button
    try {
      await axios.put('/api/kick-settings', formData);
  
      const lValue = formData.kickChannelId ? true : false;
      setFormData({
        ...formData,
        kickLinked: lValue,
      });
  
      updateData(formData);
      triggerAlert('kick', 'Kick settings successfully updated');
    } catch (err) {
      console.error(err);
      const errorMessage = (err.response && err.response.data)
                           ? err.response.data
                           : '';
      triggerAlert('kick', `Could not update Kick settings. ${errorMessage}`, false);
    } finally {
      setTimeout(() => {
        setIsKickSaving(false); // Re-enable the submit button
      }, 3000); // Wait for 3 seconds before re-enabling the submit button
    }
  };

  const saveRobotstreamer = async (event) => {
    event.preventDefault();
    try {
      if (formData.robotId && formData.robotId.length > 0 && (isNaN(formData.robotId) || formData.robotId < 1)) {
        throw new Error('Robot Id must be a positive number.');
      }

      await axios.put('/api/robotstreamer-settings', formData);

      updateData(formData);
      triggerAlert('robotstreamer', 'Robotstreamer ID successfully updated');
    } catch (err) {
      console.error('robotstreamer:', err);
      triggerAlert('robotstreamer', `Could not update Robotstreamer. ${err.message}`, false);
    }
  };

  const saveCozy = async (event) => {
    event.preventDefault();
    try {
      await axios.put('/api/cozy', formData);

      updateData(formData);
      triggerAlert('cozy', 'Cozy.tv settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('cozy', 'Could not update Cozy.tv username', false);
    }
  };

  const saveTwitch = async (event) => {
    event.preventDefault();
    try {
      await axios.put('/api/twitch-settings', formData);

      updateData(formData);
      triggerAlert('twitch', 'Twitch settings successfully updated');
    } catch (err) {
      console.error(err);
      triggerAlert('twitch', 'Could not update Twitch settings', false);
    }
  };

  const [enableRumbleSave, setEnableRumbleSave] = useState(true);
  const saveRumble = async (event) => {
    event.preventDefault();
    setEnableRumbleSave(false);
    setLoading({
      ...loading,
      rumble: true
    });
    try {
      let { data } = await axios.put('/api/rumble-settings', formData);

      setFormData({
        ...formData,
        ...data,
      });

      triggerAlert('rumble', 'Rumble settings successfully updated');
    } catch (err) {
      console.error(err);
      if (err.response.data == 'NotExists') {
        triggerAlert('rumble', formData.rumbleChannelName + ' is not a valid Rumble channel', false);
      } else {
        triggerAlert('rumble', 'Could not update Rumble settings', false);
      }
    } finally {
      setLoading({
        ...loading,
        rumble: false,
      });
      setTimeout(() => {
        setEnableRumbleSave(true);
      }, 3000);
    }
  };

  const toggleShowOptions = (name) => {
    setShowOptions({
      ...showOptions,
      [name]: !showOptions[name],
    });
  };


  const linkStreamlabs = async () => {
    window.location.href = '/api/streamlabs_link';
  };

  const unlinkStreamlabs = async () => {
    const data = await axios.get('/api/streamlabs_unlink');
    if (data) {
      setFormData({
        ...formData,
        streamlabsLinked: false,
      });
      updateData({ streamlabsLinked: false });
    }
  };

  const linkDlive = async () => {
    location.href = '/pubapi/dlive/auth';
  };

  const unlinkDlive = async () => {
    await axios.delete('/api/dlive');
    setFormData({
      ...formData,
      dliveLinked: false,
    });
    updateData({ dliveChannelId: '' });
  };

  const linkYoutube = () => {
    location.href = '/pubapi/youtube/auth';
  };

  const unlinkYoutube = async () => {
    await axios.delete('/api/youtube');
    setFormData({
      ...formData,
      youtubeLinked: false,
    });
    updateData({ youtubeChannelId: '' });
  };

  const linkTrovo = () => {
    location.href = '/pubapi/trovo/auth';
  };

  const unlinkTrovo = async () => {
    await axios.delete('/api/trovo');
    setFormData({
      ...formData,
      trovoLinked: false,
    });
    updateData({ trovoChannelId: '' });
  };

  const linkTwitch = () => {
    location.href = '/pubapi/twitch/auth';
  };

  const unlinkTwitch = async () => {
    await axios.delete('/api/twitch');
    setFormData({
      ...formData,
      twitchLinked: false,
    });
    updateData({ twitchChannelId: '' });
  };

  const spinnerStyle = {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  };

  return (
    <div className="external-integrations">
      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle' + (formData.youtubeLinked ? '' : ' disabled')
            }
            onClick={() => {
              if (formData.youtubeLinked) toggleShowOptions('youtube');
            }}
          >
            {showOptions.youtube ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img style={{ maxWidth: '32px' }} className="icon" src="/static/img/youtube-icon.webp" />
            YouTube:&nbsp;
            {formData.youtubeLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.youtubeLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkYoutube}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkYoutube}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
        </div>
        {formData.youtubeLinked && showOptions.youtube && (
          <form onSubmit={saveYoutubeSettings}>
            <div className="options form-container">
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableYoutubeAllAlerts"
                    onChange={() => handleToggle('enableYoutubeAllAlerts')}
                    checked={formData.enableYoutubeAllAlerts}
                    disabled={isMod}
                  />
                  <label htmlFor="enableYoutubeAllAlerts">Enable Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableYoutubeChats"
                    onChange={() => handleToggle('enableYoutubeChats')}
                    checked={formData.enableYoutubeChats}
                    disabled={isMod}
                  />
                  <label htmlFor="enableYoutubeChats">Enable YouTube Chats</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="showOnYoutube"
                    onChange={() => handleToggle('showOnYoutube')}
                    checked={formData.showOnYoutube}
                    disabled={isMod}
                  />
                  <label htmlFor="showOnYoutube">Show &apos;on YouTube&apos; in alerts</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('showOnYoutube')}
                    />
                    {tooltip === 'showOnYoutube' && (
                      <p className="tooltip shadow-lg">
                        Suffixes all alerts with &apos;on YouTube&apos;, e.g. &apos;Bob just subscribed on YouTube&apos;
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="viewCountYoutubeViewers"
                    onChange={() => handleToggle('viewCountYoutubeViewers')}
                    checked={formData.viewCountYoutubeViewers}
                    disabled={isMod}
                  />
                  <label htmlFor="viewCountYoutubeViewers">Show in view count overlay</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountYoutubeViewers')}
                    />
                    {tooltip === 'viewCountYoutubeViewers' && (
                      <p className="tooltip shadow-lg">
                        When enabled, the view count overlay will show the number of viewers on your YouTube stream.<br />
                        <br />
                        Note: You can adjust how the view count overlay looks in the Settings page.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableSubscribeAlerts"
                    onChange={() => handleToggle('enableSubscribeAlerts')}
                    checked={formData.enableSubscribeAlerts}
                    disabled={isMod}
                  />
                  <label htmlFor="enableYoutubeChats">Enable Subscribe Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="subscribersAddToGoal"
                    onChange={() => handleToggle('subscribersAddToGoal')}
                    checked={formData.subscribersAddToGoal}
                    disabled={isMod}
                  />
                  <label htmlFor="subscribersAddToGoal">Subscribers Add to Goal</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableMemberAlerts"
                    onChange={() => handleToggle('enableMemberAlerts')}
                    checked={formData.enableMemberAlerts}
                    disabled={isMod}
                  />
                  <label htmlFor="enableMemberAlerts">Enable Member Join Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="membersAddToGoal"
                    onChange={() => handleToggle('membersAddToGoal')}
                    checked={formData.membersAddToGoal}
                    disabled={isMod}
                  />
                  <label htmlFor="membersAddToGoal">Members Add to Goal</label>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableSuperchats"
                    onChange={() => handleToggle('enableSuperchats')}
                    checked={formData.enableSuperchats}
                    disabled={isMod}
                  />
                  <label htmlFor="enableSuperchats">Enable Superchats</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="superchatsAddToGoal"
                    onChange={() => handleToggle('superchatsAddToGoal')}
                    checked={formData.superchatsAddToGoal}
                    disabled={isMod}
                  />
                  <label htmlFor="superchatsAddToGoal">Superchats Add to Goal</label>
                </div>
                <div style={{maxWidth: 500}}>
                  <label htmlFor="superchatMin">Superchat TTS Minimum</label>
                  <CurrencyInput
                    name="superchatMin"
                    placeholder="Superchat TTS Minimum"
                    value={formData.superchatMin || ''}
                    prefix="$"
                    intlConfig={{ locale: 'en-US', currency: 'USD' }}
                    allowNegativeValue={false}
                    decimalScale={2}
                    onValueChange={(value, name) =>
                      handleChange({ target: { value, name } })
                    }
                    disabled={isMod}
                  />
                </div>
              </div>
            </div>
            {!isMod && <button type="submit">Save</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.youtube}
              width={400}
            />
          </form>
        )}
      </div>

      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle' + (formData.twitchLinked ? '' : ' disabled')
            }
            onClick={() => {
              if (formData.twitchLinked) toggleShowOptions('twitch');
            }}
          >
            {showOptions.twitch ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/twitch.svg" />
            Twitch.tv&nbsp;
            {formData.twitchLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.twitchLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkTwitch}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkTwitch}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
        </div>
        {formData.twitchLinked && showOptions.twitch && (
          <form onSubmit={saveTwitch}>
            <div className="options form-container">
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableTwitchChats"
                    onChange={() => handleToggle('enableTwitchChats')}
                    checked={formData.enableTwitchChats}
                    disabled={isMod}
                  />
                  <label htmlFor="enableTwitchChats">Enable Twitch Chats</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableTwitchAllAlerts"
                    onChange={() => handleToggle('enableTwitchAllAlerts')}
                    checked={formData.enableTwitchAllAlerts}
                    disabled={isMod}
                  />
                  <label htmlFor="enableTwitchAllAlerts">Enable All Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="showOnTwitch"
                    onChange={() => handleToggle('showOnTwitch')}
                    disabled={!formData.enableTwitchAllAlerts || isMod}
                    checked={formData.showOnTwitch}
                  />
                  <label htmlFor="showOnTwitch">Show &apos;on Twitch&apos; in alerts</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('showOnTwitch')}
                    />
                    {tooltip === 'showOnTwitch' && (
                      <p className="tooltip shadow-lg">
                        Suffixes all alerts with &apos;on Twitch&apos;, e.g. &apos;Bob followed on Twitch&apos;, &apos;Alice just subscribed on Twitch&apos;
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="viewCountTwitchViewers"
                    onChange={() => handleToggle('viewCountTwitchViewers')}
                    checked={formData.viewCountTwitchViewers}
                    disabled={isMod}
                  />
                  <label htmlFor="viewCountTwitchViewers">Show in view count overlay</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountTwitchViewers')}
                    />
                    {tooltip === 'viewCountTwitchViewers' && (
                      <p className="tooltip shadow-lg">
                        When enabled, the view count overlay will show the number of viewers on your Twitch stream.<br />
                        <br />
                        Note: You can adjust how the view count overlay looks in the Settings page.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableTwitchFollowAlerts"
                    onChange={() => handleToggle('enableTwitchFollowAlerts')}
                    disabled={!formData.enableTwitchAllAlerts || isMod}
                    checked={formData.enableTwitchFollowAlerts}
                  />
                  <label htmlFor="enableTwitchFollowAlerts">
                    Enable Follow Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="twitchFollowsAddToGoal"
                    onChange={() => handleToggle('twitchFollowsAddToGoal')}
                    disabled={!formData.enableTwitchAllAlerts || isMod}
                    checked={formData.twitchFollowsAddToGoal}
                  />
                  <label htmlFor="twitchFollowsAddToGoal">Followers Add to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('twitchFollowsAddToGoal')}
                    />
                    {tooltip === 'twitchFollowsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        Twitch follows will increment the follower goal. Follow alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableTwitchSubscribeAlerts"
                    onChange={() => handleToggle('enableTwitchSubscribeAlerts')}
                    disabled={!formData.enableTwitchAllAlerts || isMod}
                    checked={formData.enableTwitchSubscribeAlerts}
                  />
                  <label htmlFor="enableTwitchSubscribeAlerts">
                    Enable Subscribe Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="twitchSubsAddToGoal"
                    onChange={() => handleToggle('twitchSubsAddToGoal')}
                    disabled={!formData.enableTwitchAllAlerts || isMod}
                    checked={formData.twitchSubsAddToGoal}
                  />
                  <label htmlFor="twitchSubsAddToGoal">Subscribers Add to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('twitchSubsAddToGoal')}
                    />
                    {tooltip === 'twitchSubsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        Twitch subscribers will increment the subscription goal. Subscribe alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableTwitchBits"
                    onChange={() => handleToggle('enableTwitchBits')}
                    disabled={!formData.enableTwitchAllAlerts || isMod}
                    checked={formData.enableTwitchBits}
                  />
                  <label htmlFor="enableTwitchBits">
                    Enable Bits Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="playTwitchBitsTts"
                    onChange={() => handleToggle('playTwitchBitsTts')}
                    disabled={
                      !formData.enableTwitchAllAlerts || !formData.enableTwitchBits || isMod
                    }
                    checked={formData.playTwitchBitsTts}
                  />
                  <label htmlFor="playTwitchBitsTts">
                    Play Bits message with TTS
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="twitchBitsAddToGoal"
                    onChange={() => handleToggle('twitchBitsAddToGoal')}
                    disabled={
                      !formData.enableTwitchAllAlerts || !formData.enableTwitchBits || isMod
                    }
                    checked={formData.twitchBitsAddToGoal}
                  />
                  <label htmlFor="twitchBitsAddToGoal">Bits Adds to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('twitchBitsAddToGoal')}
                    />
                    {tooltip === 'twitchBitsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        Dollar amount of bits will be added to donation
                        goal. Bits alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableTwitchRaidAlerts"
                    onChange={() => handleToggle('enableTwitchRaidAlerts')}
                    disabled={!formData.enableTwitchAllAlerts || isMod}
                    checked={formData.enableTwitchRaidAlerts}
                  />
                  <label htmlFor="enableTwitchRaidAlerts">
                    Enable Raid Alerts
                  </label>
                </div>
              </div>
              <div className="options-section" style={{ flex: 1 }}>
                <label htmlFor="playTwitchBitsMinimum">Bits TTS Minimum</label>
                <input
                  name="playTwitchBitsMinimum"
                  placeholder="Bits TTS Minimum"
                  onChange={handleChange}
                  disabled={
                    !formData.enableTwitchAllAlerts || !formData.enableTwitchBits || isMod
                  }
                  value={formData.playTwitchBitsMinimum}
                />
              </div>
            </div>
            {!isMod && <button type="submit">Save</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.twitch}
              width={400}
            />
          </form>
        )}
      </div>

      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle'
            }
            onClick={() => {
              toggleShowOptions('rumble');
            }}
          >
            {showOptions.rumble ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/rumble-logo.webp" />
            Rumble&nbsp;
            {formData.rumbleLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
        </div>
        {showOptions.rumble && (
          <form onSubmit={saveRumble}>
            <div className="options form-container">
              <div className="options-section">
                <div className="input-container">
                  <input style={{width:244}}
                    name="rumbleChannelName"
                    placeholder="Rumble Channel Name"
                    onChange={handleChange}
                    value={formData.rumbleChannelName || ""}
                    disabled={isMod}
                  />
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('rumbleChannelName')}
                    />
                    {tooltip === 'rumbleChannelName' && (
                      <p className="tooltip shadow-lg">
                        Copy and paste your full Rumble URL (&quot;<b>https://rumble.com/user/MyUsername</b>&quot;
                          or &quot;<b>https://rumble.com/c/MyChannelName</b>&quot;).
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableRumbleChats"
                    onChange={() => handleToggle('enableRumbleChats') }
                    checked={formData.enableRumbleChats}
                    disabled={isMod}
                  />
                  <label htmlFor="enableRumbleChats">Enable Rumble Chats</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableAllRumbleAlerts"
                    onChange={() => handleToggle('enableAllRumbleAlerts') }
                    checked={formData.enableAllRumbleAlerts}
                    disabled={isMod}
                  />
                  <label htmlFor="enableAllRumbleAlerts">Enable All Rumble Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="showOnRumble"
                    disabled={!formData.enableAllRumbleAlerts || isMod}
                    onChange={() => handleToggle('showOnRumble')}
                    checked={formData.showOnRumble}
                  />
                  <label htmlFor="showOnRumble">Show &apos;on Rumble&apos; in alerts</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('showOnRumble')}
                    />
                    {tooltip === 'showOnRumble' && (
                      <p className="tooltip shadow-lg">
                        Suffixes all alerts with &apos;on Rumble&apos;, e.g. &apos;Alice just subscribed on Rumble&apos;
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="viewCountRumbleViewers"
                    onChange={() => handleToggle('viewCountRumbleViewers')}
                    checked={formData.viewCountRumbleViewers}
                    disabled={isMod}
                  />
                  <label htmlFor="viewCountRumbleViewers">Show in view count overlay</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountRumbleViewers')}
                    />
                    {tooltip === 'viewCountRumbleViewers' && (
                      <p className="tooltip shadow-lg">
                        When enabled, the view count overlay will show the number of viewers on your Rumble stream.<br />
                        <br />
                        Note: You can adjust how the view count overlay looks in the Settings page.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableRumbleRants"
                    disabled={!formData.enableAllRumbleAlerts || isMod}
                    onChange={() => handleToggle('enableRumbleRants') }
                    checked={formData.enableRumbleRants}
                  />
                  <label htmlFor="enableRumbleRants">Play Rants with TTS</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="rantsAddToGoal"
                    disabled={!formData.enableAllRumbleAlerts || isMod}
                    onChange={() => handleToggle('rantsAddToGoal') }
                    checked={formData.rantsAddToGoal}
                  />
                  <label htmlFor="rantsAddToGoal">Rants Add to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('rantsAddToGoal')}
                    />
                    {tooltip === 'rantsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        Rumble Rants will increment the donation goal. Rant TTS does not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableRumbleSubscribeAlerts"
                    disabled={!formData.enableAllRumbleAlerts || isMod}
                    onChange={() => handleToggle('enableRumbleSubscribeAlerts') }
                    checked={formData.enableRumbleSubscribeAlerts}
                  />
                  <label htmlFor="enableRumbleSubscribeAlerts">Enable Subscribe Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="rumbleSubsAddToGoal"
                    disabled={!formData.enableAllRumbleAlerts || isMod}
                    onChange={() => handleToggle('rumbleSubsAddToGoal') }
                    checked={formData.rumbleSubsAddToGoal}
                  />
                  <label htmlFor="rumbleSubsAddToGoal">Subscribers Add to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('rumbleSubsAddToGoal')}
                    />
                    {tooltip === 'rumbleSubsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        Rumble subscribers will increment the subscription goal. Subscribe alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!isMod && <button id="rumbleSubmitBtn" type="submit" disabled={!enableRumbleSave}>Save</button>}
            <div style={spinnerStyle}>
              <FadeLoader color="#ffffff" loading={loading.rumble} size={20} />
            </div>
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.rumble}
              width={400}
            />
          </form>
        )}
      </div>

      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle' + (formData.trovoLinked ? '' : ' disabled')
            }
            onClick={() => {
              if (formData.trovoLinked) toggleShowOptions('trovo');
            }}
          >
            {showOptions.trovo ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/trovo-icon.webp" />
            Trovo:&nbsp;
            {formData.trovoLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.trovoLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkTrovo}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkTrovo}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
        </div>
        {formData.trovoLinked && showOptions.trovo && (
          <form onSubmit={saveTrovo}>
            <div className="options form-container">
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableAllAlerts"
                    onChange={() => handleToggle('enableAllAlerts')}
                    checked={formData.enableAllAlerts}
                    disabled={isMod}
                  />
                  <label htmlFor="enableAllAlerts">Enable Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableTrovoChats"
                    onChange={() => handleToggle('enableTrovoChats')}
                    checked={formData.enableTrovoChats}
                    disabled={isMod}
                  />
                  <label htmlFor="enableTrovoChats">Enable Trovo Chats</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="viewCountTrovoViewers"
                    onChange={() => handleToggle('viewCountTrovoViewers')}
                    checked={formData.viewCountTrovoViewers}
                    disabled={isMod}
                  />
                  <label htmlFor="viewCountTrovoViewers">Show in view count overlay</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountTrovoViewers')}
                    />
                    {tooltip === 'viewCountTrovoViewers' && (
                      <p className="tooltip shadow-lg">
                        When enabled, the view count overlay will show the number of viewers on your Trovo stream.<br />
                        <br />
                        Note: You can adjust how the view count overlay looks in the Settings page.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="showOnTrovo"
                    onChange={() => handleToggle('showOnTrovo')}
                    disabled={!formData.enableAllAlerts || isMod}
                    checked={formData.showOnTrovo}
                  />
                  <label htmlFor="showOnTrovo">Show &apos;on Trovo&apos; in alerts</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('showOnTrovo')}
                    />
                    {tooltip === 'showOnTrovo' && (
                      <p className="tooltip shadow-lg">
                        Suffixes all alerts with &apos;on Trovo&apos;, e.g. &apos;Bob cast Stay
                        Safe on Trovo&apos;, &apos;Alice just subscribed on Trovo&apos;
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableFollowAlerts"
                    onChange={() => handleToggle('enableFollowAlerts')}
                    disabled={!formData.enableAllAlerts || isMod}
                    checked={formData.enableFollowAlerts}
                  />
                  <label htmlFor="enableFollowAlerts">
                    Enable Follow Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="followsAddToGoal"
                    onChange={() => handleToggle('followsAddToGoal')}
                    disabled={!formData.enableAllAlerts || isMod}
                    checked={formData.followsAddToGoal}
                  />
                  <label htmlFor="followsAddToGoal">Followers Add to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('followsAddToGoal')}
                    />
                    {tooltip === 'followsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        Trovo follows will increment the follower goal. Follow alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableSubscribeAlerts"
                    onChange={() => handleToggle('enableSubscribeAlerts')}
                    disabled={!formData.enableAllAlerts || isMod}
                    checked={formData.enableSubscribeAlerts}
                  />
                  <label htmlFor="enableSubscribeAlerts">
                    Enable Subscribe Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="subsAddToGoal"
                    onChange={() => handleToggle('subsAddToGoal')}
                    disabled={!formData.enableAllAlerts || isMod}
                    checked={formData.subsAddToGoal}
                  />
                  <label htmlFor="subsAddToGoal">Subscribers Add to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('subsAddToGoal')}
                    />
                    {tooltip === 'subsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        New Trovo subscribers will increment the subscription
                        goal. Subscribe alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableManaSpells"
                    onChange={() => handleToggle('enableManaSpells')}
                    disabled={!formData.enableAllAlerts || isMod}
                    checked={formData.enableManaSpells}
                  />
                  <label htmlFor="enableManaSpells">Enable Mana Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="playManaTts"
                    onChange={() => handleToggle('playManaTts')}
                    disabled={
                      !formData.enableAllAlerts || !formData.enableManaSpells || isMod
                    }
                    checked={formData.playManaTts}
                  />
                  <label htmlFor="playManaTts">
                    Play Mana message with TTS
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableElixirSpells"
                    onChange={() => handleToggle('enableElixirSpells')}
                    disabled={!formData.enableAllAlerts || isMod}
                    checked={formData.enableElixirSpells}
                  />
                  <label htmlFor="enableElixirSpells">
                    Enable Elixir Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="playElixirTts"
                    onChange={() => handleToggle('playElixirTts')}
                    disabled={
                      !formData.enableAllAlerts || !formData.enableElixirSpells || isMod
                    }
                    checked={formData.playElixirTts}
                  />
                  <label htmlFor="playElixirTts">
                    Play Elixir message with TTS
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="elixirAddsToGoal"
                    onChange={() => handleToggle('elixirAddsToGoal')}
                    disabled={
                      !formData.enableAllAlerts || !formData.enableElixirSpells || isMod
                    }
                    checked={formData.elixirAddsToGoal}
                  />
                  <label htmlFor="elixirAddsToGoal">Elixir Adds to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('elixirAddsToGoal')}
                    />
                    {tooltip === 'elixirAddsToGoal' && (
                      <p className="tooltip shadow-lg">
                        Dollar amount of Elixir spells will be added to donation
                        goal. Elixir alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section" style={{ flex: 1 }}>
                <label htmlFor="playManaMinimum">Mana TTS Minimum</label>
                <input
                  name="playManaMinimum"
                  placeholder="Mana TTS Minimum"
                  onChange={handleChange}
                  disabled={
                    !formData.enableAllAlerts ||
                    !formData.enableManaSpells ||
                    !formData.playManaTts ||
                    isMod
                  }
                  value={formData.playManaMinimum}
                />
                <label htmlFor="playElixirMinimum">Elixir TTS Minimum</label>
                <input
                  name="playElixirMinimum"
                  placeholder="Elixir TTS Minimum"
                  onChange={handleChange}
                  disabled={
                    !formData.enableAllAlerts ||
                    !formData.enableElixirSpells ||
                    !formData.playElixirTts ||
                    isMod
                  }
                  value={formData.playElixirMinimum}
                />
              </div>
            </div>
            {!isMod && <button type="submit">Save</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.trovo}
              width={400}
            />
          </form>
        )}
      </div>

      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle'
            }
            onClick={() => {
              toggleShowOptions('kick');
            }}
          >
            {showOptions.kick ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4>
            <img className="icon" src="/static/img/kick-logo.webp" />
            Kick.com&nbsp;
            {formData.kickLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
        </div>
        {showOptions.kick && (
          <form onSubmit={saveKick}>
            <div className="options form-container">
              <div className="options-section">
                <input
                  name="kickChannelId"
                  placeholder="Kick Channel Name"
                  onChange={handleChange}
                  value={formData.kickChannelId || ""}
                  disabled={isMod}
                />
                <div className="toggle-container">
                  <ToggleSwitch
                    name="viewCountKickViewers"
                    onChange={() => handleToggle('viewCountKickViewers')}
                    checked={formData.viewCountKickViewers}
                    disabled={isMod}
                  />
                  <label htmlFor="viewCountKickViewers">Show in view count overlay</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountKickViewers')}
                    />
                    {tooltip === 'viewCountKickViewers' && (
                      <p className="tooltip shadow-lg">
                        When enabled, the view count overlay will show the number of viewers on your Kick stream.<br />
                        <br />
                        Note: You can adjust how the view count overlay looks in the Settings page.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableKickChats"
                    onChange={() => handleToggle('enableKickChats') }
                    checked={formData.enableKickChats || false}
                    disabled={isMod}
                  />
                  <label htmlFor="enableKickChats">Enable Kick Chats</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableKickAlerts"
                    onChange={() => handleToggle('enableKickAlerts')}
                    checked={formData.enableKickAlerts}
                    disabled={isMod}
                  />
                  <label htmlFor="enableKickAlerts">Enable Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="showOnKick"
                    onChange={() => handleToggle('showOnKick')}
                    disabled={!formData.enableKickAlerts || isMod}
                    checked={formData.showOnKick}
                  />
                  <label htmlFor="showOnKick">Show &apos;on Kick&apos; in alerts</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('showOnKick')}
                    />
                    {tooltip === 'showOnKick' && (
                      <p className="tooltip shadow-lg">
                        Suffixes all alerts with &apos;on Kick&apos;, e.g. &apos;Bob followed on Kick&apos;, &apos;Alice just subscribed on Kick&apos;
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="playKickTts"
                    onChange={() => handleToggle('playKickTts')}
                    disabled={!formData.enableKickAlerts || isMod}
                    checked={formData.playKickTts}
                  />
                  <label htmlFor="playKickTts">Play Alerts with TTS</label>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableKickHostAlerts"
                    onChange={() => handleToggle('enableKickHostAlerts')}
                    disabled={!formData.enableKickAlerts || isMod}
                    checked={formData.enableKickHostAlerts}
                  />
                  <label htmlFor="enableKickHostAlerts">
                    Enable Host Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableKickSubscribeAlerts"
                    onChange={() => handleToggle('enableKickSubscribeAlerts')}
                    disabled={!formData.enableKickAlerts || isMod}
                    checked={formData.enableKickSubscribeAlerts}
                  />
                  <label htmlFor="enableKickSubscribeAlerts">
                    Enable Subscribe Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="kickSubsAddToGoal"
                    onChange={() => handleToggle('kickSubsAddToGoal')}
                    disabled={!formData.enableKickAlerts || isMod}
                    checked={formData.kickSubsAddToGoal}
                  />
                  <label htmlFor="kickSubsAddToGoal">Subscribers Add to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('kickSubsAddToGoal')}
                    />
                    {tooltip === 'kickSubsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        Kick subscribers will increment the subscription goal. Subscribe alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {!isMod && <button id="kickSubmitBtn" type="submit" disabled={isKickSaving}>Save</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.kick}
              width={400}
            />
          </form>
        )}
      </div>

      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle' + (formData.dliveLinked ? '' : ' disabled')
            }
            onClick={() => {
              if (formData.dliveLinked) toggleShowOptions('dlive');
            }}
          >
            {showOptions.dlive ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4 className="integration-header">
            <img className="icon" src="/static/img/dlive-logo.webp" />
            DLive.tv&nbsp;
            {formData.dliveLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.dliveLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkDlive}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkDlive}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
        </div>
        {formData.dliveLinked && showOptions.dlive && (
          <form onSubmit={saveDlive}>
            <div className="options form-container">
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableAllDliveAlerts"
                    onChange={() => handleToggle('enableAllDliveAlerts')}
                    checked={formData.enableAllDliveAlerts}
                    disabled={isMod}
                  />
                  <label htmlFor="enableAllDliveAlerts">All Enable Alerts</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="showOnDlive"
                    onChange={() => handleToggle('showOnDlive')}
                    disabled={!formData.enableAllDliveAlerts || isMod}
                    checked={formData.showOnDlive}
                  />
                  <label htmlFor="showOnDlive">Show &apos;on DLive&apos; in alerts</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('showOnDlive')}
                    />
                    {tooltip === 'showOnDlive' && (
                      <p className="tooltip shadow-lg">
                        Suffixes all alerts with &apos;on DLive&apos;, e.g. &apos;Bob followed on DLive&apos;, &apos;Alice just subscribed on DLive&apos;
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableDliveChats"
                    onChange={() => handleToggle('enableDliveChats')}
                    checked={formData.enableDliveChats}
                    disabled={isMod}
                  />
                  <label htmlFor="enableDliveChats">Enable DLive Chats</label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="viewCountDliveViewers"
                    onChange={() => handleToggle('viewCountDliveViewers')}
                    checked={formData.viewCountDliveViewers}
                    disabled={isMod}
                  />
                  <label htmlFor="viewCountDliveViewers">Show in view count overlay</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountDliveViewers')}
                    />
                    {tooltip === 'viewCountDliveViewers' && (
                      <p className="tooltip shadow-lg">
                        When enabled, the view count overlay will show the number of viewers on your Dlive stream.<br />
                        <br />
                        Note: You can adjust how the view count overlay looks in the Settings page.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableDliveFollowAlerts"
                    onChange={() => handleToggle('enableDliveFollowAlerts')}
                    disabled={!formData.enableAllDliveAlerts || isMod}
                    checked={formData.enableDliveFollowAlerts}
                  />
                  <label htmlFor="enableDliveFollowAlerts">
                    Enable Follow Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="dliveFollowsAddToGoal"
                    onChange={() => handleToggle('dliveFollowsAddToGoal')}
                    disabled={!formData.enableAllDliveAlerts || !formData.enableDliveFollowAlerts || isMod}
                    checked={formData.dliveFollowsAddToGoal}
                  />
                  <label htmlFor="dliveFollowsAddToGoal">Followers Add to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('dliveFollowsAddToGoal')}
                    />
                    {tooltip === 'dliveFollowsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        DLive followers will increment the follower goal. Follower alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableDliveSubscribeAlerts"
                    onChange={() => handleToggle('enableDliveSubscribeAlerts')}
                    disabled={!formData.enableAllDliveAlerts || isMod}
                    checked={formData.enableDliveSubscribeAlerts}
                  />
                  <label htmlFor="enableDliveSubscribeAlerts">
                    Enable Subscription Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="dliveSubsAddToGoal"
                    onChange={() => handleToggle('dliveSubsAddToGoal')}
                    disabled={!formData.enableAllDliveAlerts || !formData.enableDliveSubscribeAlerts || isMod}
                    checked={formData.dliveSubsAddToGoal}
                  />
                  <label htmlFor="dliveSubsAddToGoal">Subscribers Add to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      disabled={true}
                      onClick={() => toggleTooltip('dliveSubsAddToGoal')}
                    />
                    {tooltip === 'dliveSubsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        DLive subscribers will increment the subscription goal. Subscribe alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableDliveLemonAlerts"
                    onChange={() => handleToggle('enableDliveLemonAlerts')}
                    disabled={!formData.enableAllDliveAlerts || isMod}
                    checked={formData.enableDliveLemonAlerts}
                  />
                  <label htmlFor="enableDliveLemonAlerts">
                    Enable Lemon Alerts
                  </label>
                </div>
                <div className="toggle-container">
                  <ToggleSwitch
                    name="dliveLemonsAddToGoal"
                    onChange={() => handleToggle('dliveLemonsAddToGoal')}
                    disabled={
                      !formData.enableAllDliveAlerts || !formData.enableDliveLemonAlerts || isMod
                    }
                    checked={formData.dliveLemonsAddToGoal}
                  />
                  <label htmlFor="dliveLemonsAddToGoal">Lemons Adds to Goal</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('dliveLemonsAddToGoal')}
                    />
                    {tooltip === 'dliveLemonsAddToGoal' && (
                      <p className="tooltip shadow-lg">
                        Dollar amount of lemons will be added to donation
                        goal. Lemons alerts do not need to be enabled.
                      </p>
                    )}
                  </div>
                </div>
                <div className='toggle-container'>
                  <ToggleSwitch
                    name='enableDliveHostAlerts'
                    onChange={() => handleToggle('enableDliveHostAlerts')}
                    disabled={!formData.enableAllDliveAlerts || isMod}
                    checked={formData.enableDliveHostAlerts}
                  />
                  <label htmlFor='enableDliveHostAlerts'>
                    Enable Host Alerts
                  </label>
                </div>
              </div>
              <div className="options-section" style={{ flex: 1 }}>
                <label htmlFor="playDliveLemonMinimum">Lemons TTS Minimum</label>
                <input
                  name="playDliveLemonMinimum"
                  placeholder="Lemon TTS Minimum"
                  onChange={handleChange}
                  disabled={
                    !formData.enableAllDliveAlerts ||
                    !formData.enableDliveLemonAlerts ||
                    !formData.playDliveLemonTts ||
                    isMod
                  }
                  value={formData.playDliveLemonMinimum}
                />
              </div>
            </div>
            {!isMod && <button type="submit">Save</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.dlive}
              width={400}
            />
          </form>
        )}
      </div>

      {formData.cozytvEnabled && (
        <div className="integration form-container">
          <div className="integration-header">
            <div
              className={
                'dropdown-toggle' + (formData.cozytvEnabled ? '' : ' disabled')
              }
              onClick={() => {
                if (formData.cozytvEnabled) toggleShowOptions('cozy');
              }}
            >
              {showOptions.cozy ? (
                <FontAwesomeIcon icon={faChevronUp} size="lg" />
              ) : (
                <FontAwesomeIcon icon={faChevronDown} size="lg" />
              )}
            </div>
            <h4 className="integration-header">
              <img className="icon" src="/static/img/cozy-icon.svg" />
              Cozy
            </h4>
          </div>
          {showOptions.cozy && (
            <form onSubmit={saveCozy}>
              <div className="options form-container">
                <div className="options-section">
                  <div className="toggle-container">
                    <ToggleSwitch
                      name="enableAllCozyAlerts"
                      onChange={() => handleToggle("enableAllCozyAlerts")}
                      checked={formData.enableAllCozyAlerts}
                      disabled={isMod}
                    />
                    <label htmlFor="enableAllCozyAlerts">Enable All Cozy Alerts</label>
                  </div>
                  <div className="toggle-container">
                    <ToggleSwitch
                      name="enableCozyChats"
                      onChange={() => handleToggle("enableCozyChats")}
                      /*disabled={!formData.enableAllCozyAlerts}*/
                      disabled={isMod}
                      checked={formData.enableCozyChats} 
                    />
                    <label htmlFor="enableCozyChats">Enable Cozy Chats</label>
                  </div>
                  <div className="toggle-container">
                    <ToggleSwitch
                      name="viewCountCozyViewers"
                      onChange={() => handleToggle('viewCountCozyViewers')}
                      checked={formData.viewCountCozyViewers}
                      disabled={isMod}
                    />
                    <label htmlFor="viewCountCozyViewers">Show in view count overlay</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('viewCountCozyViewers')}
                      />
                      {tooltip === 'viewCountCozyViewers' && (
                        <p className="tooltip shadow-lg">
                          When enabled, the view count overlay will show the number of viewers on your Cozy stream.<br />
                          <br />
                          Note: You can adjust how the view count overlay looks in the Settings page.
                        </p>
                      )}
                    </div>
                  </div>
                  {/*<div className="toggle-container">
                    <ToggleSwitch
                      name="followerChatAlerts"
                      onChange={() => handleToggle("followerChatAlerts")}
                      disabled={!formData.enableAllCozyAlerts}
                      checked={formData.followerChatAlerts}
                    />
                    <label htmlFor="followerChatAlerts">Follower Chat Alerts</label>
                  </div>*/}
                </div>
                <div className="options-section">
                  <div className="toggle-container">
                    <ToggleSwitch
                      name="enableTtsAlerts"
                      onChange={() => handleToggle("enableTtsAlerts")}
                      disabled={!formData.enableAllCozyAlerts || isMod}
                      checked={formData.enableTtsAlerts} 
                    />
                    <label htmlFor="enableTtsAlerts">Enable Follow Alerts</label>
                  </div>
                  <div className="toggle-container">
                    <ToggleSwitch
                      name="cozyFollowsAddToGoal"
                      onChange={() => handleToggle('cozyFollowsAddToGoal')}
                      disabled={!formData.enableAllCozyAlerts || isMod}
                      checked={formData.cozyFollowsAddToGoal}
                    />
                    <label htmlFor="cozyFollowsAddToGoal">Followers Add to Goal</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        disabled={true}
                        onClick={() => toggleTooltip('cozyFollowsAddToGoal')}
                      />
                      {tooltip === 'cozyFollowsAddToGoal' && (
                        <p className="tooltip shadow-lg">
                          Cozy follows will increment the follower goal. Follow alerts do not need to be enabled.
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="toggle-container">
                    <ToggleSwitch
                      name="onCozyTts"
                      onChange={() => handleToggle("onCozyTts")}
                      disabled={!formData.enableAllCozyAlerts || isMod}
                      checked={formData.onCozyTts}
                    />
                    <label htmlFor="onCozyTTS">&quot;On Cozy&quot; TTS</label>
                  </div>
                </div>
              </div>
              <input
                name="cozytvUsername"
                placeholder="Enter your cozy.tv username"
                onChange={handleChange}
                value={formData.cozytvUsername}
                disabled={isMod}
              />
              {!isMod && <button type="submit">Save</button>}
              <FormAlert margin={"10px 0 0 0"} alertState={alertState.cozy} width={400} />
            </form>
        )}
        </div>
      )}

      <div className="integration form-container">
        <div className="integration-header">
          <div
            className={
              'dropdown-toggle'
            }
            onClick={() => {
              toggleShowOptions('robotstreamer');
            }}
          >
            {showOptions.robotstreamer ? (
              <FontAwesomeIcon icon={faChevronUp} size="lg" />
            ) : (
              <FontAwesomeIcon icon={faChevronDown} size="lg" />
            )}
          </div>
          <h4 className="integration-header">
              <img style={{ backgroundColor: '#1dd1a1', padding: '2px', borderRadius: '4px' }} className="icon" src="/static/img/robotlogo.svg" />
              Robotstreamer
          </h4>
        </div>
        {showOptions.robotstreamer && (
          <form onSubmit={saveRobotstreamer}>
            <div className="options form-container">
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="enableRobotstreamerChats"
                    onChange={() => handleToggle('enableRobotstreamerChats')}
                    checked={formData.enableRobotstreamerChats}
                    disabled={isMod}
                  />
                  <label htmlFor="enableRobotstreamerChats">Enable Robotstreamer Chats</label>
                </div>
              </div>
              <div className="options-section">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="viewCountRobotstreamerViewers"
                    onChange={() => handleToggle('viewCountRobotstreamerViewers')}
                    checked={formData.viewCountRobotstreamerViewers}
                    disabled={isMod}
                  />
                  <label htmlFor="viewCountRobotstreamerViewers">Show in view count overlay</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('viewCountRobotstreamerViewers')}
                    />
                    {tooltip === 'viewCountRobotstreamerViewers' && (
                      <p className="tooltip shadow-lg">
                        When enabled, the view count overlay will show the number of viewers on your Robotstreamer stream.<br />
                        <br />
                        Note: You can adjust how the view count overlay looks in the Settings page.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <input
              name="robotId"
              placeholder="Enter your Robotstreamer Channel ID (Robot ID)"
              onChange={handleChange}
              value={formData.robotId}
              disabled={isMod}
            />
            {!isMod && <button type="submit">Save</button>}
            <FormAlert
              margin={'10px 0 0 0'}
              alertState={alertState.robotstreamer}
              width={400}
            />
          </form>
        )}
      </div>

      <div className="integration form-container">
        <div className="integration-header">
          <h4>
            <img className="icon" src="/static/img/streamlabs-icon.webp" />
            Streamlabs:&nbsp;
            {formData.streamlabsLinked ? (
              <FontAwesomeIcon icon={faCheckSquare} />
            ) : (
              <FontAwesomeIcon icon={faTimesSquare} />
            )}
          </h4>
          <div>
            {formData.streamlabsLinked ? (
              <button
                type="button"
                className="secondary-btn"
                onClick={unlinkStreamlabs}
                disabled={isMod}
              >
                Unlink&nbsp;
                <FontAwesomeIcon icon={faLinkSlash} />
              </button>
            ) : (
              <button
                type="button"
                className="secondary-btn"
                onClick={linkStreamlabs}
                disabled={isMod}
              >
                Link&nbsp;
                <FontAwesomeIcon icon={faLink} />
                <sup>&nbsp;&nbsp;&nbsp;<FontAwesomeIcon icon={faUpRightFromSquare} /></sup>
              </button>
            )}
          </div>
          <div style={{ flex: 1 }} />
        </div>
        <div className="note">
          Linking Streamlabs will allow donation and subscription alerts from
          Powerchat to show up in your Streamlabs overlay.
        </div>
      </div>
    </div>
  );
};

export default ExternalIntegrations;
