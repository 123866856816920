import React, { useState, useEffect } from 'react';
import axios from 'axios';

const SquarePay = ({
  getPowerchat,
  reloadCaptcha,
  squareAccount,
  disabled,
  completed,
  setLoading,
  squareLoaded,
  captchaToken,
}) => {
  const [payments, setPayments] = useState(null);
  const [googlePay, setGooglePay] = useState(null);

  useEffect(() => {
    const loadSquarePayments = async () => {
      if (!window.Square) {
        console.error('Square.js failed to load properly');
        return;
      }
      if (!squareAccount) {
        return;
      }

      try {
        let pay = window.Square.payments(
          process.env.SQUARE_APP_ID,
          squareAccount
        );
        setPayments(pay);
        await initializeGooglePay(pay);
      } catch (err) {
        console.error(err);
      }
    };
    if (squareLoaded) loadSquarePayments();
  }, [squareAccount, squareLoaded]);

  const buildPaymentRequest = (payments, powerchat) => {
    let amount = powerchat ? powerchat.amount : 1;
    return payments.paymentRequest({
      countryCode: 'US',
      currencyCode: 'USD',
      total: {
        amount: `${amount}`,
        label: 'Total',
      },
    });
  };

  const initializeGooglePay = async (payments) => {
    const paymentRequest = buildPaymentRequest(payments);
    const gpay = await payments.googlePay(paymentRequest);
    setGooglePay(gpay);
    await gpay.attach('#google-pay-button', {
      buttonColor: 'black',
      buttonSizeMode: 'fill',
      buttonType: 'long',
    });
  };

  const createPayment = async (token, powerchat) => {
    await axios.post('/pubapi/square', {
      token,
      captchaToken,
      currency: 'USD',
      squareUserId: squareAccount,
      ...powerchat,
    });
    completed('Square payment completed');
  };

  const tokenize = async (paymentMethod) => {
    const tokenResult = await paymentMethod.tokenize();
    if (tokenResult.status === 'OK') {
      return tokenResult.token;
    } else {
      let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
      if (tokenResult.errors) {
        errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
      }
      throw new Error(errorMessage);
    }
  };

  const handlePaymentMethodSubmission = async () => {
    if (disabled) {
      throw 'Payment not allowed in current state';
    }
    setLoading(true);
    try {
      let powerchat = getPowerchat();
      const paymentRequest = buildPaymentRequest(payments, powerchat);
      googlePay.req = paymentRequest;
      const token = await tokenize(googlePay);
      await createPayment(token, powerchat);
    } catch (e) {
      console.error(e.message);
    } finally {
      reloadCaptcha();
      setLoading(false);
    }
  };

  const disabledStyle = {
    pointerEvents: 'none',
  };

  return (
    <div className="google-pay-container">
      <span className="image-container">
        <img className="icon" src="/static/img/square.svg" />
      </span>
      <div
        id="google-pay-button"
        onClick={handlePaymentMethodSubmission}
        style={disabled ? disabledStyle : {}}
      ></div>
    </div>
  );
};

export default SquarePay;
