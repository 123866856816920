import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../css/view-count.css';
import axios from 'axios';

const CounterDigit = ({ value }) => {
  const numValue = Number(value);
  const [currentValue, setCurrentValue] = useState(0); // Initialize at zero

  useEffect(() => {
    if (currentValue !== numValue) {
      const timer = setTimeout(() => {
        setCurrentValue(numValue);
      }, 300); // Match the animation duration

      return () => clearTimeout(timer);
    }
  }, [numValue]);

  const topPosition = currentValue === 0 ? `5px` : `-${(currentValue * 80) - 5}px`;

  return (
    <div className="digit-container">
      <div className="digit" style={{ fontSize: '40px', top: topPosition }}>
        {Array.from({ length: 10 }, (_, i) => i).map((digit, index) => (
          <div key={index} className="digit-inner" style={{ height: '40px' }}>
            {digit}
          </div>
        ))}
      </div>
    </div>
  );
};

const Counter = ({ value }) => {
  let displayValue;

  if (value === -1) {
    displayValue = '0000';
  } else {
    displayValue = String(value).replace(/^0+/, '');
    if (displayValue === '') displayValue = '0';
  }

  return (
    <div className="counter">
      {Array.from(displayValue).map((digit, index) => (
        <CounterDigit key={index} value={digit} />
      ))}
    </div>
  );
};

const PlatformContainer = ({ platform }) => {
  const [shake, setShake] = useState(false);
  const prevCount = useRef(platform.viewerCount);

  useEffect(() => {
    if (platform.viewerCount !== prevCount.current) {
      setShake(true);

      const timer = setTimeout(() => {
        setShake(false);
      }, 300);

      prevCount.current = platform.viewerCount;

      return () => clearTimeout(timer);
    }
  }, [platform.viewerCount]);

  return (
    <div className="platform-container">
      <img
        className={`platform-icon ${shake ? 'bounce' : ''}`}
        src={platform.icon}
        alt={platform.platform}
      />
      <Counter value={platform.viewerCount} />
    </div>
  );
};

const ViewCount = () => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const pingTime = 1 * 60 * 1000; // 1 minute
  const { username } = useParams();
  const [platforms, setPlatforms] = useState([]);
  const [settings, setSettings] = useState({
    overlayOrientation: 'center',
    displayOrientation: 'horizontal',
    platformsToHide: [],
    errorMessage: '',
  });

  useEffect(() => {
    async function loadSettings() {
      try {
        const response = await axios.get(`https://${baseUrl}/pubapi/live-view-count-settings`, { params: { username } });

        //console.info(`Settings:`, response.data)
    
        const filteredPlatforms = await loadViewCounts(response.data.platformsToHide);

        const powerChatPlatform = filteredPlatforms.find(platform => platform.platform === 'PowerChat');
        if (powerChatPlatform && (powerChatPlatform.viewerCount > -1 && powerChatPlatform.viewerCount < 1)) {
          setSettings({
            ...settings,
            errorMessage: ``,
          });
        }
    
        setSettings(response.data);
        setPlatforms(filteredPlatforms);
      } catch (error) {
        console.error('An error occurred:', error);
        setSettings({
          ...settings,
          errorMessage: ``, // will send to 404 in future
        });
      }
    }    
    
    async function loadViewCounts(platformsToHide) {
      const viewerCountResponse = await axios.get(`https://${baseUrl}/pubapi/live-view-counts-by-platform`, { params: { username } });
    
      //console.info(`Counts:`, viewerCountResponse.data);

      return viewerCountResponse.data.filter(
        (platform) => !platformsToHide.some((p) => p === platform.platform),
      );
    }

    const intervalId = setInterval(() => {
      loadSettings();
    }, pingTime);

    loadSettings();

    return () => {
      clearInterval(intervalId);
    };
  }, [baseUrl, username]);

  return (
    <div className={`container ${settings.overlayOrientation}`}>
      {settings.errorMessage ? (
        <div className="error-message" dangerouslySetInnerHTML={{ __html: settings.errorMessage }}></div>
      ) : (
        <div className={`count-container ${settings.displayOrientation}`}>
          {platforms.map((platform, index) => (
            <PlatformContainer key={index} platform={platform} />
          ))}
        </div>
      )}
    </div>
  );
};

export default ViewCount;
