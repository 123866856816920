import React from 'react';
import '../css/description.css';

const Description = ({ children }) => {
  return (
    <div className="description-section section-text-wrapper text-center">
      <div className="section-text">{children}</div>
    </div>
  );
};

export default Description;
