import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../css/subscriptions.css';
import FormAlert from './form-alert';
import Captcha from './captcha';
import { findTtsVoice, ttsVoiceOptions } from '../static/tts-voices';
import { containsProfanity } from '../../server/api/textFilters';
import VoiceSelect from './voice-select';

const Subscriptions = ({
  user,
  tipUsername,
  tipUserId,
  paidChatData,
  subscription,
  ttsVoice,
  captchaDone,
  setCaptchaDone,
  setCaptchaToken,
  profanity,
}) => {
  const maxMessageLength = 250;
  const pageOwner =
    user && tipUsername && user.username.toLowerCase() === tipUsername.toLowerCase();
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [subData, setSubData] = useState({});
  const [formData, setFormData] = useState({
    message: '',
  });
  const [pageData, setPageData] = useState({
    active: false,
    usedAlert: true,
    recurring: false,
    accountMatch: false,
  });
  const [validForm, setValidForm] = useState(true);
  const [hasProfanity, setHasProfanity] = useState(false);

  useEffect(() => {
    let valid = captchaDone && !hasProfanity;
    setValidForm(valid);
  }, [captchaDone, hasProfanity]);

  const goldButton = {
    backgroundColor: 'var(--gold)',
    color: 'black',
    marginLeft: '0.5rem',
  };

  const ttsStyles = {
    control: (styles) => ({
      ...styles,
      width: '150px',
      height: '40px',
      margin: '10px 0 0 0',
      border: 'none',
      outline: 'none',
    }),
    option: (styles) => ({ ...styles, color: 'black' }),
    input: (styles) => ({
      ...styles,
      cursor: 'text',
    }),
  };

  const [ttsVoiceSelected, setTtsVoiceSelected] = useState(findTtsVoice(ttsVoice));

  useEffect(() => {
    setTtsVoiceSelected(findTtsVoice(ttsVoice));
  }, [ttsVoice]);

  useEffect(() => {
    let active = subscription.status === 'active';
    let expiryDateMessage = subscription.recurring
      ? 'Renewal Date: '
      : 'Expiration Date: ';
    let subButtonText = !subscription
      ? 'Subscribe'
      : subscription.recurring
      ? 'Unsubscribe'
      : 'Renew Subscription';

    setSubData(subscription);
    setPageData({
      active,
      recurring: subscription.recurring,
      usedAlert: subscription.used_alert,
      statusString: subscription.status
        ? subscription.status[0].toUpperCase() + subscription.status.slice(1)
        : '',
      expiryDate: new Date(subscription.expires_at).toLocaleDateString(),
      expiryDateMessage,
      subButtonText,
    });
  }, [subscription]);

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async () => {
    if (!captchaDone) {
      console.error('Captcha incomplete');
      return;
    }
    if (!validForm) {
      throw 'Subscription not allowed in current state';
    }
    if (!pageData.active) {
      await subscribe();
    } else if (pageData.recurring) {
      await unsubscribe();
    } else {
      await restartSubscription();
    }
  };

  const subscribe = async () => {
    try {
      let response = await axios.post('/api/stripe/subscribe', {
        params: {
          donator: user.username,
          tipUsername,
          tipUserId,
          ttsVoice: ttsVoiceSelected ? ttsVoiceSelected.name : null,
          voiceTypeId: ttsVoiceSelected ? ttsVoiceSelected.voiceTypeId : 1,
          ...formData,
        },
      });
      let url = response.data.redirect_url;
      if (url) {
        window.location.href = url;
      } else {
        if (response.data.subscription_id) {
          triggerAlert(
            'Could not subscribe. You may have an unlogged subscription through Stripe',
            false
          );
          console.info(
            'A subscription was returned.',
            response.data.subscription_id
          );
        }
        setSubData({
          id: response.data.id,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const restartSubscription = async () => {
    try {
      await axios
        .post('/api/stripe/subscription/restart', {
          subscription_id: subData.id,
        })
        .then((response) => {
          if (response.status == 200) {
            triggerAlert('Subscription set to renew');
            setTimeout(() => window.location.reload(), 2000);
          }
        });
    } catch (err) {
      triggerAlert('Error with renewing subscription', false);
      console.error(err);
    }
  };

  const unsubscribe = async () => {
    try {
      await axios
        .post('/api/stripe/subscription/cancel', {
          subscription_id: subData.id,
        })
        .then((response) => {
          if (response.status == 200) {
            triggerAlert('Subscription set to cancel');
            setTimeout(() => window.location.reload(), 2000);
          }
        });
    } catch (err) {
      triggerAlert('Error with unsubscribing', false);
      console.error(err);
    }
  };

  const sendStreak = async () => {
    try {
      if (!validForm) {
        throw 'Streak not allowed in current state';
      }
      if (pageData.usedAlert) {
        return;
      }
      await axios
        .post('/api/subscription/streak', {
          subscription_id: subData.id,
          username: tipUsername,
          donator: user.username,
          ttsVoice: ttsVoiceSelected ? ttsVoiceSelected.name : null,
          ...formData,
        })
        .then((response) => {
          if (response.status == 200) {
            triggerAlert('Streak alert sent!');
            setPageData({
              ...pageData,
              usedAlert: true,
            });
          }
        });
    } catch (err) {
      triggerAlert('Error with sending streak alert', false);
      console.error(err);
    }
  };

  const testSubscription = async () => {
    try {
      if (!validForm) {
        throw 'Streak not allowed in current state';
      }
      await axios.get('/api/testSubscription', {
        params: {
          donator: user.username,
          tipUsername,
          ttsVoice: ttsVoiceSelected ? ttsVoiceSelected.name : null,
          voiceTypeId: ttsVoiceSelected ? ttsVoiceSelected.voiceTypeId : 1,
          ...formData,
        },
      });
      triggerAlert('Test subscription successful');
      if (!subData.status) {
        setTimeout(() => window.location.reload(), 2000);
      }
    } catch (err) {
      triggerAlert('Test subscription unsuccessful', false);
    }
  };

  const testStreak = async () => {
    try {
      if (!validForm) {
        throw 'Streak not allowed in current state';
      }
      await axios.post('/api/testSubscriptionStreak', {
        subscription_id: subData.id,
        tipUsername,
        ttsVoice: ttsVoiceSelected ? ttsVoiceSelected.name : null,
        voiceTypeId: ttsVoiceSelected ? ttsVoiceSelected.voiceTypeId : 1,
        ...formData,
      });
      triggerAlert('Test streak successful');
    } catch (err) {
      triggerAlert('Test streak unsuccessful', false);
    }
  };

  const captchaCallback = async (token) => {
    setCaptchaDone(true);
    setCaptchaToken(token);
  };

  const checkProfanity = () => {
    setHasProfanity(containsProfanity(formData.message, profanity.current)
      || (paidChatData.donatorSelectVoice && (user && containsProfanity(user.username, profanity.current))));
  };

  useEffect(() => {
    if (ttsVoiceSelected && ttsVoiceSelected.voiceTypeId == 10) {
      checkProfanity();
    }
    else {
      setHasProfanity(false);
    }
  }, [formData.message, ttsVoiceSelected, user]);

  return user ? (
    <div className="subscriptions-container form-container">
      <div className="details-wrapper">
        {subData.status && !pageOwner ? (
          <div>
            <div className="details-container">
              <h4>Logged In As:</h4>
              <h4>{user.username}</h4>
            </div>
            <div className="details-container">
              <h4>Subscription Status:</h4>
              <h4>{pageData.statusString}</h4>
            </div>
            <div className="details-container">
              <h4>Monthly Streak:</h4>
              <h4>{subData.streak}</h4>
            </div>
            <div className="details-container">
              <h4>{pageData.expiryDateMessage}</h4>
              <h4>{pageData.expiryDate}</h4>
            </div>
          </div>
        ) : (
          <div className="details-container">
            <h4>Logged In As:</h4>
            <h4>{user.username}</h4>
          </div>
        )}
        {subData.account_id &&
          subData.account_id !== paidChatData.stripeUserId && (
            <div style={{ color: 'red' }}>
              Their account may have been changed or unlinked.
            </div>
          )}
      </div>
      <div>
        <div className="select-row input-row">
          <div className="input-w100">
            <label htmlFor="message">Optional Message</label>
            <div className="input-group">
              <input
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                name="message"
                placeholder="Enter a message"
                maxLength={maxMessageLength}
                onChange={handleChange}
              />
              <div className="input-group-append">
                <span>{maxMessageLength - formData.message.length}</span>
              </div>
            </div>
          </div>
          {paidChatData.donatorSelectVoice && (
            <div className="tts-select-container" title="Type to search">
              <label htmlFor="ttsVoice">Choose a voice</label>
              <div className="tts-select">
                <VoiceSelect
                  options={ttsVoiceOptions}
                  styles={ttsStyles}
                  placeholder={ttsVoice || 'Select a voice'}
                  value={ttsVoiceSelected}
                  onChange={(voice) => setTtsVoiceSelected(voice)}
                />
              </div>
            </div>
          )}
        </div>
        {hasProfanity && (
          <div className="invalid-message">Slurs are not allowed in this voice.</div>
        )}
        <div className="input-row">
          <label>Amount (USD)</label>
          <div className="amount-container">
            <span>Monthly Subscription:</span>
            <span>$5</span>
          </div>
        </div>
        <div className="action-btn-container">
          {!captchaDone && (
            <Captcha
              captchaDone={captchaDone}
              captchaCallback={captchaCallback}
            />
          )}
          {pageOwner ? (
            <div>
              <button
                disabled={!validForm}
                className="test-btn"
                onClick={testSubscription}
              >
                Test Subscription
              </button>
              <button
                disabled={!pageData.active || !validForm}
                className="test-btn"
                onClick={testStreak}
              >
                Test Streak
              </button>
            </div>
          ) : (
            process.env.STRIPE_ENABLED == 'true' && paidChatData.stripeLinked &&
            (!pageData.active ||
              subData.account_id === paidChatData.stripeUserId) && (
              <div>
                <button disabled={!validForm} onClick={handleSubmit}>
                  {pageData.subButtonText}
                </button>
                {pageData.active && (
                  <button
                    disabled={pageData.usedAlert || !validForm}
                    onClick={sendStreak}
                    style={goldButton}
                    title="May send after first renewal, once per pay period"
                  >
                    Send Sub Streak Alert
                  </button>
                )}
              </div>
            )
          )}
        </div>
      </div>
      <FormAlert alertState={alertState} width={400} />
    </div>
  ) : (
    <div style={{ margin: 10, textAlign: 'center', width: '100%' }}>
      <h4>
        <a href="/login">Login</a> to subscribe
      </h4>
    </div>
  );
};

export default Subscriptions;
