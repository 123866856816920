import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../css/events-overlay.css';
import ReconnectingWebSocket from 'reconnecting-websocket';
import ProgressBar from '../components/progress-bar';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

const EventsOverlay = () => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const { username } = useParams();
  const [chats, setChats] = useState([]);

  useEffect(() => {
    setInterval(tick, 1000);
  }, []);

  useEffect(() => {
    startWebsockets();
  }, []);

  const addChat = (donator, amount) => {
    setChats((chats) => {
      let retIndex = chats.findIndex((x) => amount >= x.amount);
      if (retIndex == -1) {
        retIndex = chats.length;
      }

      const maxTime = Math.round(60 * Math.pow(amount, 0.4));

      return [
        ...chats.slice(0, retIndex),
        {
          id: crypto.randomUUID(),
          donator:
            donator.length > 15 ? donator.substring(0, 12) + '...' : donator,
          amount,
          maxTime,
          remTime: maxTime,
          time: Date.now(),
        },
        ...chats.slice(retIndex),
      ];
    });
  };

  const tick = () => {
    setChats((chats) => {
      chats.forEach((e, index) => {
        e['remTime'] -= 1;
        if (e['remTime'] <= 0) {
          chats.splice(index, 1);
        }
      });
      return [...chats];
    });
  };

  const receiveMessage = (data) => {
    const dataObj = JSON.parse(data);
    if (dataObj.amount >= dataObj.ttsLimit) {
      addChat(dataObj.donator, dataObj.amount);
    }
  };

  const startWebsockets = () => {
    const protocol = 'wss';
    const socket = new ReconnectingWebSocket(
      `${protocol}://${baseUrl}/${username.toLowerCase()}`
    );

    socket.onopen = function () {
      console.log('[open] Connection established');
    };

    socket.onmessage = function (event) {
      console.log(`[message] Data received from server: ${event.data}`);
      if (event.data == 'pong') {
        return;
      }
      receiveMessage(event.data);
    };

    socket.onclose = function (event) {
      if (event.wasClean) {
        console.log(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        console.log('[close] Connection died');
      }
    };
  };

  return (
    <TransitionGroup className="events-overlay">
      {chats.map((c) => (
        <CSSTransition key={c.id} timeout={500}>
          <div className="chat-event">
            <h4>{c.donator}</h4>
            <p>${c.amount.toFixed(2)}</p>
            <ProgressBar height="0.25rem" value={c.remTime} max={c.maxTime} />
          </div>
        </CSSTransition>
      ))}
    </TransitionGroup>
  );
};

export default EventsOverlay;
