import React, { useEffect, useState, useCallback, useMemo } from 'react';
import axios from 'axios';
import '../css/settings-form.css';
import '../css/goal.css';
import '../css/fonts.css';
import FormAlert from './form-alert';
import ColorInput from './color-input';
import FontSelector from './font-selector';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronRight, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import ProgressBar from '../components/progress-bar';
import MonetaryInput from '../components/monetary-input';
import SizeSelector from '../components/size-selector';
import CustomSelect from '../components/custom-select';

const Goal = ({ updateData, goalSettings, isMod=false }) => {
  const replacePlaceholders = (goal) => {
    return goal.text_value
      .replace(/{goal_name}/g, goal.goal_name ? goal.goal_name : '')
      .replace(/{current}/g, goal.is_monetary ? `$${parseFloat(goal.goal_current).toFixed(2)}` : goal.goal_current)
      .replace(/{max}/g, goal.is_monetary ? `$${parseFloat(goal.goal_max).toFixed(2)}` : goal.goal_max)
      .replace(/{percentage}/g, goal.goal_max > 0 ? `${(goal.goal_current / goal.goal_max * 100).toFixed(0)}%` : '100%');
  };

  const initialGoals = useMemo(() => goalSettings.map(goal => ({
    ...goal,
    goal_length: goal.goal_length || '100',
    goal_length_unit: goal.goal_length_unit || '%',
    length: `${goal.goal_length}${goal.goal_length_unit}`,
  })), [goalSettings]);

  const [goals, setGoals] = useState(initialGoals);
  const [isGoalsSaved, setIsGoalsSaved] = useState(false);
  const [alertState, setAlertState] = useState({ message: '', success: true });
  const [expandedGoals, setExpandedGoals] = useState({});
  const [customizeColorsExpanded, setCustomizeColorsExpanded] = useState({});
  const [customizeStylesExpanded, setCustomizeStylesExpanded] = useState({});
  const [tooltip, setTooltip] = useState('');

  const unitOptions = [
    { value: '%', label: '%' },
    { value: 'px', label: 'px' },
    { value: 'rem', label: 'rem' },
  ];

  const handleLengthChange = useCallback((index, value, unit) => {
    setGoals(prevGoals => prevGoals.map((goal, idx) =>
      idx === index ? {
        ...goal,
        length: `${value}${unit}`,
        goal_length: value,
        goal_length_unit: unit
      } : goal
    ));
  }, []);

  const handleChange = useCallback((index, event) => {
    const { name, value, type, checked } = event.target;
    const updatedValue = type === 'checkbox' ? checked : value;

    setGoals(prevGoals => prevGoals.map((goal, idx) =>
      idx === index ? { ...goal, [name]: updatedValue } : goal
    ));
  }, []);

  const getTextValueForTheme = (theme, textOption, goal) => {
    switch(theme) {
      case 'outline-glow':
        switch(textOption) {
          case 'value':
            return '{goal_name} {current} / {max}';
          case 'percentage':
            return '{goal_name} {percentage}';
          case 'custom':
            return goal.text_value || '';
          default:
            return '{goal_name} {current} / {max} ({percentage})';
        }
      default:
        switch(textOption) {
          case 'value':
            return '{current} / {max}';
          case 'percentage':
            return '{percentage}';
          case 'custom':
            return goal.text_value || '';
          default:
            return '{current} / {max} ({percentage})';
        }
    }
  };

  const handleSelectChange = useCallback((index, name, selectedOption) => {
    setGoals(prevGoals => prevGoals.map((goal, idx) => {
      if (idx === index) {
        const updatedGoal = { ...goal, [name]: selectedOption.value };
        if (name === 'theme') {
          updatedGoal.text_value = getTextValueForTheme(selectedOption.value, goal.text_option, goal);
          if (selectedOption.value === 'outline-glow' && goal.tertiary_color === 'transparent') {
            updatedGoal.tertiary_color = '#ffffff'; // Set to white if tertiary color is transparent
          }
        }
        return updatedGoal;
      }
      return goal;
    }));
  }, []);

  const handleBlur = useCallback((index, event, blurValue) => {
    const { name, value } = event.target;
    if (value === '') {
      setGoals(prevGoals => prevGoals.map((goal, idx) =>
        idx === index ? { ...goal, [name]: blurValue } : goal
      ));
    }
  }, []);

  const handleColorChange = useCallback((index, color, name) => {
    if (color.charAt(0) === '#') {
      color = '#' + color.substring(color.length - 6);
    }
    setGoals(prevGoals => prevGoals.map((goal, idx) =>
      idx === index ? { ...goal, [name]: color } : goal
    ));
  }, []);

  const onFontChange = useCallback((index, selectedOption) => {
    handleChange(index, {
      target: {
        name: 'font_style',
        value: selectedOption.value,
        type: 'select'
      }
    });
  }, [handleChange]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Validate font size
    const validGoals = goals.map(goal => {
      if (goal.font_size === '') {
        return { ...goal, font_size: 16 }; // Default to 16 if empty
      }
      return goal;
    });

    setIsGoalsSaved(true);
    try {
      const response = await axios.put('/api/goals', validGoals);
      if (response.status === 200) {
        updateData(validGoals);
        setAlertState({ message: 'Settings successfully updated', success: true });
        setTimeout(() => {
          setIsGoalsSaved(false);
          setAlertState({ message: '', success: true });
        }, 3000);
      } else {
        setAlertState({ message: 'Failed to update settings. Please try again.', success: false });
      }
    } catch (error) {
      console.error('Error updating settings:', error);
      setAlertState({ message: 'An error occurred while updating settings.', success: false });
      setIsGoalsSaved(false);
    }
  };

  const fontOptions = useMemo(() => [
    { value: 'default', label: 'Default' },
    { value: 'bold', label: 'Bold' },
    { value: 'comicbook', label: 'Comic Book' },
    { value: 'consolas', label: 'Consolas' },
    { value: 'cursive', label: 'Cursive' },
    { value: 'futuristic', label: 'Futuristic' },
    { value: 'groovy', label: 'Groovy' },
    { value: 'jaldi', label: 'Jaldi' },
    { value: 'professional', label: 'Professional' },
    { value: 'spooky', label: 'Spooky' },
    { value: 'three-d', label: '3D' },
  ], []);

  const fontStyles = useMemo(() => ({
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '40px',
      margin: '10px 0 0 0',
      border: 'none',
      outline: 'none',
    }),
    option: (styles) => ({ ...styles, color: 'black' }),
    input: (styles) => ({
      ...styles,
    }),
  }), []);

  const goalOrientationOptions = useMemo(() => [
    { value: 'topLeft', label: 'Top Left' },
    { value: 'topCenter', label: 'Top Center' },
    { value: 'topRight', label: 'Top Right' },
    { value: 'centerLeft', label: 'Center Left' },
    { value: 'center', label: 'Center' },
    { value: 'centerRight', label: 'Center Right' },
    { value: 'bottomLeft', label: 'Bottom Left' },
    { value: 'bottomCenter', label: 'Bottom Center' },
    { value: 'bottomRight', label: 'Bottom Right' },
  ], []);

  const goalTextOptions = useMemo(() => [
    { value: 'default', label: 'Default' },
    { value: 'value', label: 'Value' },
    { value: 'percentage', label: 'Percentage' },
    { value: 'custom', label: 'Custom' },
  ], []);

  const themeOptions = useMemo(() => [
    { value: 'default', label: 'Default' },
    { value: 'pill', label: 'Pill' },
    { value: 'outline-glow', label: 'Outline Glow' },
    { value: 'vertical-left', label: 'Vertical Left' },
    { value: 'vertical-right', label: 'Vertical Right' },
    { value: 'tilted-back', label: 'Tilted Back' },
    { value: 'tilted-forward', label: 'Tilted Forward' },
  ], []);

  const handleTextOption = (index, name, selectedOption) => {
    const newTextValue = getTextValueForTheme(goals[index].theme, selectedOption.value, goals[index]);
    setGoals(prevGoals => prevGoals.map((goal, idx) =>
      idx === index ? { ...goal, text_option: selectedOption.value, text_value: newTextValue } : goal
    ));
  };

  const appendPlaceholderToTextValue = (index, placeholder) => {
    const input = document.getElementById(`text-value-input-${index}`);
    if (!input) return;

    const startPos = input.selectionStart;
    const endPos = input.selectionEnd;

    const textBefore = input.value.substring(0, startPos);
    const textAfter = input.value.substring(endPos, input.value.length);
    
    const updatedTextValue = textBefore + placeholder + textAfter;
    const updatedGoals = goals.map((goal, idx) => {
      if (idx === index) {
        return { ...goal, text_value: updatedTextValue };
      }
      return goal;
    });

    setGoals(updatedGoals);

    setTimeout(() => {
      input.focus();
      const caretPos = textBefore.length + placeholder.length;
      input.setSelectionRange(caretPos, caretPos);
    }, 0);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.path || !event.path.find((x) => x.classList && x.classList.contains('tooltip'))) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  if (!goals.length) {
    return <div>No goals available. Please add some goals.</div>;
  }

  goals.sort((a, b) => a.goal_order - b.goal_order);

  return (
    <div className="goal-container settings-container form-container">
      <form onSubmit={handleSubmit}>
        {goals.map((goal, index) => (
          <span key={index}>
            <div className="settings-sections-wrapper">
              <div className="settings-section">
                <div className="goal-header" onClick={() => setExpandedGoals(prev => ({ ...prev, [index]: !prev[index] }))}>
                  <FontAwesomeIcon icon={expandedGoals[index] ? faChevronDown : faChevronRight} style={{ marginRight: '8px' }} />
                  <h4 className="text-light-blue">{`${goal.goal_type === 'goal' ? "Powerchat G" : goal.goal_type.charAt(0).toUpperCase()}${goal.goal_type.slice(1).replace(/goal/g, ' Goal')}`} Settings</h4>
                </div>
                {expandedGoals[index] && (
                  <div className="goal-content">
                    <div className="form-row">
                      <label htmlFor={`goalName${index}`}>Goal Name</label>
                      <input
                        name="goal_name"
                        placeholder="Enter goal name"
                        value={goal.goal_name || ''}
                        onChange={(e) => handleChange(index, e)}
                        disabled={isMod}
                      />
                    </div>
                    <div className="form-row inline-items">
                      <div className="inline-item">
                        <label htmlFor={`goalCurrent${index}`}>Current Value</label>
                        <MonetaryInput
                          is_monetary={goal.is_monetary}
                          value={goal.goal_current}
                          onChange={(newValue) => handleChange(index, { target: { name: 'goal_current', value: newValue, type: 'monetary' } })}
                          disabled={isMod}
                        />
                      </div>
                      <div className="inline-item">
                        <label htmlFor={`goalMax${index}`}>Goal Value</label>
                        <MonetaryInput
                          is_monetary={goal.is_monetary}
                          value={goal.goal_max}
                          onChange={(newValue) => handleChange(index, { target: { name: 'goal_max', value: newValue, type: 'monetary' } })}
                          disabled={isMod}
                        />
                      </div>
                      {goal.goal_type === 'custom' && (
                        <div>
                          <label htmlFor={`isMonetary${index}`}>Monetary Goal?</label>
                          <input
                            name="is_monetary"
                            type="checkbox"
                            checked={goal.is_monetary || false}
                            onChange={(e) => handleChange(index, e)}
                            disabled={isMod}
                          />
                        </div>
                      )}
                    </div>
                    <div className="form-row">
                      <label htmlFor={`theme${index}`}>Theme</label>
                      <CustomSelect
                        name="theme"
                        className="theme-select-dropdown"
                        classNamePrefix="react-select"
                        value={goal.theme}
                        onChange={(name, selectedOption) => handleSelectChange(index, name, selectedOption)}
                        options={themeOptions}
                        disabled={isMod}
                      />
                    </div>
                    <div className="goal-header" onClick={() => setCustomizeColorsExpanded(prev => ({ ...prev, [index]: !prev[index] }))}>
                      <FontAwesomeIcon icon={customizeColorsExpanded[index] ? faChevronDown : faChevronRight} style={{ marginRight: '8px' }} />
                      <h4 className="text-light-blue">Customize Goal Colors</h4>
                    </div>
                    {customizeColorsExpanded[index] && (
                      <div className="goal-content">
                        <div className="form-row inline-items">
                          <div style={{ marginRight: '2rem', width: '47%' }}>
                            <label>Primary Color</label>
                            <span className="icon-btn tooltip-wrapper">
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                color="#57bad8"
                                onClick={() => setTooltip(`goalPrimaryColor${index}`)}
                              />
                              {tooltip === `goalPrimaryColor${index}` && (
                                <p className="tooltip shadow-lg">
                                  The color of the unfilled portion of the goal bar.
                                </p>
                              )}
                            </span>
                            <ColorInput
                              name={`primary_color${index}`}
                              value={goal.primary_color || '#375a7f'}
                              onValueChange={(color) => handleColorChange(index, color, 'primary_color')}
                              opacity={true}
                              disabled={isMod}
                            />
                          </div>
                          <div style={{ marginRight: '2rem', width: '47%' }}>
                            <label>Secondary Color</label>
                            <span className="icon-btn tooltip-wrapper">
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                color="#57bad8"
                                onClick={() => setTooltip(`goalSecondaryColor${index}`)}
                              />
                              {tooltip === `goalSecondaryColor${index}` && (
                                <p className="tooltip shadow-lg">
                                  The color of the filled portion of the goal bar.
                                </p>
                              )}
                            </span>
                            <ColorInput
                              name={`secondary_color${index}`}
                              value={goal.secondary_color || '#444444'}
                              onValueChange={(color) => handleColorChange(index, color, 'secondary_color')}
                              opacity={true}
                              disabled={isMod}
                            />
                          </div>
                          <div style={{ marginRight: '2rem', width: '47%' }}>
                            <label>Tertiary Color</label>
                            <span className="icon-btn tooltip-wrapper">
                              <FontAwesomeIcon
                                icon={faInfoCircle}
                                color="#57bad8"
                                onClick={() => setTooltip(`goalTertiaryColor${index}`)}
                              />
                              {tooltip === `goalTertiaryColor${index}` && (
                                <p className="tooltip shadow-lg">
                                  Tertiary color is a 3rd color used in some themes. It is not used in all themes. If you are unsure, leave this color as transparent.
                                </p>
                              )}
                            </span>
                            <ColorInput
                              name={`tertiary_color${index}`}
                              value={goal.tertiary_color || 'transparent'}
                              onValueChange={(color) => handleColorChange(index, color, 'tertiary_color')}
                              opacity={true}
                              disabled={isMod}
                            />
                          </div>
                          <div style={{ marginRight: '2rem', width: '50%' }}>
                            <label>Background Color</label>
                            <ColorInput
                              name={`background_color${index}`}
                              value={goal.background_color || 'rgba(10, 10, 10, 0.4)'}
                              onValueChange={(color) => handleColorChange(index, color, 'background_color')}
                              opacity={true}
                              disabled={isMod}
                            />
                          </div>
                          <div style={{ marginRight: '2rem' }}>
                            <label>Text Color</label>
                            <ColorInput
                              name={`text_color${index}`}
                              value={goal.text_color || '#ffffff'}
                              onValueChange={(color) => handleColorChange(index, color, 'text_color')}
                              disabled={isMod}
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="goal-header" onClick={() => setCustomizeStylesExpanded(prev => ({ ...prev, [index]: !prev[index] }))}>
                      <FontAwesomeIcon icon={customizeStylesExpanded[index] ? faChevronDown : faChevronRight} style={{ marginRight: '8px' }} />
                      <h4 className="text-light-blue">Customize Goal Styles</h4>
                    </div>
                    {customizeStylesExpanded[index] && (
                      <div className="goal-content">
                        <div className="form-row">
                          <label htmlFor={`goalFontSize${index}`}>Font Size (pixels)</label>
                          <input
                            name="font_size"
                            type="number"
                            value={goal.font_size}
                            onChange={(e) => handleChange(index, e)}
                            onBlur={(e) => handleBlur(index, e, 16)}
                            disabled={isMod}
                          />
                        </div>
                        <div className="form-row">
                          <label htmlFor={`goalFontStyle${index}`}>Font Style</label>
                          <FontSelector
                            name="font_style"
                            fonts={fontOptions}
                            styles={fontStyles}
                            placeholder="Font"
                            onFontChange={(selectedOption) => onFontChange(index, selectedOption)}
                            value={fontOptions.find((x) => x.value === goal.font_style)}
                            disabled={isMod}
                          />
                        </div>
                        <div className="form-row">
                          <label htmlFor={`goalOrientation${index}`}>Orientation</label>
                          <CustomSelect
                            name="orientation"
                            value={goal.orientation}
                            onChange={(name, selectedOption) => handleSelectChange(index, name, selectedOption)}
                            options={goalOrientationOptions}
                            disabled={isMod}
                          />
                        </div>
                        <div className="form-row">
                          <label htmlFor={`goalLength${index}`}>Goal Bar Length</label>
                          <SizeSelector
                            name={`goalLength${index}`}
                            value={goal.goal_length}
                            unit={unitOptions.find(u => u.value === goal.goal_length_unit)}
                            onSizeChange={(value, unit) => handleLengthChange(index, value, unit)}
                            styles={{
                              control: (styles) => ({ ...styles, width: '120px' }),
                              input: (styles) => ({ ...styles, width: '70px' }),
                            }}
                            disabled={isMod}
                          />
                        </div>
                        <div className="form-row">
                          <label htmlFor={`goalTextOption${index}`}>Text Options</label>
                          <span className="icon-btn tooltip-wrapper">
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                              color="#57bad8"
                              onClick={() => setTooltip(`goalTextOption${index}`)}
                            />
                            {tooltip === `goalTextOption${index}` && (
                              <p className="tooltip shadow-lg">
                                Set how you want the text on the goal bar to look. Not all themes use the text option.
                              </p>
                            )}
                          </span>
                          <CustomSelect
                            name="text_option"
                            value={goal.text_option}
                            onChange={(name, selectedOption) => handleTextOption(index, name, selectedOption)}
                            options={goalTextOptions}
                            disabled={isMod}
                          />
                        </div>
                        {goal.text_option === 'custom' && (
                          <div className="form-row help-text">
                            <p>
                              Enter custom text for your goal. You can use
                              <span
                                style={{ color: 'var(--light-blue)', cursor: 'pointer' }}
                                onClick={() => appendPlaceholderToTextValue(index, '{current}')}
                              >
                                {' {current} '}
                              </span>,
                              <span
                                style={{ color: 'var(--light-blue)', cursor: 'pointer' }}
                                onClick={() => appendPlaceholderToTextValue(index, '{max}')}
                              >
                                {' {max} '}
                              </span>,
                              <span
                                style={{ color: 'var(--light-blue)', cursor: 'pointer' }}
                                onClick={() => appendPlaceholderToTextValue(index, '{percentage}')}
                              >
                                {' {percentage} '}
                              </span>and
                              <span
                                style={{ color: 'var(--light-blue)', cursor: 'pointer' }}
                                onClick={() => appendPlaceholderToTextValue(index, '{goal_name}')}
                              >
                                {' {goal_name} '}
                              </span> as placeholders.
                            </p>
                          </div>
                        )}
                        <div className="form-row">
                          <label htmlFor={`goalTextValue${index}`}>Goal Text</label>
                          <input
                            id={`text-value-input-${index}`}
                            name="text_value"
                            value={goal.text_value || ''}
                            readOnly={goal.text_option !== 'custom'}
                            onChange={(e) => handleChange(index, e)}
                            {...goal.text_option !== 'custom' && { style: { cursor: 'not-allowed', backgroundColor: '#d3d3d3' } }}
                            disabled={isMod}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              {expandedGoals[index] && (
                <div className="settings-section preview-section">
                  <h4 className="text-light-blue">Preview</h4>
                  <div
                    className={`goal-content ${goal.font_style} ${goal.orientation}`}
                    style={{
                      backgroundColor: 'transparent',
                      padding: '0.5rem',
                      display: 'flex',
                      flexDirection: 'column',
                      height: '100vh',
                      maxHeight: '300px',
                      boxSizing: 'border-box',
                      position: 'relative',
                    }}
                  >
                    <ProgressBar
                      goalName={goal.goal_name}
                      value={goal.goal_current}
                      max={goal.goal_max}
                      text={replacePlaceholders(goal)}
                      theme={goal.theme}
                      primaryColor={goal.primary_color}
                      secondaryColor={goal.secondary_color}
                      tertiaryColor={goal.tertiary_color}
                      textColor={goal.text_color}
                      backgroundColor={goal.background_color}
                      uid={index}
                      isMonetary={goal.is_monetary}
                      fontSize={goal.font_size}
                      orientation={goal.orientation}
                      length={`${goal.length}`}
                    />
                  </div>
                </div>
              )}
            </div>
            <hr className="hr-light-blue" />
          </span>
        ))}
      {!isMod && (
        <div>
          <button type="submit" disabled={isGoalsSaved}>Save</button>
          &nbsp;&nbsp;&nbsp;
          <button type="button" onClick={() => setGoals(initialGoals)}>Cancel</button>
        </div>
      )}
      </form>
      {alertState.message && <FormAlert alertState={alertState} />}
    </div>
  );
};

export default Goal;
