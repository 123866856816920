import React, { useState } from 'react';
import axios from 'axios';
import '../css/admin-dashboard.css';
import Card from '../components/card';
import FormAlert from '../components/form-alert';

const AdminDashboard = () => {
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const restartChatService = async (name) => {
    if (!confirm(`Are you sure you want to restart ${name}?`)) {
      return;
    }
    try {
      await axios.post(`/admapi/${name.toLowerCase()}-restart`);
      triggerAlert(`Restarted ${name}`);
    } catch (err) {
      console.error(err);
      triggerAlert(`Could not restart ${name}`, false);
    }
  };

  return (
    <div className="admin-dashboard page-container">
      <Card title="Admin Dashboard" lineClass="admin-dashboard-line">
        <div className="restart-chat form-container">
          <button className="secondary-btn" onClick={() => restartChatService('YouTube')}>
            YouTube
            <img src="/static/img/youtube-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Kick')}>
            Kick.com
            <img src="/static/img/kick-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Twitch')}>
            Twitch
            <img src="/static/img/twitch-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Rumble')}>
            Rumble
            <img src="/static/img/rumble-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('DLive')}>
            DLive
            <img src="/static/img/dlive-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Cozy')}>
            Cozy.tv
            <img src="/static/img/cozytv-logo.webp"/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Robotstreamer')}>
            Robotstreamer
            <img src="/static/img/robotlogo.svg" style={{ backgroundColor: '#1DD1A1', padding: 2, borderRadius: 4 }}/>
          </button>
          <button className="secondary-btn" onClick={() => restartChatService('Trovo')}>
            Trovo
            <img src="/static/img/trovo-icon.webp"/>
          </button>
        </div>
        <FormAlert alertState={alertState} width={400} />
      </Card>
    </div>
  );
};

export default AdminDashboard;
