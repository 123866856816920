import React from 'react';
import Select from 'react-select';

const CustomSelect = ({ name, value, onChange, options, disabled = false }) => {

    const customStyles = {
        control: (provided) => ({
            ...provided,
            marginTop: '10px',
            marginBottom: '8px',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected 
            ? '#2684FF' 
            : state.isFocused 
                ? '#DEEBFF'
                : 'white',
        }),
        input: (provided) => ({
            ...provided,
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,  // Ensure the menu has a high z-index
        }),
    };

    return (
        <Select
            name={name}
            value={options.find(option => option.value === value)} // Find the correct option based on the value
            onChange={(selectedOption) => onChange(name, selectedOption)} // Pass the name and selected option
            options={options}
            styles={customStyles}
            className="custom-select"
            isDisabled={disabled}
        />
    );
};

export default CustomSelect;
