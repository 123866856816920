import React, { useState } from 'react';
import axios from 'axios';
import FormAlert from '../components/form-alert';
import FadeLoader from 'react-spinners/FadeLoader';
import Header from '../components/header';
import Captcha from '../components/captcha';
import '../css/reset-password.css';

const ResetPasswordRequest = () => {
  const [email, setEmail] = useState('');
  const [captchaDone, setCaptchaDone] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });

  const handleChange = (event) => {
    setEmail(event.target.value);
  };
  
  const captchaCallback = (token) => {
    setCaptchaDone(true);
    setCaptchaToken(token);
  };

  const resetCaptcha = () => {
    setCaptchaDone(false);
    setCaptchaToken(null);
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!captchaDone) {
      console.error('Cannot submit. Captcha incomplete.');
      return;
    }
    setLoading(true);
    await axios
      .post('/reset-password-request', { email, token: captchaToken })
      .then((response) => {
        setLoading(false);
        if (response.data === true) {
          triggerAlert('Password reset sent');
        } else {
          triggerAlert('Error - password reset not sent', false);
          resetCaptcha();
        }
      });
  };

  const spinnerStyle = {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  };

  return (
    <div className="page-container reset-password-container">
      <Header size="small" />
      <form onSubmit={handleSubmit}>
        <h3>Email:</h3>
        <input
          name="email"
          type="email"
          placeholder="Enter email to reset your password"
          onChange={handleChange}
        />
        {!captchaDone && (
          <Captcha
            captchaDone={captchaDone}
            captchaCallback={captchaCallback}
          />
        )}
        <button disabled={!captchaDone || !email} type="submit">
          Submit
        </button>
        <a href="/account-recovery">Lost access to email? Submit a recovery request.</a>
        <FormAlert alertState={alertState} width={265} />
      </form>
      <div style={spinnerStyle}>
        <FadeLoader color="#ffffff" loading={loading} size={20} />
      </div>
    </div>
  );
};

export default ResetPasswordRequest;
