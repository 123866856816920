import React, { useState } from 'react';
import axios from 'axios';
import FormAlert from './form-alert';
import '../css/change-password-form.css';
import FadeLoader from 'react-spinners/FadeLoader';

const ChangePasswordForm = () => {
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    currentPassword: '',
    newPassword: '',
    newPasswordConfirmation: '',
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      const { data } = await axios.post('/api/change-password', formData);
      triggerAlert(data, data === 'Successfully changed password');
    } catch (err) {
      console.error(err);
      triggerAlert('Could not update password', false);
    }
    setLoading(false);
  };

  const spinnerStyle = {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  };

  return (
    <div className="change-password-container form-container">
      <h4>Change Password:</h4>
      <form onSubmit={handleSubmit}>
        <div className="input-row">
          <label htmlFor="currentPassword">Password</label>
          <input
            name="currentPassword"
            placeholder="Enter current password"
            type="password"
            onChange={handleChange}
          />
        </div>
        <div className="input-row">
          <label htmlFor="newPassword">New Password</label>
          <input
            name="newPassword"
            placeholder="Enter new password"
            type="password"
            onChange={handleChange}
          />
        </div>
        <div className="input-row">
          <label htmlFor="newPasswordConfirmation">Confirm</label>
          <input
            name="newPasswordConfirmation"
            placeholder="Confirm new password"
            type="password"
            onChange={handleChange}
          />
        </div>
        <button type="submit">Save</button>
      </form>
      <div style={spinnerStyle}>
        <FadeLoader color="#ffffff" loading={loading} size={20} />
      </div>
      <FormAlert alertState={alertState} width={400} />
    </div>
  );
};

export default ChangePasswordForm;
