const CoinNames = {
    "1inch": "1inch Network",
    "1inchbsc": "1Inch Network (BSC)",
    "aave": "Aave",
    "ada": "Cardano",
    "ae": "AE",
    "algo": "ALGO",
    "ape": "ApeCoin",
    "ark": "ARK",
    "arpa": "ARPA Chain",
    "arv": "Ariva",
    "atom": "Cosmos",
    "ava": "Travala.com",
    "avabsc": "Travala.com",
    "avaerc20": "Travala.com",
    "avax": "AVAX",
    "avaxc": "Avalanche (C-Chain)",
    "avn": "AVNRich",
    "axs": "Axie Infinity",
    "babydoge": "Baby Doge Coin",
    "bat": "Basic Attention Token",
    "bcd": "Bitcoin Diamond",
    "bch": "Bitcoin Cash",
    "beam": "Beam",
    "bel": "Bella Protocol",
    "bifi": "Beefy Finance",
    "blocks": "BLOCKS",
    "bnb": "BNB",
    "bnbbsc": "BNBBSC",
    "bnbmainnet": "BNBMAINNET",
    "bone": "Bone ShibaSwap",
    "brise": "Bitgert",
    "bsv": "Bitcoin SV",
    "btc": "Bitcoin",
    "btfa": "Banana Task Force Ape",
    "btg": "Bitcoin Gold",
    "bttc": "BitTorrent-New (TRC 20)",
    "bttcbsc": "BitTorrent-NEW (Binance Smart Chain)",
    "busd": "BUSD",
    "busdbsc": "BUSDBSC",
    "c98": "Coin98",
    "cake": "CAKE",
    "chr": "CHR",
    "chz": "Chiliz",
    "cns": "CentricSwap",
    "coti": "COTI",
    "cro": "CRO",
    "ctsi": "CTSI",
    "cudos": "Cudos",
    "cult": "Cult DAO",
    "cvc": "Civic",
    "dai": "DAI",
    "dao": "DAO",
    "dash": "Dash",
    "dcr": "Decred",
    "dgb": "DigiByte",
    "dgmoon": "DogeMoon",
    "divi": "Divi",
    "doge": "DOGE",
    "dogecoin": "Buff Doge Coin",
    "dot": "POLKADOT",
    "egld": "EGLD",
    "enj": "Enjin Coin",
    "eos": "EOS",
    "etc": "Ethereum Classic",
    "eth": "Ethereum",
    "ethbsc": "Ethereum (Binance Smart Chain)",
    "ethw": "EthereumPoW",
    "feg": "FEG",
    "fil": "Filecoin",
    "firo": "FIRO",
    "floki": "Floki Inu",
    "flokibsc": "Floki (BSC)",
    "fluf": "Fluffy Coin",
    "front": "FRONT",
    "ftm": "FTM",
    "ftmmainnet": "FTMMAINNET",
    "ftt": "FTT",
    "fun": "FunFair",
    "gafa": "Gafa",
    "gal": "Project Galaxy",
    "galaerc20": "GALAERC20",
    "gas": "NeoGas",
    "ggtkn": "GG TOKEN",
    "ghc": "Galaxy Heroes Coin",
    "grs": "Groestlcoin",
    "grt": "The Graph",
    "gspi": "Shopping.io Governance",
    "gt": "Gatechain Token",
    "guard": "Guardian",
    "gusd": "GUSD",
    "hbar": "HBAR",
    "hoge": "HOGE",
    "hot": "HOT",
    "hotcross": "Hot Cross",
    "ht": "Huobi Token",
    "icx": "ICON",
    "ilv": "Illuvium",
    "iota": "IOTA",
    "iotx": "IOTX",
    "keanu": "KEANU",
    "kiba": "Kiba Inu (ERC20)",
    "kibabsc": "Kiba Inu (BSC)",
    "kishu": "KISHU",
    "klay": "Klaytn",
    "klv": "KLV",
    "kmd": "Komodo",
    "knc": "Kyber Network Crystal",
    "leash": "Doge Killer",
    "lgcy": "LGCY",
    "link": "Chainlink",
    "lsk": "Lisk",
    "ltc": "Litecoin",
    "luna": "LUNA",
    "lunc": "Terra Classic",
    "mana": "MANA",
    "marsh": "Unmarshal",
    "matic": "MATIC",
    "maticmainnet": "Polygon (Matic Mainnet)",
    "mco": "MCO",
    "miota": "MIOTA",
    "mx": "MX",
    "nano": "Nano",
    "near": "Near",
    "neo": "NEO",
    "nftb": "NFTb",
    "now": "NOW",
    "npxs": "Pundi-x",
    "ntvrk": "Netvrk",
    "nwc": "NWC",
    "ocean": "OCEAN",
    "okb": "OKB",
    "om": "OM",
    "omg": "OMG Network",
    "omni": "OMNI",
    "one": "ONE",
    "ont": "Ontology",
    "pika": "Pika",
    "pit": "PITBULL",
    "poodl": "Poodl Token",
    "poolz": "Poolz Finance",
    "qtum": "Qtum",
    "quack": "RichQuack",
    "raca": "Radio Caca",
    "rbif": "Robo Inu Finance",
    "rep": "Augur",
    "rvn": "RVN",
    "rxcg": "RXCGames",
    "sand": "SAND",
    "sfund": "Seedify.fund",
    "shib": "SHIB",
    "shibbsc": "Shiba Inu (BSC)",
    "sol": "SOL",
    "spi": "Shopping.io",
    "srk": "SRK",
    "stpt": "STP Network",
    "strax": "STRAX",
    "super": "SUPER",
    "sxp": "swipe",
    "sxpmainnet": "Solar Network",
    "tenshi": "Tenshi",
    "tfuel": "TFUEL",
    "theta": "THETA",
    "tko": "Tokocrypto",
    "tomo": "TomoChain",
    "ton": "Toncoin",
    "trvl": "DTravel",
    "trx": "Tron",
    "ttc": "TechTrees",
    "tusd": "TrueUSD",
    "uni": "Uniswap",
    "usdc": "USDC",
    "usdcmatic": "USD Coin (Polygon)",
    "usddtrc20": "USDD (TRC20)",
    "usdp": "USDP",
    "usdt": "USDT",
    "usdtbsc": "Tether-BSC",
    "usdterc20": "Tether",
    "usdtsol": "Tether (SOL)",
    "usdttrc20": "Tether",
    "ust": "UST",
    "vet": "Vechain",
    "vib": "VIB",
    "volt": "Volt Inu V2",
    "wabi": "Tael",
    "waves": "Waves",
    "xcad": "XCAD Network",
    "xcur": "XCUR",
    "xdc": "XDC Network",
    "xem": "NEM",
    "xlm": "Stellar Lumens",
    "xmr": "Monero",
    "xrp": "Ripple",
    "xtz": "Tezos",
    "xvg": "Verge",
    "xym": "XYM",
    "xyo": "XYO Network",
    "xzc": "Zcoin",
    "yfi": "YFI",
    "zbc": "Zebec Protocol",
    "zec": "Zcash",
    "zen": "Horizen",
    "zil": "Zilliqa",
    "zinu": "Zombie-Inu"
};

exports['coinNames'] = CoinNames;
