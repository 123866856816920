import React from 'react';
import ContentBox from '../components/content-box';

const ContentBoxes = ({ contentBoxes }) => {
  return contentBoxes.map(({ header, content, imgUrl }, idx) => (
    <div key={header + String(idx)}>
      <ContentBox header={header} content={content} imgUrl={imgUrl} rowNum={idx} />
    </div>
  ));
};

export default ContentBoxes;
