import React from 'react';
import '../css/header.css';

const Header = () => {
  return (
    <div className="header-container">
      <div className="text-center">
        <img src="/static/img/logo-text-only.webp" />
      </div>
    </div>
  );
};

export default Header;
