import React, { useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import axios from 'axios';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { ThemeProvider, styled } from '@mui/material/styles';
import sbSearchTheme from '../static/sbSearchTheme';

const HoverListItem = styled(ListItem)({
  '&:hover': {
    backgroundColor: '#26262d',
  },
});

const DraggableSoundboardList = ({ soundboards, selectedSoundboard, onSelect, onClose }) => {
  const [items, setItems] = useState(soundboards);

  useEffect(() => {
    setItems(soundboards);
  }, [soundboards]);

  const onDragEnd = async (result) => {
    if (!result.destination) return;

    const newItems = Array.from(items);
    const [reorderedItem] = newItems.splice(result.source.index, 1);
    newItems.splice(result.destination.index, 0, reorderedItem);

    setItems(newItems);
    try {
      await axios.patch(`/api/soundboards/${selectedSoundboard.id}`, {
        tableOrderUpdate: newItems.map((item, index) => ({
          id: item.id,
          order: index + 1
        }))
      });
    } catch (error) {
      console.error('Error reordering soundboard tables:', error);
    }
  };

  return (
    <ThemeProvider theme={sbSearchTheme}>
      <Dialog onClose={onClose} open={true} fullWidth={true} maxWidth={"md"} scroll="paper">
        <DialogTitle>
          <Typography variant="h6" style={{ padding: '16px', color: 'white' }}>Reorder Soundboards</Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 0,
              top: 0,
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable-soundboard-list">
            {(provided) => (
              <List {...provided.droppableProps} ref={provided.innerRef} style={{ maxHeight: '60vh', overflowY: 'auto' }}>
                {items.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id.toString()} index={index}>
                    {(provided) => (
                      <HoverListItem
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        selected={selectedSoundboard === item.id}
                        onClick={() => onSelect(item.id)}
                      >
                        <ListItemText primary={`${index + 1}. ${item.name}`} />
                        <DragIndicatorIcon />
                      </HoverListItem>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
      </Dialog>
    </ThemeProvider>
  );
};

export default DraggableSoundboardList;
