import React from 'react';

const PillProgressBar = ({
  goalName, // Goal name
  value, // Current value
  max, // Maximum value
  text, // Text to display
  fontSize = 16, // Base font size
  primaryColor = '#375a7f', // Bar background color
  secondaryColor = '#444', // Fill color
  backgroundColor = 'transparent',
  textColor = '#fff', // Text color
  uid = '', // Unique identifier (optional)
  length = '100%', // Bar length
  heightRatio = 2, // Height ratio (relative to font size)
}) => {
  const percentage = Math.min((value / max) * 100, 100);
  const actualFontSize = fontSize / 16;
  const heightInEm = `${actualFontSize * heightRatio}rem`; // Height in em units

  return (
    <div className={`progress-bar-top-pill${uid}`} style={{
      width: `calc(${length} - 30px)`, display: 'block', position: 'relative',
      backgroundColor,
      padding: '15px',
      paddingTop: 'calc(15px - 0.5rem)'
    }}>
      <style>
      {`
        .progress-bar-name-pill${uid} {
          color: ${textColor};
          font-size: ${actualFontSize}rem;
          font-weight: bold;
          line-height: ${heightInEm};
          white-space: nowrap;
          text-overflow: ellipsis;
          text-align: left;
          margin-bottom: 5px;
        }
        .progress-bar-pill${uid} {
          height: ${heightInEm};
          width: 100%;
          background-color: ${primaryColor};
          border-radius: ${heightInEm};
          position: relative;
          top: 0;
          left: 0;
          overflow: hidden;
          box-sizing: border-box;
          z-index: 1;
        }
        .progress-fill-pill${uid} {
          height: 100%;
          width: ${percentage}% !important;
          background-color: ${secondaryColor};
          border-radius: 0 ${heightInEm} ${heightInEm} 0;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 2;
        }
        .progress-text-pill${uid} {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          color: ${textColor};
          font-size: ${actualFontSize}rem;
          font-weight: bold;
          line-height: ${heightInEm};
          line-wrapped: nowrap;
          text-overflow: ellipsis;
          text-align: center;
          z-index: 3;
        }
      `}
      </style>
      <div className={`progress-bar-name-pill${uid}`}>{goalName}</div>
      <div className={`progress-bar-pill${uid}`}>
        <div className={`progress-fill-pill${uid}`} />
        <div className={`progress-text-pill${uid}`}>{text}</div>
      </div>
    </div>
  );
};

export default PillProgressBar;
