import React, { useState, useEffect } from 'react';
const axios = require('axios');
import ToggleSwitch from './toggle-switch';
import FormAlert from './form-alert';

const EmailSettingsForm = () => {
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const getEmailSettings = async () => {
      try {
        const emailSettings = await axios.get('/api/email-settings');
        setFormData(emailSettings.data);
      } catch (err) {
        console.error(err);
      }
    };

    getEmailSettings();
  }, []);

  const handleToggle = (name) => {
    const value = formData[name] ? false : true;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const saveEmailSettings = async (event) => {
    event.preventDefault();
    try {
      await axios.put('api/email-settings', formData);
      triggerAlert('Email settings updated successfully');
    } catch (err) {
      console.error(err);
      triggerAlert('Could not update email settings', false);
    }
  };

  return (
    <div className="form-container">
      <form onSubmit={saveEmailSettings}>
        <div className="radio-inputs">
          <div className="flex-row">
            <ToggleSwitch
              name="optIn"
              onChange={() => handleToggle('optIn')}
              checked={formData.optIn}
            />
            <label htmlFor="optIn">Subscribe to emails</label>
          </div>
          <div className="note">
            Receive newsletter emails from Powerchat.live to stay updated on our latest developments.
          </div>
        </div>
        <button type="submit">Save</button>
      </form>
      <FormAlert alertState={alertState} width={400} />
    </div>
  );
};

export default EmailSettingsForm;
