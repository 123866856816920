import { createTheme } from '@mui/material/styles';

const commonStyles = {
  palette: {
    colors: {
      white: 'var(--white)',
      black: 'var(--black)',
      grey: {
        dark: 'var(--dark-grey)',
        medium: 'var(--medium-grey)',
        light: 'var(--light-grey)',
        base: 'var(--grey)',
      },
      blue: {
        light: 'var(--light-blue)',
      },
    },
    backgrounds: {
      transparent: 'transparent !important',
      darkGrey: 'var(--dark-grey)',
      mediumGrey: 'var(--medium-grey)',
    },
  },
  typography: {
    whiteBold: {
      color: 'var(--white)',
      fontWeight: 'bold',
    },
    base: {
      fontSize: '0.9rem',
      color: 'var(--white)',
      '&.Mui-focused': {
        color: 'var(--light-blue)',
      },
      '& input': {
        color: 'var(--white)',
      },
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      padding: '10px',
    },
    modernInput: {
      color: 'var(--white)',
      '&::placeholder': {
        color: 'var(--white)',
      },
      backgroundColor: 'var(--dark-grey)',
      borderRadius: '4px',
      padding: '10px',
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'var(--light-grey)',
        },
        '&:hover fieldset': {
          borderColor: 'var(--light-blue)',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'var(--light-blue)',
        },
      },
    },
  },
  transitions: {
    common: {
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'scale(1.03)',
      },
    },
  },
  borders: {
    lightGrey: {
      '& fieldset': {
        borderColor: 'var(--light-grey)',
      },
      '&:hover fieldset': {
        borderColor: 'var(--light-blue)',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'var(--light-blue)',
      },
    },
    borderRadius: 'var(--border-radius)',
    topRounded: {
      borderRadius: 'var(--border-radius) var(--border-radius) 0 0',
    },
  },
  effects: {
    modernSleek: {
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
      borderRadius: '10px',
      overflow: 'hidden',
    },
  },
  gridItem: {
    display: 'flex',
    justifyContent: 'center',
  },
};

const theme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxWidth: '100px',
          overflow: 'auto',
          whiteSpace: 'normal',
          textAlign: 'center',
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          color: commonStyles.palette.colors.white,
        },
      },
    },
    MuiPaper: { 
      styleOverrides: {
        root: {
          '&.MuiCard-root': {
            width: '145px',
            height: '200x',
            ...commonStyles.transitions.common,
            backgroundColor: commonStyles.palette.backgrounds.mediumGrey,
            borderRadius: commonStyles.borders.borderRadius,
            boxShadow: 'none',
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          ...commonStyles.typography.base,
          color: commonStyles.palette.colors.white,
          backgroundColor: commonStyles.palette.backgrounds.transparent,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...commonStyles.typography.whiteBold,
          backgroundColor: commonStyles.palette.colors.blue.light,
          width: '80%',
          padding: '5px 10px',
          ...commonStyles.typography.base,
          '&:hover': {
            backgroundColor: commonStyles.palette.colors.grey.light,
            color: commonStyles.palette.colors.grey.dark,
          },
          '&.add-to-soundboard': {
            width: '60%',
            padding: '3px 5px',
            fontSize: '0.65rem',
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          ...commonStyles.typography.modernInput,
          maxWidth: 'none', // Set max width to unlimited
          '&.MuiFormControl-root.MuiTextField-root': {
            margin: '0px',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: commonStyles.palette.backgrounds.darkGrey,
          color: commonStyles.palette.colors.white,
        },
        root: {
          '& .MuiOutlinedInput-root': {
            ...commonStyles.borders.lightGrey,
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: commonStyles.palette.backgrounds.darkGrey,
          color: commonStyles.palette.colors.white,
          padding: '20px',
          borderRadius: commonStyles.borders.borderRadius,
          width: '100%',
          maxWidth: '500px',
          ...commonStyles.effects.modernSleek,
          textAlign: 'center',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.blue.light,
        },
        thumb: {
          color: commonStyles.palette.colors.white,
        },
        track: {
          color: commonStyles.palette.colors.white,
        },
        rail: {
          color: commonStyles.palette.colors.white,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.blue.light,
          '&:hover': {
            color: commonStyles.palette.colors.grey.light,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'relative',
          color: commonStyles.palette.colors.white,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.white,
          margin: '3px',
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          overflowY: 'clip',
          padding: '0px',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          backgroundColor: commonStyles.palette.backgrounds.darkGrey,
          color: commonStyles.palette.colors.white,
          width: '100%',
          maxWidth: '345px',
          cursor: 'pointer',
          borderRadius: commonStyles.borders.borderRadius,
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          height: '120px',
          width: '100%',
          objectFit: 'cover',
          borderRadius: commonStyles.borders.topRounded,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.white,
          padding: '5px',
          '&.Mui-focused': {
            color: commonStyles.palette.colors.blue.light,
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          overflowY: 'clip',
        },
        item: {
          ...commonStyles.gridItem,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.white,
        },
        indicator: {
          backgroundColor: commonStyles.palette.colors.blue.light,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.white,
          '&.Mui-selected': {
            color: commonStyles.palette.colors.blue.light,
          },
        },
      },
    },
  },
});

export default theme;
