import React from 'react';
import { Link } from 'react-router-dom';
import '../css/footer.css';

const Footer = ({ topMargin }) => {
  const footerLinks = [
    { name: 'Contact Us', url: '/contact-us' },
    { name: 'Support', url: 'https://discord.gg/32UCnreakK' },
    { name: 'Terms of Service', url: '/terms-of-service' },
    { name: 'Privacy Policy', url: '/privacy-policy' },
  ];
  const socialMediaLinks = [
    {
      url: 'https://discord.gg/32UCnreakK',
      logoImgSrc: '/static/img/discord-logo.webp',
    },
    // {
    //   url: 'https://gab.com/powerchat',
    //   logoImgSrc: '/static/img/gab-logo.webp',
    // },
    {
      url: 'https://www.youtube.com/@powerchat',
      logoImgSrc: '/static/img/youtube-logo.webp',
    },
    {
      url: 'https://t.me/powerchatlive',
      logoImgSrc: '/static/img/telegram-logo.webp',
    },
    // Still need to create accounts:
    // {
    //   url: 'https://instagram.com',
    //   logoImgSrc: '/static/img/instagram.webp',
    // },
    // {
    //   url: 'https://www.twitter.com',
    //   logoImgSrc: '/static/img/twitter.webp',
    // },
  ];

  return (
    <div className={`footer-container ${topMargin ? 'top-margin' : ''}`}>
      <div className="footer-links">
        <div className="footer-logo">
          <Link to="/">
            <img src="/static/img/powerchat-full-text-logo.webp" height="60px" />
          </Link>
        </div>
        {footerLinks.map((link, idx) =>
          link.name == 'Support' ? (
            <a key={link.name + String(idx)} href={link.url}>
              {link.name}
            </a>
          ) : (
            <Link to={link.url} key={link.name + String(idx)}>
              {link.name}
            </Link>
          )
        )}
      </div>
      <div className="text-center footer-title-container">
        <div className="mobile-footer-logo">
          <img src="/static/img/powerchat-full-text-logo.webp" height="60px" />
          <h3 className="subheader text-center">
            Paid chat platform for streamers
          </h3>
        </div>
        <div className="social-media-links">
          {socialMediaLinks.map(({ url, logoImgSrc }, idx) => (
            <a
              href={url}
              target="_blank"
              rel="noopener noreferrer"
              key={logoImgSrc + String(idx)}
            >
              <img src={logoImgSrc} />
            </a>
          ))}
        </div>
      </div>
      <span className="copyright-text">&copy;2022 Powerchat</span>
    </div>
  );
};

export default Footer;
