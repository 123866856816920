import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Header from '../components/header';
import useAuth from '../hooks/useAuth';
import FormAlert from '../components/form-alert';
import Captcha from '../components/captcha';
import TotpDialog from '../components/dialogs/totp-dialog';
import '../css/login.css';

const Login = () => {
  const [user, setUser] = useState({
    usernameOrEmail: '',
    password: '',
  });
  const [alertState, setAlertState] = useState({
    message: '',
    success: false,
  });
  const [resendAlertState, setResendAlertState] = useState({
    message: '',
    success: true,
  });
  const [redirect, setRedirect] = useState(null);
  const [showResendConfirmation, setShowResendConfirmation] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [captchaDone, setCaptchaDone] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [totpDialogOpen, setTotpDialogOpen] = useState(false);

  const { login, loading, error, authInfo } = useAuth();

  useEffect(() => {
    let urlParams = new URLSearchParams(window.location.search);
    setRedirect(urlParams.get('redirect'));
  });

  const captchaCallback = async (token) => {
    setCaptchaDone(true);
    setCaptchaToken(token);
  };

  const triggerAlert = (message, show = true) => {
    setAlertState({
      message,
      success: false,
    });
    setShowAlert(show);
  };

  useEffect(() => {
    if (authInfo) {
      if (authInfo.needsTotp) {
        setTotpDialogOpen(true);
      }
    }
  }, [authInfo]);

  useEffect(() => {
    if (error) {
      setCaptchaDone(false);
      if (error.response.data.length > 0) {
        let message = error.response.data[0];
        if (error.response.data === 'Internal Server Error') {
          message = 'Server error';
        }
        setShowResendConfirmation(message == 'User is not confirmed');
        triggerAlert(message);
      } else {
        triggerAlert('Login failed.');
      }
    } else {
      triggerAlert('', false);
    }
  }, [error]);

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const sendEmailConfirmation = async () => {
    const { usernameOrEmail } = user;
    try {
      await axios
        .post('/confirm-email', { usernameOrEmail })
        .then((response) => {
          if (response.status === 200) {
            setResendAlertState({
              message: 'Confirmation email resent',
              success: true,
            });
          } else {
            setResendAlertState({
              message: 'Could not send confirmation email',
              success: false,
            });
          }
        });
    } catch (err) {
      console.error(err);
      setResendAlertState({
        message: 'Could not send confirmation email',
        success: false,
      });
    }
  };

  const onTotpComplete = ({totp, backupCode}) => {
    setTotpDialogOpen(false);
    const { usernameOrEmail, password } = user;
    login({ usernameOrEmail, password, token: captchaToken, totp, backupCode, redirect });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!captchaDone) {
      console.error('Cannot log in. Captcha incomplete.');
      return;
    }
    const { usernameOrEmail, password } = user;
    login({ usernameOrEmail, password, token: captchaToken, redirect });
  };

  const onTotpClose = () => {
    setTotpDialogOpen(false);
  };

  return (
    <div className="page-container">
      <div className="login-container">
        <Header size="small" />
        <form onSubmit={handleSubmit}>
          <h3>Login:</h3>
          <input
            name="usernameOrEmail"
            placeholder="Username / Email"
            onChange={handleChange}
          />
          <input
            name="password"
            type="password"
            placeholder="Password"
            onChange={handleChange}
          />
          {!captchaDone && (
            <Captcha
              captchaDone={captchaDone}
              captchaCallback={captchaCallback}
            />
          )}
          <button disabled={!captchaDone || loading}>Login</button>
          <div className="link-container">
            <div className="reset-password-request-link">
              <a href="/reset-password-request">Forgot password?</a>
            </div>
            <div className="signup-link">
              <span>Don&apos;t have an account?</span>
              <Link to="/signup">Signup</Link>
            </div>
          </div>
          {showAlert && (
            <FormAlert
              alertState={alertState}
              width={265}
              margin={'0'}
              duration={-1}
            />
          )}
          {showResendConfirmation && (
            <p>
              Click{' '}
              <span className="link" onClick={sendEmailConfirmation}>
                here
              </span>{' '}
              to resend confirmation email
            </p>
          )}
          <FormAlert alertState={resendAlertState} width={265} margin={'0'} />
        </form>
        <TotpDialog
          open={totpDialogOpen}
          onComplete={onTotpComplete}
          enableBackupCode={true}
          onClose={onTotpClose}
        />
      </div>
    </div>
  );
};

export default Login;
