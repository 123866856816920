import React from 'react';
import ViewCount from '../components/view-count.jsx';

const ViewCountOverlay = () => {
  return (
    <ViewCount />
  );
};

export default ViewCountOverlay;
