import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import ToggleSwitch from './toggle-switch';
import FormAlert from './form-alert';
import '../css/settings-form.css';
import '../css/testing.css';
import lodash from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import useAuth from '../hooks/useAuth';

const Testing = ({ ...props }) => {
  const { user } = useAuth();
  const initialFormState = { ...props };
  const initialRender = useRef(true);
  const [tooltip, setTooltip] = useState('');
  const [formData, setFormData] = useState(initialFormState);
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const testDonation = () => {
    event.preventDefault();
    lodash.throttle(async function () {
      await axios.post('/api/testDonation');
      triggerAlert('Test donation sent');
    }, 2000)();
  };

  const testEmotes = async () => {
    event.preventDefault();
    const emotes = [
      '/static/img/powerchat-logo.webp',
      '/static/img/youtube-logo.webp',
      '/static/img/twitch-logo.webp',
      '/static/img/kick-logo.webp',
      '/static/img/trovo-icon.webp',
      '/static/img/rumble-logo.webp',
      '/static/img/dlive-logo.webp',
    ];

    // pick a random number between 1 and 10
    const rndEmoteCount = Math.floor(Math.random() * 10) + 1;

    for(let i = 0; i < rndEmoteCount; i++) {
      const rndEmote = emotes[Math.floor(Math.random() * emotes.length)];
      try{
        axios.post('/api/test-emote-wall',
        {
          emote: rndEmote
        });
      } catch (error) {
        console.error(error);
        triggerAlert('Could not send test emotes', false);
      }
    }
    triggerAlert('Overlay settings successfully updated', true);
  };

  const handleToggle = (name) => {
    const value = !formData[name];
    setFormData(prevState => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      updateTestViewerCounts();
    }
  }, [formData.testViewCounts]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const updateTestViewerCounts = async () => {
    try {
      await axios.put('/api/test-view-counts', { testViewCounts: formData.testViewCounts });

      triggerAlert('Overlay settings successfully updated', true);
    } catch (error) {
      console.error(error);
      triggerAlert('Could not update overlay settings', false);
    }
  }

  return (
    <div className="settings-container form-container testing-container">
      <form>
        <div className="settings-sections-wrapper">
          <div className="settings-section">
            <div className="input-row tooltip-wrapper">
              <label htmlFor="testDonation">
                Send a generic $50 paid chat.<br/>You can send custom test alerts from your
                &nbsp;<a href={'/' + user.username}>Powerchat page</a> if you&apos;re logged in.
              </label>
              <div className="tooltip-input">
                <button onClick={(event) => testDonation(event)} className="secondary-btn">
                  Send Test Alert
                </button>
              </div>
            </div>
            <div className="input-row tooltip-wrapper">
              <label htmlFor="testEmotes">
                Send a random amount of emotes to your emote wall overlay to test it out.
              </label>
              <div className="tooltip-input">
                <button onClick={(event) => testEmotes(event)} className="secondary-btn">
                  Send Test Emotes
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="settings-sections-wrapper">
          <div className="radio-inputs">
            <div className="input-row">
              <div className="flex-row">
                <div className="toggle-container">
                  <ToggleSwitch
                    name="testViewCounts"
                    onChange={() => handleToggle('testViewCounts')}
                    checked={formData.testViewCounts}
                  />
                  <label htmlFor="testViewCounts">
                    Enable test viewer counts
                    <span className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('testViewCounts')}
                      />
                      {tooltip === 'testViewCounts' && (
                        <p className="tooltip shadow-lg">
                          This option will set all offline external integrations to show 0000 viewers for testing how it looks.<br />
                          <br />
                          Note: You can change how the overlay looks in the &quot;Settings&quot; menu.
                        </p>
                      )}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <FormAlert alertState={alertState} width={400} />
    </div>
  );
};

export default Testing;
