import React, { useState } from 'react';
import axios from 'axios';
import FormAlert from '../components/form-alert';
import Header from '../components/header';
import Captcha from '../components/captcha';
import FadeLoader from 'react-spinners/FadeLoader';
import TotpDialog from '../components/dialogs/totp-dialog';
import '../css/account-recovery.css';

const AccountRecovery = () => {
  const [formData, setFormData] = useState({
    usernameOrEmail: '',
    reason: '',
    newEmail: '',
  });
  const [usernameSubmitted, setUsernameSubmitted] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [requireTotp, setRequireTotp] = useState(false);
  const [notSetup, setNotSetup] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [questionAnswers, setQuestionAnswers] = useState([]);
  const [captchaDone, setCaptchaDone] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [totpDialogOpen, setTotpDialogOpen] = useState(false);
  
  const captchaCallback = async (token) => {
    setCaptchaDone(true);
    setCaptchaToken(token);
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value
    });
  };

  const answerChange = (evt) => {
    let nextState = questionAnswers.map((s, i) => {
      if (evt.target.name == 'answer' + i) {
        return {
          ...s,
          answer: evt.target.value
        };
      } else {
        return s;
      }
    });
    setQuestionAnswers(nextState);
  };

  const submitUsername = async () => {
    if (!formData.usernameOrEmail || formData.usernameOrEmail == '') {
      return;
    }
    try {
      let response = await axios.get('/pubapi/security-questions/' + formData.usernameOrEmail);
      if (response.data.length == 0) {
        setNotSetup(true);
      }
      else {
        setQuestionAnswers(response.data.map(({ id, question }) => ({ id, question, answer: ''})));
        setUsernameSubmitted(true);
      }
    } catch (err) {
      console.error(err);
      triggerAlert('Username or email not found', false);
    }
  };

  const handleSubmit = () => {
    event.preventDefault();
    if (formSubmitted) {
      if (!isValidEmail(formData.newEmail)) {
        return triggerAlert('Email format not valid', false);
      }
      else if (requireTotp) {
        setTotpDialogOpen(true);
      } else {
        submitNewEmail();
      }
    }
    else if (usernameSubmitted) {
      submitAnswers();
    } else {
      submitUsername();
    }
  };
  
  const submitAnswers = async () => {
    if (!captchaDone) {
      return;
    }
    let body = {
      captchaToken,
      ...formData,
    };
    body['answers'] = questionAnswers.reduce((a,x) => ({ ...a, [x.id]: x.answer}), {});

    setLoading(true);
    try {
      let res = await axios.post('/pubapi/account-recovery', body);
      setFormSubmitted(true);
      setRequireTotp(res.data.requireTotp);
    } catch (err) {
      console.error(err);
      triggerAlert('Could not submit request', false);
    } finally {
      setLoading(false);
    }
  };
  
  const onTotpComplete = ({totp, backupCode}) => {
    setTotpDialogOpen(false);
    submitNewEmail(totp, backupCode);
  };

  const submitNewEmail = async (totp = null, backupCode = null) => {
    try {
      setLoading(true);
      let answers = questionAnswers.reduce((a,x) => ({ ...a, [x.id]: x.answer}), {});
      let { newEmail, usernameOrEmail } = formData;
      await axios.post('/pubapi/account-recovery/email', {
        usernameOrEmail,
        answers,
        newEmail,
        totp,
        captchaToken,
        backupCode,
      });
      setEmailChanged(true);
    } catch (err) {
      console.error(err);
      triggerAlert(err.response.data, false);
    } finally {
      setLoading(false);
    }
  };

  const isValidEmail = (email) => {
    return email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+/i);
  };
 
  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const spinnerStyle = {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  };

  return (
    <div className="page-container reset-password-container recovery-form">
      <Header size="small" />
      <form onSubmit={handleSubmit}>
      {notSetup ? ( 
        <div>
          Sorry, this account doesn&apos;t have recovery questions setup. Please <a href="/contact-us">reach out to us for support</a>.
        </div>
      ) : emailChanged ? (
        <div>
          Your email change request is pending. Please confirm your new email to complete.<br/>
          A confirmation email has been sent to {formData.newEmail}.
        </div>
      ) : formSubmitted ? (
        <div>
          <p>Please enter a new email address:</p>
          <input placeholder="Email" type="email" name="newEmail" onChange={handleChange} value={formData.newEmail} />
        </div>
      ) : !usernameSubmitted ? (
        <input name="usernameOrEmail" type="text"
          value={formData.usernameOrEmail}
          onChange={handleChange}
          placeholder="Enter username or email" />
      ) : (
        <div className="security-questions-container">
        { questionAnswers.map(({ question, answer }, index) => (
          <div key={index}>
            <div style={{marginBottom: 10}}>{question}</div>
            <input type="text" name={'answer' + index}
              autoComplete="off"
              value={answer}
              onChange={answerChange}
            />
          </div>
        ))}
        {!captchaDone && (
          <Captcha
            captchaDone={captchaDone}
            captchaCallback={captchaCallback}
          />
        )}
        </div>
      )}
      {!notSetup && !emailChanged && (
        <button disabled={loading || usernameSubmitted && !captchaDone}
          type="submit" className="primary-btn">
          Submit
        </button>
      )}
        <div style={spinnerStyle}>
          <FadeLoader color="#ffffff" loading={loading} size={20} />
        </div>
        <FormAlert alertState={alertState} width={265} />
      </form>
      <TotpDialog
        open={totpDialogOpen}
        onComplete={onTotpComplete}
        enableBackupCode={true}
        onClose={() => setTotpDialogOpen(false)}
      />
    </div>
  );
};
export default AccountRecovery;
