import React from 'react';
import '../css/content-box.css';

const ContentBox = ({ header, content, imgUrl, rowNum }) => {
  const isEvenRow = rowNum % 2 === 0;

  return (
    <div className="content-box">
      {imgUrl && !isEvenRow && (
        <div className="odd-row content-box-flex-item">
          <img src={imgUrl} />
        </div>
      )}
      {imgUrl && isEvenRow && (
        <div className="even-row content-box-flex-item even-row-mobile">
          <img src={imgUrl} />
        </div>
      )}
      <div className="content-box-flex-item">
        <div className={isEvenRow ? 'text-box even-row' : 'text-box odd-row'}>
          <h4>{header}</h4>
          {content}
        </div>
      </div>
      {imgUrl && isEvenRow && (
        <div className="even-row content-box-flex-item">
          <img src={imgUrl} />
        </div>
      )}
    </div>
  );
};

export default ContentBox;
