import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import FadeLoader from 'react-spinners/FadeLoader';
import FormAlert from '../components/form-alert';
import Header from '../components/header';
import Captcha from '../components/captcha';
import axios from 'axios';
import '../css/reset-password.css';

const ResetPassword = () => {
  const { token } = useParams();
  const [formData, setFormData] = useState({
    password: '',
    passwordConfirmation: '',
  });
  const [captchaDone, setCaptchaDone] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const captchaCallback = (token) => {
    setCaptchaDone(true);
    setCaptchaToken(token);
  };

  const resetCaptcha = () => {
    setCaptchaDone(false);
    setCaptchaToken(null);
  };

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!captchaDone) {
      console.error('Cannot submit. Captcha incomplete.');
      return;
    }
    setLoading(true);
    await axios
      .post('/change-password/' + token, { ...formData, token: captchaToken })
      .then((response) => {
        setLoading(false);
        if (response.data === 'Successfully changed password') {
          window.location = '/login';
        } else {
          triggerAlert('Error - ' + response.data, false);
          resetCaptcha();
        }
      });
  };

  const spinnerStyle = {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    width: '1000px',
    justifyContent: 'space-around',
  };

  return (
    <div className="page-container reset-password-container">
      <div className="reset-password">
        <Header size="small" />
        <form onSubmit={handleSubmit}>
          <h3>Password Reset:</h3>
          <input
            name="password"
            type="password"
            placeholder="New password"
            onChange={handleChange}
          />
          <input
            name="passwordConfirmation"
            type="password"
            placeholder="Confirm new password"
            onChange={handleChange}
          />
          {!captchaDone && (
            <Captcha
              captchaDone={captchaDone}
              captchaCallback={captchaCallback}
            />
          )}
          <button
            type="submit"
            disabled={
              !captchaDone ||
              !formData.password ||
              formData.password != formData.passwordConfirmation
            }
          >
            Submit
          </button>
          <FormAlert alertState={alertState} width={400} />
        </form>
        <div style={spinnerStyle}>
          <FadeLoader color="#ffffff" loading={loading} size={20} />
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
