import React from 'react';
import '../css/hero-section.css';

const HeroSection = ({ imgUrl, children }) => {
  return (
    <div
      className="hero-section text-center"
      style={{
        backgroundImage: `linear-gradient(to bottom, transparent, var(--black)), url(${imgUrl})`,
      }}
    >
      <div className="section-text-wrapper">
        <div className="section-text">{children}</div>
      </div>
    </div>
  );
};

export default HeroSection;
