import React, { useEffect } from 'react';

const Captcha = ({ captchaDone, captchaCallback }) => {
  useEffect(() => {
    const render = () =>
      window.grecaptcha.render('captcha-container', {
        sitekey: process.env.RECAPTCHA_SITEKEY,
        callback: captchaCallback,
        theme: 'dark',
      });
    if (!window.grecaptcha || !window.grecaptcha.render) {
      setTimeout(render, 500);
    } else {
      render();
    }
  }, [captchaDone]);

  return <div id="captcha-container"></div>;
};

export default Captcha;
