import React from 'react';
import Card from '../components/card';
import '../css/contact-us.css';

const ContactUs = () => {
  return (
    <div className="page-container">
      <div className="contact-us-container">
        <Card title="Contact Us" lineClass="contact-us-line">
          <div className="contact-section">
            <span>General inquiries and media:</span>
            <p>contact@powerchat.live</p>
          </div>
          <div className="contact-section">
            <span>For support contact us on Discord:</span>
            <p>
              <a href="https://discord.gg/32UCnreakK">
                Discord Support Channel
              </a>
            </p>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default ContactUs;
