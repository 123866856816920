import axios from 'axios';

export const getCurrentUser = async () => {
  try {
    const { data } = await axios.get('/me');
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const getProfileData = async () => {
  try {
    const { data } = await axios.get('/profile-data');
    return data;
  } catch (err) {
    console.error(err);
  }
};

export const signup = async (params) => {
  try {
    const response = await axios.post('/signup', { ...params });
    return response.data.data;
  } catch (err) {
    console.error(err);
    throw err;
  }
};
