import React from 'react';
import DefaultProgressBar from './progress_bars/default';
import PillProgressBar from './progress_bars/pill';
import OutlineGlowProgressBar from './progress_bars/outline-glow';
import VerticalLeftProgressBar from './progress_bars/vertical-left';
import VerticalRightProgressBar from './progress_bars/vertical-right';
import TiltedBackProgressBar from './progress_bars/tilted-back';
import TiltedForwardProgressBar from './progress_bars/tilted-forward';
// Import other themed progress bars as needed

const ProgressBar = (props) => {
  switch (props.theme.toLowerCase()) {
    case 'outline-glow':
      return <OutlineGlowProgressBar {...props} />;

    case 'pill':
      return <PillProgressBar {...props} />;

    case 'vertical-left':
      return <VerticalLeftProgressBar {...props} />;

    case 'vertical-right':
      return <VerticalRightProgressBar {...props} />;

    case 'tilted-back':
      return <TiltedBackProgressBar {...props} />;

    case 'tilted-forward':
      return <TiltedForwardProgressBar {...props} />;

    default:
      return <DefaultProgressBar {...props} />;
  }
};

export default ProgressBar;
