import React, { useState, useEffect } from 'react';
import '../css/community.css';
import '../css/followers.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Search from './search';

const Followers = ({
    list
    })=>{
  const [followers, setFollowers] = useState([]);
  const [filteredFollowers,setFilteredFollowers] = useState([]);
  const toggleOpenFollower = (index) => {
    setFollowers((follows) => {
      follows[index]['opened'] = !follows[index]['opened'];
      return [...follows];
    });
  };

  useEffect(() => {
    if (list) {
      let tmp =  list
          .map((x) => {
            return { ...x, opened: false, visible: true };
          })
      setFollowers(tmp);
      setFilteredFollowers(tmp);
    } 
  },[list]);  
  const searchFilterCallback = ({ username }, searchInput) => {
    return username.toLowerCase().includes(searchInput);
  }
  return (
        <div className="followers">
          {/*<h3>Notify Followers</h3>
            <button
              onClick={notifyFollowersOfStream}
            >I am Live!</button>*/}
          <h3>Total Followers: {followers.length}</h3>
          <Search
            inputPlaceholder="Filter by username"
            initialData={followers}
            setData={setFilteredFollowers}
            filterCallback={searchFilterCallback}
          />
          <div className="followers-mobile">
              {filteredFollowers
              .filter((x) => x.visible)
              .map((user, index) => (
                <div key={index} className="follower-container">
                  <div
                    className="dropdown-toggle"
                    onClick={() => toggleOpenFollower(index)}
                  >
                    <div className="toggle-button">
                      {filteredFollowers[index].opened ? (
                        <FontAwesomeIcon icon={faChevronUp} size="lg" />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} size="lg" />
                      )}
                    </div>
                    <div className="title">{user.username}</div>
                  </div>
                  {filteredFollowers[index].opened && (
                    <div className="follower-info">
                      <div>Username: {user.username}</div>
                      <div>Follower since: {user.date_followed.substring(0,10)}</div>
                    </div>
                  )}
                </div>
              ))
              }
          </div>
          <div className="followers-table">
            <table>
              {filteredFollowers.length>0 && (
                <thead>
                  <tr>
                    <th></th>
                    <th>
                      <span>Username</span>
                    </th>
                    <th>
                      <span>Follower Since</span>
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {filteredFollowers
                  .filter((x) => x.visible)
                  .map(( user, index) => {
                  return (
                    <tr key={index}>
                      <td className="crop-wrapper"><img src={user.avatar || `/static/img/user-default.jpg`} /></td>
                      <td>{user.username}</td>
                      <td>{user.date_followed.substring(0,10)}</td>
                    </tr>
                  )}
                )}
                
              </tbody>
            </table>
            </div>
        </div>
      );
}
export default Followers;
