import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Navbar from './components/navbar';
import Footer from './components/footer';
import useAuth from './hooks/useAuth';
import Login from './pages/login';
import Signup from './pages/signup';
import AccountRecovery from './pages/account-recovery';
import ResetPasswordRequest from './pages/reset-password-request';
import ResetPassword from './pages/reset-password';
import Features from './pages/features';
import Pricing from './pages/pricing';
import Learn from './pages/learn';
import TermsOfService from './pages/terms-of-service';
import PrivacyPolicy from './pages/privacy-policy';
import AccountSettings from './pages/account-settings';
import Home from './pages/home';
import ContactUs from './pages/contact-us';
import CreatorDashboard from './pages/creator-dashboard';
import PaidChatPage from './pages/paid-chat';
import TtsPage from './pages/tts';
import ChatOverlay from './pages/chat-overlay';
import EventsOverlay from './pages/events-overlay';
import GoalOverlay from './pages/goal-overlay';
import Moderation from './pages/moderation';
import LiveNowNetwork from './pages/live-now-network';
import PageError from './pages/page-error';
import ViewCountOverlay from './pages/view-count-overlay';
import EmoteWallOverlay from './pages/emote-wall-overlay';
import QrOverlay from './pages/qr-overlay';
import LinkOverlay from './pages/link-overlay';
import AdminDashboard from './pages/admin-dashboard';

// Webpage with header and footer
const PageRoute = ({ component, ...props }) => {
  document.body.style.backgroundColor = 'var(--black)';
  document.documentElement.style.backgroundColor = 'var(--black)';
  return (
    <div>
      <Navbar />
      <Route {...props} component={component} />
      <Footer />
    </div>
  );
};

const AuthenticatedRoute = ({ component, ...props }) => {
  const { user } = useAuth();
  if (!user) return <Redirect to="/login" />;
  return <PageRoute component={component} {...props} />;
};

const ModRoute = ({ component, ...props }) => {
  const { user, isMod } = useAuth();
  if (!user || !isMod) return <Route render={() => <PageError errorCode={403} />} />;
  return <PageRoute component={component} {...props} />;
};

const AdminRoute = ({ component, ...props }) => {
  const { user, isAdmin } = useAuth();
  if (!user || !isAdmin) return <Route render={() => <PageError errorCode={403} />} />;
  return <PageRoute component={component} {...props} />;
};

const RedirectRoute = ({ to }) => {
  return <Redirect to={to} />;
};

const Routes = () => (
  <Switch>
    <AuthenticatedRoute exact path="/account-settings" component={AccountSettings} />
    <AuthenticatedRoute  exact path="/creator-dashboard" component={CreatorDashboard} />
    <ModRoute exact path="/moderation" component={Moderation} />
    <AdminRoute exact path="/admin-dashboard" component={AdminDashboard} />
    <AdminRoute exact path="/network" component={LiveNowNetwork} />
    <RedirectRoute path="/dashboard" to="/creator-dashboard" />
    <PageRoute exact path="/login" component={Login} />
    <PageRoute exact path="/signup" component={Signup} />
    <PageRoute
      exact
      path="/reset-password-request"
      component={ResetPasswordRequest}
    />
    <PageRoute
      exact
      path="/account-recovery"
      component={AccountRecovery}
    />
    <PageRoute exact path="/reset-password/:token" component={ResetPassword} />
    <PageRoute exact path="/contact-us" component={ContactUs} />
    <PageRoute exact path="/features" component={Features} />
    <PageRoute exact path="/pricing" component={Pricing} />
    <PageRoute exact path="/learn" component={Learn} />
    <PageRoute exact path="/terms-of-service" component={TermsOfService} />
    <PageRoute exact path="/privacy-policy" component={PrivacyPolicy} />
    <PageRoute exact path="/" component={Home} />
    <Route path="/:username/tts" component={TtsPage} />
    <Route path="/:username/events" component={EventsOverlay} />
    <Route path="/:username/goal" component={GoalOverlay} />
    <Route path="/:username/subgoal" component={GoalOverlay} />
    <Route path="/:username/followgoal" component={GoalOverlay} />
    <Route path="/:username/customgoal/:goalId" component={GoalOverlay} />
    <Route path="/:username/chat" component={ChatOverlay} />
    <Route path="/:username/view-count" component={ViewCountOverlay} />
    <Route path="/:username/emote-wall" component={EmoteWallOverlay} />
    <Route path="/:username/qr" component={QrOverlay} />
    <Route path="/:username/link" component={LinkOverlay} />
    <PageRoute path="/:username" component={PaidChatPage} />
    <PageRoute exact path="/error/:errorCode" render={(props) => {
      const errorCode = parseInt(props.match.params.errorCode, 10);
      return <PageError errorCode={errorCode} />;
    }} />
    {/* Fallback route for 404 Not Found */}
    <Route render={() => <PageError errorCode={404} />} />
  </Switch>
);

export default Routes;
