import React, { useState, useEffect } from 'react';
import '../css/community.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Search from './search';

const Subscribers = ({
    community
    })=>{
  const [subscriptions, setSubscriptions] = useState([]);
  const [filteredSubscriptions,setFilteredSubscriptions] = useState([]);
  const toggleOpenSubscription = (index) => {
    setSubscriptions((subs) => {
      subs[index]['opened'] = !subs[index]['opened'];
      return [...subs];
    });
  };

  useEffect(() => {
    if (community.subscriptions) {
      let tmp = community.subscriptions
          .filter((x) => x.paymentPlatform != 'powerchat-test')
          .map((x) => {
            return { ...x, opened: false, visible: true };
          })
      setFilteredSubscriptions(tmp);
      setSubscriptions(tmp);
    }
  },[community]);  

  const searchFilterCallback = ({ username }, searchInput) =>
    username.toLowerCase().includes(searchInput);
  return (
        <div className="subscriptions">
          <h3>Total Subscribers: {filteredSubscriptions.length}</h3>
          <Search
            inputPlaceholder="Filter by username"
            initialData={subscriptions}
            setData={setFilteredSubscriptions}
            filterCallback={searchFilterCallback}
          />
          <div className="subscriptions-mobile">
            {filteredSubscriptions
              .filter((x) => x.visible)
              .map(({ username, streak, expires_at }, index) => (
                <div key={index} className="subscription-container">
                  <div
                    className="dropdown-toggle"
                    onClick={() => toggleOpenSubscription(index)}
                  >
                    <div className="toggle-button">
                      {filteredSubscriptions[index].opened ? (
                        <FontAwesomeIcon icon={faChevronUp} size="lg" />
                      ) : (
                        <FontAwesomeIcon icon={faChevronDown} size="lg" />
                      )}
                    </div>
                    <div className="title">{username}</div>
                  </div>
                  {filteredSubscriptions[index].opened && (
                    <div className="subscription-info">
                      <div>Username: {username}</div>
                      <div>Streak: {streak}</div>
                      <div>Expires on {expires_at}</div>
                    </div>
                  )}
                </div>
              ))}
          </div>
          <div className="subscriptions-table">
            <table>
              {filteredSubscriptions.length > 0 && (
                <thead>
                  <tr>
                    <th>
                      <span>Username</span>
                    </th>
                    <th>
                      <span>Streak</span>
                    </th>
                    <th>
                      <span>Expires on</span>
                    </th>
                  </tr>
                </thead>
              )}
              <tbody>
                {filteredSubscriptions
                  .filter((x) => x.visible)
                  .map(({ username, streak, expires_at }, index) => (
                    <tr key={index}>
                      <td>{username}</td>
                      <td>{streak}</td>
                      <td>{expires_at}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      );
}
export default Subscribers;
