import React from 'react';
import HeroSection from '../components/hero-section';
import ContentBoxes from '../components/content-boxes';
import Description from '../components/description';

const pricingContentBoxes = [
  {
    header: 'Go Live. Get Paid',
    content: <p>Earn money for your content and get paid immediately with Powerchat.Live. Powerchat destroys the competition with its competitive rates by only charging 7.1%* of transactions received through our payment gateway partners for Powerchats and 25%* for Subscribers. See the Terms of Service for more information.</p>,
    imgUrl: '/static/img/pricing-page-assets/PC_Pricing-02.webp',
  },
  {
    header: 'Secure and Trusted',
    content: <p>Powerchat partners with world class payment gateways such as Square for security, convenience, and peace of mind. Get paid and run your streaming business with confidence.</p>,
    imgUrl: '/static/img/pricing-page-assets/PC_Pricing-03.webp',
  },
  {
    header: 'Earn Crypto',
    content: <p>Welcome to the next generation of stream monetization. Powerchat.Live&apos;s unique system allows streamers to receive Text-To-Speech (TTS) and Media Powerchats with crypto support for major coins such as Bitcoin, Litecoin, Monero, Doge, and over 150 additional coins available for creators to get paid in the 21st century.</p>,
    imgUrl: '/static/img/pricing-page-assets/PC_Pricing-04.webp',
  },
];

const Pricing = () => {
  return (
    <div className="page-container content-box-page-container">
      <HeroSection imgUrl="/static/img/pricing-page-assets/PC_Pricing-01.webp">
        <p>Earn more for your content</p>
        <br />
        <p>
          with<b>Powerchat</b>
        </p>
      </HeroSection>
      <Description>
        <p className="description-header">
          Free to use.<b>For real</b>
        </p>
        <p className="description-text">
          Powerchat.Live offers all of its features with no upfront cost. Access
          stream platform integrations, chat overlays, stream event alerts such
          as follows, subscribers, superchats, and more -- absolutely free, no
          subscription required.
        </p>
      </Description>
      <ContentBoxes contentBoxes={pricingContentBoxes} />
    </div>
  );
};

export default Pricing;
