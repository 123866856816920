import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import '../css/qr-overlay.css';
import QRCode from 'qrcode-with-logos';

const QrOverlay = ({ id = 'qr-overlay', username = null }) => {
  if (!username) username = useParams().username;

  useEffect(() => {
    new QRCode({
      content: `${process.env.BASE_URL}/${username}`,
      width: 240,
      download: false,
      image: document.getElementById(username + '-qr'),
      logo: {
        src: '/static/img/powerchat-logo.png',
        bgColor: "#000000",
      },
      nodeQrCodeOptions: {
        color: {
          dark: '#ffffff',
          light: '#000000',
        }
      }
    });
  }, []);

  return (
    <div id={id} className="overlay-container qr-overlay">
      <div className="flex-column">
        <img id={username + '-qr'} className="code" />
        <img className="logo" src='/static/img/powerchat-full-text-logo.png' />
      </div>
    </div>
  );
};

export default QrOverlay;
