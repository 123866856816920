import React from 'react';

const TiltedBackProgressBar = ({
  goalName,
  value,
  max,
  text,
  fontSize = 16,
  primaryColor = '#375a7f',
  secondaryColor = '#44c767',
  tertiaryColor = 'transparent',
  backgroundColor = 'transparent',
  textColor = '#fff',
  uid = '',
  length = '100%',
  heightRatio = 2,
  tiltAngle = 20, // Angle for slanting to the right
}) => {
  const percentage = Math.min((value / max) * 100, 100);
  const actualFontSize = fontSize / 16;
  const heightInEm = `${actualFontSize * heightRatio}rem`;
  const halfHeightInEm = `${actualFontSize * heightRatio / 2}rem`;

  return (
    <div className={`slanted-bar-container${uid}`} style={{
      width: `calc(${length} - 30px)`, display: 'block', position: 'relative',
      backgroundColor,
      padding: '15px',
      paddingTop: 'calc(15px - 0.5rem)'
    }}>
      <style>
      {`
          .slanted-bar-name${uid} {
            color: ${textColor};
            font-size: ${actualFontSize}rem;
            font-weight: bold;
            line-height: ${heightInEm};
            white-space: nowrap;
            text-overflow: ellipsis;
            text-align: left;
            margin-bottom: 5px;
          }
          .slanted-bar${uid} {
            height: ${halfHeightInEm};
            width: 100%;
            background-color: transparent;
            border: ${tertiaryColor === 'transparent' ? 'none' : `0.15rem solid ${tertiaryColor}`};
            position: relative;
            top: 0;
            left: 0;
            overflow: hidden;
            box-sizing: border-box;
            z-index: 1;
            transform: skewX(${tiltAngle}deg);
          }
          .slanted-fill${uid} {
            height: 100%;
            width: ${percentage}% !important;
            background-color: ${secondaryColor};
            position: absolute;
            top: 0;
            left: 0;
            z-index: 2;
          }
          .slanted-background${uid} {
            height: 100%;
            width: 100% !important;
            background-color: ${primaryColor};
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
          }
          .slanted-text${uid} {
            height: 100%;
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            color: ${textColor};
            font-size: ${actualFontSize}rem;
            font-weight: bold;
            line-height: ${heightInEm};
            text-align: center;
            z-index: 3;
          }
        `}
      </style>
      <div style={{ position: 'relative', height: heightInEm }}>
        {goalName && <div className={`slanted-bar-name${uid}`}>{goalName}</div>}
        <div className={`slanted-text${uid}`}>{text}</div>
      </div>
      <div className={`slanted-bar${uid}`}>
        <div className={`slanted-fill${uid}`} />
        <div className={`slanted-background${uid}`} />
      </div>
    </div>
  );
};

export default TiltedBackProgressBar;
