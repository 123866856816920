import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import LiveChat from '../components/live-chat';

const ChatOverlay = () => {
  const { username } = useParams();
  const [robotstreamer, setRobotstreamer] = useState('');

  useEffect(() => {
    const loadChatOverlay = async () => {
      try {
        const { data } = await axios.get(`/pubapi/chat-overlay/${username}`);
        let robotstreamerId = data.robotstreamer_id;
        setRobotstreamer(robotstreamerId);
      } catch (err) {
        console.error(err);
      }
    };
    loadChatOverlay();
  }, []);

  return (
    <LiveChat
      robotstreamer={robotstreamer}
    />
  );
};

export default ChatOverlay;
