import React from 'react';
import '../css/slider.css';

const Slider = ({ name, label, unit, value, disabled, handleChange, list,
  step = 1, min = 0, max = 100, width }) => {
  const sliderStyle = width ? { width } : {};
  const inputStyle = width ? { width: '100%' } : {};
  return (
    <div className="slider-container" style={sliderStyle}>
      <div>
        <h4>{label}:</h4>
        <div className="value-container">
          <p>{value}{unit}</p>
        </div>
      </div>
      <div>
        <input
          type="range"
          name={name}
          min={min}
          max={max}
          step={step}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          list={list}
          style={inputStyle}
        />
      </div>
    </div>
  );
};

export default Slider;
