import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import Routes from './routes';
import './css/app.css';
import { AuthProvider } from './hooks/useAuth';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const App = () => {
  const location = useLocation();

  useEffect(() => {
    if (window.scrollY !== 0) {
      window.scrollTo(0, 0);
    }
  }, [location]);

  return (
    <div className="app-container">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <AuthProvider>
          <Routes />
        </AuthProvider>
      </LocalizationProvider>
    </div>
  );
};

export default App;
