import React from 'react';
import axios from 'axios';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Tooltip from '@mui/material/Tooltip';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../static/theme';
import sbSearchTheme from '../static/sbSearchTheme';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Badge from '@mui/material/Badge';
import AudiotrackIcon from '@mui/icons-material/Audiotrack';
import MusicVideoIcon from '@mui/icons-material/MusicVideo';

function SoundboardItem(props) {
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);

  const sendSound = async () => {
    try {
      console.log('Sending sound:', props.sound.id, props.user.username);
      await axios.post(`/api/pushSound/${props.sound.id}`, {
        username: props.user.username,
        volume: props.selectedSoundboardVolume,
        videoEnabled: props.videoEnabled,
        queueEnabled: props.queueEnabled
      });
      props.onSoundSent(`${props.sound.name} sent!`);
    } catch (error) {
      console.error('Error sending sound:', error);
      props.onSoundSent('Error sending sound', 'error');
    }
  };

  const mediaComponent = props.sound.video ? (
    <div style={{ position: 'relative' }}>
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        badgeContent={<MusicVideoIcon style={{ color: '#F8F8FF', fontSize: '1rem' }} />}
        color="primary"
        sx={{
          '.MuiBadge-badge': {
            backgroundColor: 'var(--lighter-blue)',
            color: '#F8F8FF',
            borderBottomLeftRadius: 'var(--border-radius)',
            borderTopRightRadius: 'var(--border-radius)',
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 0,
            bottom: '10px',
            left: '13px',
          },
        }}
      >
        <CardMedia
          component="video"
          src={props.sound.video}
          alt={props.sound.name}
          onLoadedMetadata={(e) => {
            e.target.currentTime = 0;
          }}
        />
      </Badge>
      <PlayCircleIcon style={{ position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '3rem', opacity: '0.5' }} />
    </div>
  ) : (
    <div style={{ position: 'relative' }}>
      <Badge
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        badgeContent={<AudiotrackIcon style={{ color: '#F8F8FF', fontSize: '1rem' }} />}
        color="primary"
        sx={{
          '.MuiBadge-badge': {
            backgroundColor: 'var(--lighter-blue)',
            color: '#F8F8FF',
            borderBottomLeftRadius: 'var(--border-radius)',
            borderTopRightRadius: 'var(--border-radius)',
            borderTopLeftRadius: 0,
            borderBottomRightRadius: 0,
            bottom: '10px',
            left: '13px',
          },
        }}
      >
        <CardMedia
          component="img"
          image={props.sound.thumbnailUrl || "/static/img/powerchat-logo.webp"}
          alt={props.sound.name}
          draggable="false"
        />
      </Badge>
      <PlayCircleIcon style={{ position: 'absolute', top: '60%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '3rem', opacity: '0.5' }} />
    </div>
  );

  const handleDelete = () => {
    setConfirmDeleteOpen(true);
  };

  const confirmDelete = async () => {
    try {
      console.log('Deleting sound from soundboard:', props.soundboardDetails.id, 'sound:', props.sound.id);
      await axios.patch(`/api/soundboards/${props.soundboardDetails.id}/sounds`, {
        soundboardSounds: [{ id: props.sound.id, delete: true }],
      });
      props.onSoundSent('Sound deleted successfully');
      props.refreshSounds();
      setConfirmDeleteOpen(false);
    } catch (error) {
      console.error('Error deleting sound from soundboard:', error);
      props.onSoundSent('Error deleting sound', 'error');
      setConfirmDeleteOpen(false);
    }
  };

  return (
    <ThemeProvider theme={theme}>
    <div
      onClick={sendSound}
      style={{ cursor: 'pointer', position: 'relative' }}
      draggable
      onDragStart={(e) => e.dataTransfer.setData('text/plain', props.sound.id)}
    >
      <Tooltip title={`${props.sound.name} (${props.sound.video ? 'Video' : 'Audio'})`} placement="bottom" arrow>
        <Card sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', backgroundColor: 'transparent' }}>
          <IconButton
            title="Delete sound"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete();
            }}
            sx={{ position: 'absolute', top: '-7px', right: '-7px', color: 'var(--light-blue)' }}
          >
            <CloseIcon />
          </IconButton>
          <DragIndicatorIcon sx={{ position: 'absolute', top: '1px', left: '1px', color: 'var(--light-blue)' }} />
          <div>
            {mediaComponent}
          </div>
          <CardContent sx={{ textAlign: 'center', backgroundColor: 'transparent' }}>
            <Typography variant="h6" component="div" style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '115px' }}>
              {props.sound.name}
            </Typography>
          </CardContent>
        </Card>
      </Tooltip>
    </div>
    <Dialog
      open={confirmDeleteOpen}
      onClose={() => setConfirmDeleteOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Confirm Delete"}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
        Are you sure you want to delete this sound from your soundboard?
        </DialogContentText>
      </DialogContent>
      <ThemeProvider theme={sbSearchTheme}>
      <DialogActions>
        <Button onClick={confirmDelete} variant="contained" color="primary">
          Confirm
        </Button>
        <Button 
          onClick={() => setConfirmDeleteOpen(false)} 
          variant="contained" 
          sx={{
            color: 'white',
            backgroundColor: 'var(--light-grey)',
            '&:hover': {
              backgroundColor: 'var(--red)',
            }
          }}
        >
          Cancel
        </Button>
      </DialogActions>
      </ThemeProvider>
    </Dialog>
    </ThemeProvider>
  );
}

export default SoundboardItem;
