import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import '../css/live-chat.css';
import '../css/fonts.css';
import axios from 'axios';
import Sockette from 'sockette';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWrench } from '@fortawesome/free-solid-svg-icons';
import Avatar from 'react-avatar';
import { TransitionGroup, CSSTransition } from 'react-transition-group';
import { filterProfanity } from '../../server/api/textFilters';
import parse from 'html-react-parser';
import Speech from 'speak-tts';
const speech = new Speech();

const LiveChat = () => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const maxChats = 50;
  const pingTime = 5 * 60 * 1000; //5 minutes
  const updateTime = 1 * 60 * 1000; // 1 minute
  const colors = ['orange', 'white', 'lightyellow', 'lightblue', 'lightgreen', 'lightpink', 'lightgray', 'tomato', 'gold', 'palegreen', 'lightseagreen', 'mediumaquamarine', 'aquamarine', 'lightskyblue', 'mediumspringgreen', 'plum', 'lightsalmon', 'lightslategray', 'mediumorchid', 'mediumslateblue', 'lightcoral', 'khaki', 'palevioletred', 'palegoldenrod', 'mediumturquoise', 'mintcream', 'wheat', 'lavender', 'mistyrose', 'peachpuff'];
  const lastPickedColors = useRef({});
  const defaultMessageColor = 'white'; // Define a default message color
  const defaultChatterColor = ''; // Define a default chatter color, leave blank to allow css to handle it
  
  const { username } = useParams();
  const [chats, setChats] = useState([]);
  const settingsLoaded = useRef(false);

  const speechSupported = speech.hasBrowserSupport();

  const useDefaultChatFilter = useRef(true);
  const profanity = useRef([]);
  
  const [chatSetting, setChatSettings] = useState({});
  const chatSettingRef = useRef({});  

  const latestMessageTimestampRef = useRef(null);
  const [expirationQueue, setExpirationQueue] = useState([]);
  const [isAnimating, setIsAnimating] = useState(false);

  const containerRef = useRef(null);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  useEffect(() => {
    const initializeSettings = async () => {
      if (window.location.href.includes("#")) {
        // Create a new URL that replaces all instances of # with its encoded value (%23)
        let newURL = window.location.href.split("#").join("%23");
        window.location.replace(newURL);
      }

      await getChatSettings(username);
      const refreshSettings = setInterval(() => {
        getChatSettings(username);
      }, updateTime);

      let { cozyUser, youtubeUser, rumbleUser } = chatSettingRef.current;
      if (cozyUser === true || youtubeUser === true || rumbleUser === true) {
        chatPing({ cozyUser, youtubeUser, rumbleUser });
        setInterval(() => chatPing({ cozyUser, youtubeUser, rumbleUser }), pingTime);
      }

      useDefaultChatFilter.current = chatSettingRef.current.useDefaultChatFilter;
      if (chatSettingRef.current.useDefaultChatFilter) {
        profanity.current = chatSettingRef.current.profanity;
      }

      startWebsockets();

      return () => clearInterval(refreshSettings);  // Cleanup the interval on component unmount
    };
    initializeSettings();
  }, []);

  const getChatSettings = async (username) => {
    const settings = await axios.get('/pubapi/chat-settings', { params: { username } });

    // loop through url flags to set chat settings
    for (let [key, value] of params) {
      // if the key is not a valid chatSetting.key skip it.
      if (!Object.keys(settings.data.chatSetting).includes(key)) {
        console.warn(`${key} is not a valid chat setting. It will be ignored.`);
        continue;
      } else {
        // if the key is a valid chatSetting.key set the value
        switch(true){
          case value === 'true':
            value = true;
            break;

          case value === 'false':
            value = false;
            break;

          case isNaN(value):
            break;
          
          default:
            value = Number(value);
        }
        settings.data.chatSetting[key] = value;
      }
    }

    // Only update the settings if they change. This prevents an infinite loop.
    if (JSON.stringify(settings.data.chatSetting) !== JSON.stringify(chatSettingRef.current)) {
      console.info('Custom Chat Settings:', settings.data.chatSetting);
      setChatSettings(settings.data.chatSetting);
      chatSettingRef.current = settings.data.chatSetting;
    }

    if (!settingsLoaded.current && !isNaN(chatSettingRef.current.ttsVolume)) {
      settingsLoaded.current = true;
      initializeTTS();
    }
  };

  const initializeTTS = () => {
    let volume = chatSettingRef.current.ttsVolume / 100;
    if (volume < 0.1 || isNaN(volume)) {
      volume = 0.01; //volume = 0 does not work
    }
    speech.init({
      volume
    }).catch(err => {
      console.error('Error occured in speech initialization', err);
    });
 };

  useEffect(() => {
    if (chatSetting.ttl < 1) {
      return;  // Exit early if TTL is < 1, no need to set up the interval
    }

    const checkExpiry = () => {
      const now = Date.now();
    
      const newChats = chats.map(c => {
        // If ttl is < 1, then the chat never expires. Skip the expiration logic.
        if (chatSetting.ttl < 1) {
          return c;
        }
          
        const expiryTime = c.timestamp + (chatSetting.ttl * 1000);
        if (now > expiryTime - 1000 && !c.expiring) {
          return { ...c, expiring: true };
        }
        return c;
      }).filter(c => {
        if (chatSetting.ttl === 0) {
          return true;  // Always keep the chat if ttl is 0
        }
          
        const expiryTime = c.timestamp + (chatSetting.ttl * 1000);
        if (now <= expiryTime) return true;
    
        if (!c.expiring) { // Only add to the queue if not already expiring
          setExpirationQueue(prevQueue => [...prevQueue, c]);
        }
        return false;
      });
    
      setChats(newChats);
    };
  
    const intervalId = setInterval(checkExpiry, 100); // Check every 100ms

    return () => clearInterval(intervalId);
  }, [chats, chatSetting.ttl]);
  
  useEffect(() => {
    if (!expirationQueue.length || isAnimating) {
      return;
    }
  
    const chatToAnimate = expirationQueue[0];
  
    animateChatExpiration(chatToAnimate).then(() => {
      setExpirationQueue(prevQueue => prevQueue.slice(1));
    });
  }, [expirationQueue, isAnimating]);

  const animateChatExpiration = (chat) => {
    return new Promise((resolve) => {
      setIsAnimating(true);
      setTimeout(() => {
        setChats(prevChats => prevChats.filter(c => c.timestamp !== chat.timestamp));
        setIsAnimating(false);
        resolve();
      }, 1000);
    });
  };

  const chatPing = async ({ cozyUser, youtubeUser, rumbleUser }) => {
    await axios.post('/pubapi/chat-ping', { username, cozyUser, youtubeUser, rumbleUser });
  };
  
  const pickRandomColor = (type) => {
    let excludeColor = lastPickedColors.current[type];
    let availableColors = colors;
    if (excludeColor) {
      availableColors = colors.filter(color => color !== excludeColor);
    }
    const pickedColor = availableColors[Math.floor(Math.random() * availableColors.length)];
    lastPickedColors.current[type] = pickedColor;  // Update the last picked color for this type
    return pickedColor;
  };

  const addChat = (chat) => {
    chat.timestamp = new Date().getTime();
    latestMessageTimestampRef.current = chat.timestamp;
    chat.ttl = chatSettingRef.current.ttl ? chatSettingRef.current.ttl : 0;
    chat.expirationAnimation = chatSettingRef.current.expirationAnimation;
    chat.expiring = false;
    chat.incomingAnimation = chatSettingRef.current.incomingAnimation;

    setChats((prevChats) => {
      const newChats = [...prevChats, chat];
      // Ensure the length doesn't exceed maxChats
      newChats.splice(0, newChats.length - maxChats + 1);
      return newChats;
    });

    scrollToBottom(true, `addChat - ${chat.text}`);
  };

  const displayReceivedMessage = async (data) => {
    if (!data || !data.message || (!data.message[0])) {
      console.info(`data`, data);
      console.info(`data.message`, data.message);
      console.info(`data.message[0]`, data.message[0]);
      console.info(`data.source`, data.source);
      return;
    }

    let wordwrapMessage = data.message;
    
    data.emote = false;
    data.sticker = false;

    if (data.message.some(element => element.includes('chat_emote'))) {
      data.emote = true;
    }

    if (useDefaultChatFilter.current) {
      wordwrapMessage = filterProfanity(wordwrapMessage.join(' '), profanity.current).split(' ');
    }

    let messageColor = defaultMessageColor;
    let chatterColor = defaultChatterColor;

    if (Object.keys(chatSettingRef.current).length !== 0) {
      if(chatSettingRef.current.messageColorRandom) {
        messageColor = pickRandomColor('message');
      } else if(chatSettingRef.current.messageColor) {
        messageColor = chatSettingRef.current.messageColor;
      }

      switch(true) {
        case chatSettingRef.current.chatterColorDefault:
            chatterColor = defaultChatterColor;
            break;
    
        case chatSettingRef.current.chatterColorRandom:
            chatterColor = pickRandomColor('chatter');
            break;
    
        case chatSettingRef.current.chatterColor && chatSettingRef.current.chatterColor !== "":
            chatterColor = chatSettingRef.current.chatterColor;
            break;
    
        default:
            chatterColor = defaultChatterColor;
      }
    }

    addChat({
      nick: data.username,
      text: wordwrapMessage,
      emote: data.emote,
      sticker: data.sticker,
      banned: false,
      mod: data.mod,
      source: data.source,
      avatar: data.avatar,
      chatterColor: chatterColor,
      badges: data.badges,
      color: messageColor,
    });

    if (speechSupported) {
      let ttsMessage = wordwrapMessage
        .filter(x => typeof(x) == 'string')
        .map(x => {
          let parsed = parse.default(x.replace(/&nbsp;/g, ' '));
          if (typeof(parsed) == 'object' && !!parsed.props) {
            return parsed.props.name;
          }
          return x;
        })
        .join(' ');

      if (chatSettingRef.current.ttsChatsMode === 2) {
        speakTTS(ttsMessage);
      } else if (chatSettingRef.current.ttsChatsMode === 1 && ttsMessage.startsWith('!tts')) {
        speakTTS(ttsMessage.slice(4).trim());
      }
    }    
  };

  const speakTTS = (text) => {
    speech.speak({text}).catch(err => {
      console.error('Speech error', err);
    });
  };

  const emoteMessageParser = (emoteMessage, index, chat) => {
    try {
      let ret = null;

      // Handle cases where emoteMessage is not a string
      if (Array.isArray(emoteMessage)) {
          // If it's an array, join the elements to form a string
          emoteMessage = emoteMessage.join(" ");
      }
      
      // Now, check if emoteMessage is a string before trying to replace
      if (typeof emoteMessage === 'string') {
          emoteMessage = emoteMessage.replace(/&nbsp;/g, " ");
      } else {
          // If emoteMessage is still not a string, return null or handle accordingly
          console.info("emoteMessage is not a string:", emoteMessage)
          return null;
      }
        
      if (emoteMessage.includes('<div class="chat_emote"')) {
          if(chatSetting.noEmotes) return null;
          
          // Extract background-image URL, alt, and title attributes
          const urlMatch = emoteMessage.match(/background-image: url\('([^']+)'\)/);
          const altMatch = emoteMessage.match(/name="([^"]*)"/);
          const titleMatch = emoteMessage.match(/name="([^"]*)"/);
          
          const imageUrl = urlMatch ? urlMatch[1] : "";
          let altText = altMatch ? altMatch[1] : "";
          let titleText = titleMatch ? titleMatch[1] : "";

          // Construct img tag with the extracted attributes
          const imgTag = `<img src="${imageUrl}" name="${altText}" alt="${altText}" title="${titleText}" style="max-height: ${chatSettingRef.current.emoteSize}px; width: auto;" />`;
          
          // Replace the entire div with a new div without background-image style, and inject img tag into the div.
          emoteMessage = `<div class="chat_emote" style="">${imgTag}</div>`;

          const rawHtml = { __html: emoteMessage };
          ret = <div className="chat-word" key={index} dangerouslySetInnerHTML={rawHtml} />;
      } else {
          if (!chatSetting.emotesOnly) {
              ret = (
                <div className="chat-word" style={{ color: chat.color }} key={index}>
                  {emoteMessage}
                </div>
              );
          }
      }
      return ret;
    } catch (err) {
        console.error(err);
        return null;  // Ensure we return null in case of an error
    }
  };

  const receiveMessage = async (data) => {
    await displayReceivedMessage(data);
  };

  const startWebsockets = () => {
    // we always use a secure connection to test.
    const protocol = 'wss';
    let socket = new Sockette(
      `${protocol}://${baseUrl}/${username.toLowerCase()}_chat`,
      {
        onopen: function (event) {
          event.target.send(
            `Remote client log - ${username} - Open TTS - ${username}`
          );
        },

        onmessage: function (event) {
          event.target.send(`Remote client log - ${username} - [message] Data received from server: ${event.data}`);
          if (event.data == 'pong') {
            return;
          }
          const data = JSON.parse(event.data);
          receiveMessage(data);
          //scrollToBottom(false, "onmessage");
        },

        onclose: function (event) {
          if (event.wasClean) {
            console.info(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
          } else {
            console.info('[close] Connection died');
          }
        },

        onerror: function (error) {
          console.error(`[error] ${error.message}`);
        },
      }
    );

    setInterval(() => {
      socket.send(`ping from: ${username} - TTS Overlay`);
    }, 30 * 1000);
  };

  const scrollToBottom = () => {
    if (containerRef.current) {
        const container = containerRef.current;
        container.children[0].scrollTop = container.children[0].scrollHeight;
    }
  };

  function toTitleCase(str) {
    if (typeof str !== 'string') return '';
    let titleCase = str.replace(/([A-Z])/g, ' $1') // Insert space before capital letters
                    .replace(/^./, function(ch) { return ch.toUpperCase(); }) // Capitalize the first letter
                    .replace(/\s+/g, '') // Replace sequences of spaces with a single space
                    .trim(); // Trim any leading spaces
    return titleCase;
  }

  return (
    <div ref={containerRef} className="livechat" style={{ backgroundColor: chatSetting.backgroundColor }}>
      <TransitionGroup className={`chat-container orientation${toTitleCase(chatSettingRef.current.orientation)}`}>
      {(chatSettingRef.current && chatSettingRef.current.orientation && chatSettingRef.current.orientation.toLowerCase().includes("bottom") ? [...chats].reverse() : chats).map((chat, idx) => {
          if(chat.banned || (chatSetting.emotesOnly && !chat.emote && !chat.sticker)) {
            const newChats = [...chats];
            newChats.splice(idx, 1);
            setChats(newChats);
            return null;
          }

          const chatKey = `chat-${chat.timestamp}`;          

          const animationClasses = chat.expiring
            ? { enter: '', exit: `expiring-${chat.expiringAnimation}` }
            : { enter: `incoming-${chat.incomingAnimation}`, exit: '' };
          
          const getExpirationAnimation = (chat) => {
            if (!chat.expiring) return '';
            const animationClass = `expiring-${chat.expirationAnimation}`;
            return animationClass;
          };

          const getIncomingAnimation = (chat) => {
            if (chat.timestamp === latestMessageTimestampRef.current && chat.incomingAnimation) {
              return `incoming-${chat.incomingAnimation + chat.incomingAnimation.slice(1)}`;
            }
            return '';
          };

          const parsedMessages = chat.emote 
            ? chat.text.map((element, index) => emoteMessageParser(element, index, chat)).filter(Boolean)
            : chat.text.map((char, index) => <div key={'chatword' + index} className="chat-word" style={{ color: chat.color }}>{char}</div>);
          
          if(parsedMessages.length === 0) {
            return null;
          }

          const chatNickname = () => {
            switch(chatSettingRef.current.theme) {
              // Themes with no : after name
              case 'bubble':
                // if chat.nick ends in : remove it
                return chat.nick.endsWith(':') ? chat.nick.slice(0, -1) : chat.nick;

              case 'console': {
                // add ~$ to the end of chat.nick
                let tempNick = chat.nick.endsWith(':') ? chat.nick.slice(0, -1) : chat.nick;
                tempNick = tempNick + '@' + chat.source + ':~$';
                return tempNick;
              }

              default:
                return chat.nick;
            }
          }

          return (
            <CSSTransition
              key={chatKey}
              timeout={1000}
              classNames={animationClasses}
              onEntered={() => {
                scrollToBottom(false, "CSSTransition onEntered");
              }}
            >
              <div key={chatKey} className={`${getIncomingAnimation(chat, chats)} ${getExpirationAnimation(chat)} ${chatSettingRef.current.font ? chatSettingRef.current.font : 'default'} ${chatSettingRef.current.theme ? `theme${toTitleCase(chatSettingRef.current.theme)}` : 'themeClassic'} ${chatSettingRef.current.orientation ? `orientation${toTitleCase(chatSettingRef.current.orientation)}` : 'orientationTopLeft'} chatMessage`} style={{fontSize:`${chatSettingRef.current.fontSize}px`, backgroundColor: (chatSettingRef.current.theme == 'console' ? chatSettingRef.current.backgroundMessageColor : 'transparent') }}>
                <div className={`title ${chatSettingRef.current.orientation.toLowerCase().includes("right") ? 'rightAlign' : ''}`}  style={{ backgroundColor: chatSettingRef.current.backgroundTitleColor }}>
                  {!chatSettingRef.current.noAvatars && (
                      <div className="avatar">
                          <Avatar size={`${chatSettingRef.current.fontSize}px`} round={`${chatSettingRef.current.fontSize}px`} color={Avatar.getRandomColor(chat.nick)} src={chat.avatar} style={{paddingRight:'0.25rem'}} />
                      </div>
                  )}
                  {!chatSettingRef.current.noBadges && (
                      <div className="badges">
                          {chat.badges && chat.badges.map((badge, index) => <img key={index} height={`${chatSettingRef.current.fontSize}px`} width={`${chatSettingRef.current.fontSize}px`} className="badge" src={badge} />)}
                      </div>
                  )}
                  {!chatSettingRef.current.noUsernames && (
                      <div className="name">
                          {chat.mod && <FontAwesomeIcon className="chat-icon" icon={faWrench} />}
                          <span className={
                            'chat-nick' + 
                            (chat.source == 'kick' ? ' kickNick' : '') + 
                            (chat.source == 'twitch' ? ' twitchNick' : '') + 
                            (chat.source == 'rs' ? ' rsNick' : '') + 
                            (chat.source == 'cozy' ? 
                                (chat.badges && chat.badges.some(badge => badge.includes('vf.svg')) ? ' cozyVerified' : 
                                chat.badges && chat.badges.some(badge => badge.includes('mod.svg')) ? ' cozyMod' : ' cozyNick') 
                            : '') + 
                            (chat.source == 'trovo' ? ' trovoNick' : '') + 
                            (chat.source == 'rumble' ? ' rumbleNick' : '') + 
                            (chat.source == 'dlive' ? ' dliveNick' : '')
                        } style={{ color: (chat.chatterColor ? ` ${chat.chatterColor}` : '')}}>
                            {(chat.banned ? '(Banned) ' : '') + chatNickname()}
                        </span>
                      </div>
                  )}
                  </div>
                  <div className={`message ${chatSettingRef.current.orientation.toLowerCase().includes("right") ? 'rightAlign' : ''}`} style={{ backgroundColor: (chatSettingRef.current.theme == 'console' ? 'transparent' : chatSettingRef.current.backgroundMessageColor) }}>
                    {parsedMessages}
                  </div>
              </div>
            </CSSTransition>
          );
        }).filter(Boolean)}
      </TransitionGroup>
    </div>
  );
};

export default LiveChat;
