import axios from 'axios';

export const login = async (loginData) => {
  const { data } = await axios.post('/login', loginData);
  return data;
};

export const logout = async () => {
  const { data } = await axios.get('/logout');
  return data;
};
