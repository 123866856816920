import React, { useState, useEffect } from 'react';
import Select, { components } from 'react-select';

const FontSelector = ({ name, fonts, onFontChange, styles = {}, value, disabled = false }) => {
    const [selectedFont, setSelectedFont] = useState(value || fonts[0]);

    useEffect(() => {
        setSelectedFont(value);
    }, [value]);

    const handleChange = selectedOption => {
        setSelectedFont(selectedOption);
        if (onFontChange) {
            onFontChange(selectedOption, { name: name });
        }
    };

    const options = fonts.map(font => ({
        value: font.value,
        label: font.label,
    }));

    const customStyles = {
        control: (provided) => ({
            ...provided,
            ...styles.control(provided),
            marginTop: '10px',
            marginBottom: '8px',
        }),
        option: (provided, state) => ({
            ...provided,
            color: state.isSelected ? 'white' : 'black',
            backgroundColor: state.isSelected 
            ? '#2684FF' 
            : state.isFocused 
                ? '#DEEBFF'
                : 'white',
            ...styles.option(provided, state),
        }),
        input: (provided) => ({
            ...provided,
            ...styles.input(provided),
        }),
    };

    const CustomOption = props => (
        <components.Option {...props} className={props.data.value}>
            {props.label}
        </components.Option>
    );

    const CustomSingleValue = props => (
        <components.SingleValue {...props} className={props.data.value}>
            {props.data.label}
        </components.SingleValue>
    );

    return (
        <Select
            value={selectedFont}
            onChange={handleChange}
            options={options}
            styles={customStyles}
            components={{
                Option: CustomOption,
                SingleValue: CustomSingleValue,
            }}
            isDisabled={disabled}
        />
    );
};

export default FontSelector;
