import React, { useEffect, useState } from 'react';
import '../css/page-error.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

const PageError = ({ errorCode }) => {
  const [errorInfo, setErrorInfo] = useState(null);

  const determineError = (code) => {
    switch(code) {
      case 401:
        setErrorInfo({
          icon: faTriangleExclamation,
          title: 'Error (401)',
          text: "You must be logged in to view this page."
        });
        break;

      case 403:
        setErrorInfo({
          icon: faTriangleExclamation,
          title: 'Error (403)',
          text: "You don't have permission to view this page."
        });
        break;

      case 404:
        setErrorInfo({
          icon: faTriangleExclamation,
          title: 'Error (404)',
          text: "We can't find the page you're looking for."
        });
        break;

      case 500:
      default:
        setErrorInfo({
          icon: faTriangleExclamation,
          title: 'Error (500)',
          text: "An unexpected error occurred. Try refreshing the page in a few moments."
        });
        break;
    }
  };

  useEffect(() => {
    determineError(errorCode);
  }, [errorCode]);

  if (!errorInfo) {
    return <div>Loading...</div>;
  }

  return (
    <div className="page-container page-error-container">
      <div className="page-error-image"><FontAwesomeIcon icon={errorInfo.icon} /></div>
      <h1 className="page-error-title">{errorInfo.title}</h1>
      <p className="page-error-text">{errorInfo.text}</p>
    </div>
  );
};

export default PageError;
