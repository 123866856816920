import React, { useEffect, useState } from 'react';
import { getRandomNumber } from '../../../util/random-number.js';

const ExplosionEmote = (props) => {
  const { emote, maxSize, containerWidth, containerHeight, speed } = props;
  
  const [debrisArray, setDebrisArray] = useState([]);
  
  const generateDebris = () => {
    const debrisCount = getRandomNumber(5, 15);
    const newDebrisArray = Array.from({ length: debrisCount }, (_, index) => {
      const size = getRandomNumber(maxSize / 4, maxSize / 2);
      const middlePositionX = containerWidth / 2 - size / 2;
      const middlePositionY = containerHeight / 2 - size / 2;
      const randomDirectionX = (Math.random() - 0.5) * 2;
      const randomDirectionY = (Math.random() - 0.5) * 2;
      const animationSpeed = `${speed}s`;

      return (
        <div
          key={`debris_${index}`}
          className="emote-container explosion-debris"
          style={{
            maxWidth: `${size}px`,
            maxHeight: `${size}px`,
            left: `${middlePositionX}px`,
            top: `${middlePositionY}px`,
            animationDuration: animationSpeed,
            animationName: 'explodeAndFade',
            '--translateX': `${randomDirectionX * 400}px`,
            '--translateY': `${randomDirectionY * 400}px`
          }}
        >
          <img
            className="emote"
            src={emote}
            alt=""
            style={{
              maxWidth: `${size}px`,
              maxHeight: `${size}px`,
            }}
          />
        </div>
      );
    });

    setDebrisArray(newDebrisArray);
  };

  useEffect(() => {
    generateDebris();
  }, []);

  return <div>{debrisArray}</div>;
};

export default React.memo(ExplosionEmote);
