import React from 'react';
import '../css/about-section.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

const AboutSection = ({ description, links }) => {
  return (
    <div className="about-container">
      {description && description.trim() != '' && (
        <div className="description">
          <p>{description}</p>
        </div>
      )}
      {links && links.sort((a,b) => a.rank - b.rank).map(link => (
        <a key={link.name} href={link.value} rel="noreferrer" target="_blank">
          <div className="link-row">
            <img src={link.icon} />
            <div>
              {link.name}
            </div>
            <div>
              <FontAwesomeIcon icon={faUpRightFromSquare} size="1x" />
            </div>
          </div>
        </a>
      ))}
    </div>
  );
}

export default AboutSection;
