import React, { useState } from 'react';
import '../css/coin-selection.css';

const CoinSelection = ({ options, onCoinSelected, selectedCoin }) => {
  const [coinFilter, setCoinFilter] = useState('');
  const [coinOptions, setCoinOptions] = useState(options);

  const onCoinFilterChange = (event) => {
    let { value } = event.target;
    setCoinFilter(value);
    filterCoins(value);
  };

  const filterCoins = (filter) => {
    setCoinOptions(co => {
      for (let coin of co) {
        coin.visible = filter.length < 1 ||
          coin.code.toLowerCase().includes(filter.toLowerCase()) ||
          coin.name.toLowerCase().includes(filter.toLowerCase());
      }
      return co;
    });
  };

	return (
    <div className="coin-selection">
      <input autoFocus className="filter" type="text" placeholder="Type to filter.."
        value={coinFilter} onChange={onCoinFilterChange} />
      <div className="coin-row-container">
        {coinOptions.filter(coin => coinFilter.length < 1 || coin.visible !== false).map(coin =>
          <button key={coin.code} className={"coin-row " + (coin == selectedCoin ? 'selected' : '')}
            onClick={() => onCoinSelected(coin)}>
            <img src={process.env.NOWPAY_SITE_URL + coin.logoUrl} alt=""/>
            <span>{coin.name}<br/>${coin.code}</span>
            <div style={{width:25}}/>
          </button>
        )}
      </div>
    </div>
  );
};

export default CoinSelection;
