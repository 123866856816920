import React, { useState, useEffect, useRef, useMemo, Fragment } from 'react';
import axios from 'axios';
import '../css/events.css';
import ToggleSwitch from './toggle-switch';
import Pagination from './pagination';
import { DatePicker } from '@mui/x-date-pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRotateRight, faRepeat, faSearch } from '@fortawesome/free-solid-svg-icons';
import { format } from 'date-fns';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

let pageSize = 5;

const Events = ({ username, userId, isMod=false }) => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const [total, setTotal] = useState(0);
  const [filteredTotal, setFilteredTotal] = useState(0);
  const [events, setEvents] = useState([]);
  const [,setNewEvents] = useState([]); // newEvents state not being used
  const [autoRefresh, setAutoRefresh] = useState(true);
  const [refreshInterval, setRefreshInterval] = useState(null);
  const [manualRefreshing, setManualRefreshing] = useState(false);
  const [replayingIndex, setReplayingIndex] = useState(null);
  const [searchText, setSearchText] = useState('');
  const searchTextRef = useRef('');
  const [minDate, setMinDate] = useState(null);
  const minDateRef = useRef(null);
  const [maxDate, setMaxDate] = useState(null);
  const maxDateRef = useRef(null);

  const resend = async (id, index) => {
    setReplayingIndex(index);
    await axios.post(`/api/resend/${id}`);
    setTimeout(() => setReplayingIndex(null), 1000);
  };

  const getEvents = async () => {
    const { data } = await axios.get('/api/history', { params: { userId } });
    setTotal(data.total);
    setEvents(data.donations);
  };

  const dequeueNewEvents = () => {
    setNewEvents((newEvents) => {
      setTimeout(() => newEvents.forEach((x) => (x.isNewEvent = false)), 5000);
      setEvents((events) => [...newEvents, ...events]);
      return [];
    });
  };

  const startRefreshInterval = () => {
    setRefreshInterval(setInterval(dequeueNewEvents, 5000));
  };

  const stopRefreshInterval = () => {
    clearInterval(refreshInterval);
    setRefreshInterval(null);
  };

  const manualRefresh = () => {
    if (autoRefresh) return;
    setManualRefreshing(true);
    setTimeout(() => setManualRefreshing(false), 500);
    dequeueNewEvents();
  };

  useEffect(() => {
    getEvents();
  }, []);

  useEffect(() => {
    if (autoRefresh) {
      startRefreshInterval();
    } else {
      stopRefreshInterval();
    }
  }, [autoRefresh]);

  useEffect(() => {
    startWebsocket();
  }, []);

  const [currentPage, setCurrentPage] = useState(1);

  const currentPageEvents = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    setFilteredTotal(events.filter(x => x.hidden !== true).length);
    return events.filter(x => x.hidden !== true).slice(firstPageIndex, lastPageIndex);
  }, [currentPage, events]);

  const startWebsocket = () => {
    const protocol = 'wss';
    let socket = new ReconnectingWebSocket(
      `${protocol}://${baseUrl}/${username.toLowerCase()}_feed`
    );

    setInterval(() => {
      socket.send(`keep alive from: ${username} - Events Component`);
    }, 15 * 1000);

    socket.onopen = function () {
      console.log('[open] Connection established');
    };

    socket.onmessage = function (event) {
      if (event.data == 'pong') {
        return;
      }
      const dataObj = JSON.parse(event.data);
      addEvent(dataObj);
      socket.send(`rec_feed:${dataObj.id}`);
    };

    socket.onclose = function (event) {
      if (event.wasClean) {
        console.log(
          `[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
        );
      } else {
        console.log('[close] Connection died');
      }
    };

    socket.onerror = function (event) {
      console.log(`[error] ${event.message}`);
    };
  };

  const addEvent = (data) => {
    if (data.isReplay) {
      return;
    }
    setNewEvents((newEvents) => {
      let newEvent = {
        amount: data.amount,
        created_at: data.created_at,
        donator: data.donator,
        id: data.id,
        media: data.media,
        media_title: data.mediaTitle,
        message: data.message,
        payment_platform: data.paymentPlatform,
        isNewEvent: true,
      };
      newEvent.hidden = shouldFilter(newEvent);
      console.log(newEvent);
      return [
        newEvent,
        ...newEvents,
      ];
    });
  };

  const toggleAutoRefresh = () => {
    setAutoRefresh(!autoRefresh);
  };

  const timezoneName = new Intl.DateTimeFormat('en-us', {
    timeZoneName: 'short',
  })
    .formatToParts(new Date())
    .find((part) => part.type == 'timeZoneName').value;

  const searchTextChanged = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    searchTextRef.current = searchText;
  }, [searchText]);

  const filterSubmit = (event) => {
    event.preventDefault();
    setEvents((events) => {
      let newEvents = events.map(x => ({
        ...x,
        hidden: shouldFilter(x),
      }));
      return newEvents;
    });
    setCurrentPage(1);
  };

  const shouldFilter = (evt) => {
    let lowerSearch = searchTextRef.current.toLowerCase();
    return (lowerSearch != '' &&
      !evt.message.toLowerCase().includes(lowerSearch) &&
      !evt.donator.toLowerCase().includes(lowerSearch))
      || (!!minDateRef.current && minDateRef.current > new Date(evt.created_at))
      || (!!maxDateRef.current && maxDateRef.current < new Date(new Date(evt.created_at).toLocaleDateString()));
  };

  const clearFilter = () => {
    setSearchText('');
    setMinDate(null);
    setMaxDate(null);
  };

  useEffect(() => {
    maxDateRef.current = maxDate;
  }, [maxDate]);

  useEffect(() => {
    minDateRef.current = minDate;
  }, [minDate]);

  return (
    <div className="events-container">
      <div className="event-controls">
        <form className="event-filter-controls" onSubmit={filterSubmit}>
          <div className="flex-row">
            <input className="search" type="search" name="searchText" placeholder="Filter by message or username"
              onChange={searchTextChanged} value={searchText} />
            <div className="date-pick">
              <DatePicker
                format="MM/DD/YYYY"
                label="Start Date"
                value={minDate}
                maxDate={maxDate}
                sx={{
                  width: 200,
                }}
                slotProps={{
                  field: { clearable: true, onClear: () => setMinDate(null) }
                }}
                onChange={(date) => setMinDate(date)} /> 
            </div>
            <div className="date-pick">
              <DatePicker
                format="MM/DD/YYYY"
                label="End Date"
                value={maxDate}
                minDate={minDate}
                sx={{
                  width: 200,
                }}
                slotProps={{
                  field: { clearable: true, onClear: () => setMaxDate(null) }
                }}
                onChange={(date) => setMaxDate(date)} />
            </div>
          </div>
          <div className="form-container">
          <button className="primary-btn" onClick={filterSubmit}>
            Search&nbsp;
            <FontAwesomeIcon icon={faSearch} size="1x" color="#fff" />
          </button>
            <button className="secondary-btn" onClick={clearFilter}>
              Clear
            </button>
          </div>
        </form>
      {!isMod && (
        <div className="event-refresh-controls">
          <button
            onClick={manualRefresh}
            className={
              (manualRefreshing ? 'refreshing' : '') +
              (autoRefresh ? ' disabled' : '')
            }
          >
            <FontAwesomeIcon icon={faRotateRight} size="2x" color="#57bad8" />
          </button>
          <label htmlFor="autoRefresh" title="Refresh every 5 seconds">
            Auto&nbsp;Refresh
          </label>
          <div title="Refresh every 5 seconds" style={{ width: '45px' }}>
            <ToggleSwitch
              name="autoRefresh"
              onChange={toggleAutoRefresh}
              checked={autoRefresh}
            />
          </div>
        </div>
      )}
      </div>
      {currentPageEvents.length ? (
        <Fragment>
          <TransitionGroup>
            {currentPageEvents.map(
              (
                {
                  id,
                  donator,
                  message,
                  sub_message,
                  amount,
                  currency,
                  payment_platform,
                  media,
                  media_title,
                  created_at,
                  isNewEvent,
                },
                idx
              ) => (
                <CSSTransition
                  key={String(id)}
                  timeout={5000}
                  enter={true}
                  exit={false}
                >
                  <div className={'event' + (isNewEvent ? ' new-event' : '')}>
                    <div className="event-title">
                      <div className="event-replay">
                      {!isMod && (
                        <button
                          title="Replay"
                          className={idx === replayingIndex ? 'animate' : ''}
                          onClick={() => resend(id, idx)}
                        >
                          <FontAwesomeIcon
                            icon={faRepeat}
                            size="2x"
                            color="#57bad8"
                          />
                        </button>
                      )}
                        <h3 className="capitalize" style={{ marginLeft: '15px' }}>
                          {payment_platform}
                        </h3>
                      </div>
                      <h3>
                        {format(
                          new Date(created_at),
                          `Y-MM-dd HH:mm '${timezoneName}'`
                        )}
                      </h3>
                    </div>
                    <div className="event-details">
                      <div>
                        <h3>{donator}</h3>
                        {payment_platform == 'trovo' || payment_platform.startsWith('Twitch') || payment_platform.startsWith('Kick') || payment_platform.startsWith('DLive') ? (
                          <h4>
                            {amount} {currency}
                          </h4>
                        ) : (payment_platform == 'cozy') ? ( <h4>{currency}</h4> ) : (
                          <h4>${amount ? amount.toFixed(2) : 0}</h4>
                        )}
                        {media && (
                          <h4>
                            <a href={media} rel="noreferrer" target="_blank">
                              {media_title}
                            </a>
                          </h4>
                        )}
                      </div>
                      {message && message.length > 0 && (
                        <div className="message">
                          <p>{message}</p>
                        </div>
                      )}
                      {sub_message && sub_message.length > 0 && (
                        <div className="message">
                          <p>{sub_message}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </CSSTransition>
              )
            )}
          </TransitionGroup>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredTotal}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </Fragment>
      ) : total > 0 ? (
        <div>No events fit the search criteria</div>
      ) : (
        <div>No event history to display</div>
      )}
    </div>
  );
};

export default Events;
