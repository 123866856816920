import React from 'react';
import '../css/user-agreement.css';

const PrivacyPolicy = () => (
  <div className="page-container">
    <div className="user-agreement-container">
      <h3 className="text-center py-5">Privacy Policy</h3>

      <h4>Introduction</h4>
      <div>
        <p>
          Protecting your private information is our priority. This Statement of
          Privacy applies to Powerchat.live, and Powerchat LLC and governs data
          collection and usage. For the purposes of this Privacy Policy, unless
          otherwise noted, all references to Powerchat LLC include
          Powerchat.live and Powerchat LLC. The Powerchat LLC website is a
          content creator services, paid chat, and subscription site. By using
          the Powerchat LLC website, you consent to the data practices described
          in this statement.
        </p>
      </div>

      <h4>Collection of your Personal Information</h4>
      <div>
        <p>
          If you purchase Powerchat LLC&apos;s products and services, we collect
          billing and credit card information. This information is used to
          complete the purchase transaction.
        </p>
        <p>
          Please keep in mind that if you directly disclose personally
          identifiable information or personally sensitive data through
          Powerchat LLC&apos;s public message boards, this information may be
          collected and used by others.
        </p>
        <p>
          We do not collect any personal information about you unless you
          voluntarily provide it to us. However, you may be required to provide
          certain personal information to us when you elect to use certain
          products or services. These may include: (a) registering for an
          account; (b) entering a sweepstakes or contest sponsored by us or one
          of our partners; (c) signing up for special offers from selected third
          parties; (d) sending us an email message; (e) submitting your credit
          card or other payment information when ordering and purchasing
          products and services. To wit, we will use your information for, but
          not limited to, communicating with you in relation to services and/or
          products you have requested from us. We also may gather additional
          personal or non-personal information in the future.
        </p>
      </div>

      <h4>Use of your Personal Information</h4>
      <div>
        <p>
          Powerchat LLC collects and uses your personal information to operate
          and deliver the services you have requested.
        </p>
        <p>
          Powerchat LLC may also use your personally identifiable information to
          inform you of other products or services available from Powerchat LLC
          and its affiliates.
        </p>
      </div>

      <h4>Sharing Information with Third Parties</h4>
      <div>
        <p>
          Powerchat LLC does not sell, rent or lease its customer lists to third
          parties.
        </p>
        <p>
          Powerchat LLC may share data with trusted partners to help perform
          statistical analysis, send you email or postal mail, provide customer
          support, or arrange for deliveries. All such third parties are
          prohibited from using your personal information except to provide
          these services to Powerchat LLC, and they are required to maintain the
          confidentiality of your information.
        </p>
        <p>
          Powerchat LLC may disclose your personal information, without notice,
          if required to do so by law or in the good faith belief that such
          action is necessary to: (a) conform to the edicts of the law or comply
          with legal process served on Powerchat LLC or the site; (b) protect
          and defend the rights or property of Powerchat LLC; and/or (c) act
          under exigent circumstances to protect the personal safety of users of
          Powerchat LLC, or the public.
        </p>
        <p>
          Powerchat.live integrates with <a href="https://developers.google.com/youtube/terms/developer-policies#definition-youtube-api-services">YouTube API Services</a>. By linking your Powerchat.live account to your
          YouTube account, Powerchat.live may access your basic YouTube account information and
          channel memberships. Your Powerchat.live account information is not shared with YouTube.
          For more information, you can read the <a href="https://policies.google.com/privacy">Google Privacy Policy</a>.
        </p>
        <p>
          Powerchat&apos;s use and transfer of information received from Google APIs to any other app will adhere to Google API Services <a href="https://developers.google.com/terms/api-services-user-data-policy">User Data Policy</a>, including the <a href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes">Limited Use</a> requirements.
        </p>
      </div>

      <h4>Automatically Collected Information</h4>
      <div>
        <p>
          Information about your computer hardware and software may be
          automatically collected by Powerchat LLC. This information can
          include: your IP address, browser type, domain names, access times and
          referring website addresses. This information is used for the
          operation of the service, to maintain quality of the service, and to
          provide general statistics regarding use of the Powerchat LLC website.
        </p>
      </div>

      <h4>Use of Cookies</h4>
      <div>
        <p>
          The Powerchat LLC website may use &quot;cookies&quot; to help you personalize
          your online experience. A cookie is a text file that is placed on your
          hard disk by a web page server. Cookies cannot be used to run programs
          or deliver viruses to your computer. Cookies are uniquely assigned to
          you, and can only be read by a web server in the domain that issued
          the cookie to you.
        </p>
        <p>
          One of the primary purposes of cookies is to provide a convenience
          feature to save you time. The purpose of a cookie is to tell the Web
          server that you have returned to a specific page. For example, if you
          personalize Powerchat LLC pages, or register with Powerchat LLC site
          or services, a cookie helps Powerchat LLC to recall your specific
          information on subsequent visits. This simplifies the process of
          recording your personal information, such as billing addresses,
          shipping addresses, and so on. When you return to the same Powerchat
          LLC website, the information you previously provided can be retrieved,
          so you can easily use the Powerchat LLC features that you customized.
        </p>
        <p>
          You have the ability to accept or decline cookies. Most Web browsers
          automatically accept cookies, but you can usually modify your browser
          setting to decline cookies if you prefer. If you choose to decline
          cookies, you may not be able to fully experience the interactive
          features of the Powerchat LLC services or websites you visit.
        </p>
      </div>

      <h4>Links</h4>
      <div>
        <p>
          This website contains links to other sites. Please be aware that we
          are not responsible for the content or privacy practices of such other
          sites. We encourage our users to be aware when they leave our site and
          to read the privacy statements of any other site that collects
          personally identifiable information.
        </p>
      </div>

      <h4>Security of your Personal Information</h4>
      <div>
        <p>
          Powerchat LLC secures your personal information from unauthorized
          access, use, or disclosure. Powerchat LLC uses the following methods
          for this purpose: - SSL Protocol
        </p>
        <p>
          When personal information (such as a credit card number) is
          transmitted to other websites, it is protected through the use of
          encryption, such as the Secure Sockets Layer (SSL) protocol.
        </p>
        <p>
          We strive to take appropriate security measures to protect against
          unauthorized access to or alteration of your personal information.
          Unfortunately, no data transmission over the Internet or any wireless
          network can be guaranteed to be 100% secure. As a result, while we
          strive to protect your personal information, you acknowledge that: (a)
          there are security and privacy limitations inherent to the Internet
          which are beyond our control; and (b) security, integrity, and privacy
          of any and all information and data exchanged between you and us
          through this Site cannot be guaranteed.
        </p>
      </div>

      <h4>Right to Deletion</h4>
      <div>
        <p>
          Subject to certain exceptions set out below, on receipt of a
          verifiable request from you, we will: • Delete your personal
          information from our records; and • Direct any service providers to
          delete your personal information from their records.
        </p>
        <p>
          Please note that we may not be able to comply with requests to delete
          your personal information if it is necessary to: • Complete the
          transaction for which the personal information was collected, fulfill
          the terms of a written warranty or product recall conducted in
          accordance with federal law, provide a good or service requested by
          you, or reasonably anticipated within the context of our ongoing
          business relationship with you, or otherwise perform a contract
          between you and us; • Detect security incidents, protect against
          malicious, deceptive, fraudulent, or illegal activity; or prosecute
          those responsible for that activity; • Debug to identify and repair
          errors that impair existing intended functionality; • Exercise free
          speech, ensure the right of another consumer to exercise his or her
          right of free speech, or exercise another right provided for by law; •
          Comply with the California Electronic Communications Privacy Act; •
          Engage in public or peer-reviewed scientific, historical, or
          statistical research in the public interest that adheres to all other
          applicable ethics and privacy laws, when our deletion of the
          information is likely to render impossible or seriously impair the
          achievement of such research, provided we have obtained your informed
          consent; • Enable solely internal uses that are reasonably aligned
          with your expectations based on your relationship with us; • Comply
          with an existing legal obligation; or • Otherwise use your personal
          information, internally, in a lawful manner that is compatible with
          the context in which you provided the information.
        </p>
      </div>

      <h4>Children Under Thirteen</h4>
      <div>
        <p>
          Powerchat LLC does not knowingly collect personally identifiable
          information from children under the age of thirteen. If you are under
          the age of thirteen, you must ask your parent or guardian for
          permission to use this website.
        </p>
      </div>

      <h4>
        Disconnecting your Powerchat LLC Account from Third Party Websites
      </h4>
      <div>
        <p>
          You will be able to connect your Powerchat LLC account to third party
          accounts. BY CONNECTING YOUR POWERCHAT LLC ACCOUNT TO YOUR THIRD PARTY
          ACCOUNT, YOU ACKNOWLEDGE AND AGREE THAT YOU ARE CONSENTING TO THE
          CONTINUOUS RELEASE OF INFORMATION ABOUT YOU TO OTHERS (IN ACCORDANCE
          WITH YOUR PRIVACY SETTINGS ON THOSE THIRD PARTY SITES). IF YOU DO NOT
          WANT INFORMATION ABOUT YOU, INCLUDING PERSONALLY IDENTIFYING
          INFORMATION, TO BE SHARED IN THIS MANNER, DO NOT USE THIS FEATURE. You
          may disconnect your account from a third party account at any time.
          Users may disconnect access to any third party site in the user
          dashboard.
        </p>
        <p>
          If your Powerchat.live account is integrated with YouTube, you may revoke
          access to your YouTube account&apos;s data through <a href="https://security.google.com/settings/security/permissions">Google Security Settings</a>.
        </p>
      </div>

      <h4>E-mail Communications</h4>
      <div>
        <p>
          From time to time, Powerchat LLC may contact you via email for the
          purpose of providing announcements, promotional offers, alerts,
          confirmations, surveys, and/or other general communication.
        </p>
      </div>

      <h4>External Data Storage Sites</h4>
      <div>
        <p>
          We may store your data on servers provided by third party hosting
          vendors with whom we have contracted.
        </p>
      </div>

      <h4>Changes to this Statement</h4>
      <div>
        <p>
          Powerchat LLC reserves the right to change this Privacy Policy from
          time to time. We will notify you about significant changes in the way
          we treat personal information by sending a notice to the primary email
          address specified in your account, by placing a prominent notice on
          our website, and/or by updating any privacy information. Your
          continued use of the website and/or Services available after such
          modifications will constitute your: (a) acknowledgment of the modified
          Privacy Policy; and (b) agreement to abide and be bound by that
          Policy.
        </p>
      </div>

      <h4>Contact Information</h4>
      <div className="contact-info-container">
        <p>
          Powerchat LLC welcomes your questions or comments regarding this
          Statement of Privacy. If you believe that Powerchat LLC has not
          adhered to this Statement, please contact Powerchat LLC at:
        </p>
        <div>
          <p>Powerchat LLC</p>
        </div>
        <div>
          <p>Email Address:</p>
          <p>contact@powerchat.live</p>
        </div>
        <div>
          <p>Effective as of April 21, 2022</p>
        </div>
      </div>
    </div>
  </div>
);

export default PrivacyPolicy;
