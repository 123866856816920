import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/follow-button.css';
import '../css/app.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const FollowButton = ({
    followerUsername,
    streamerUsername,
    hasTooltip,
    onFollowerCountChange,
  }) => {
  const [alreadyFollowing,setAlreadyFollowing] = useState(false);
  const [tooltip, setTooltip] = useState('');
  
  useEffect(() => {
    const getFollowerCount = async () => {
      try {
        const response = await axios.get(`/api/followerCount/${streamerUsername}`);
        const count = response.data; // Assuming this is the structure
        onFollowerCountChange(count); // Update parent component
      } catch (error) {
        console.error('Failed to fetch follower count:', error);
      }
    };
  
    getFollowerCount();
  }, [streamerUsername, onFollowerCountChange]);

  const removeFollower = async () => {
    try {
      await axios
        .post(`/api/followers/remove`,{
          params:{
            streamerUsername: streamerUsername,
            followerUsername: followerUsername
         }
        })
        .then( (response) => {
          // 'true' return means removal was successful
          setAlreadyFollowing(!response.data);
          onFollowerCountChange(prevCount => prevCount - 1);
        });
    }catch(err){
      console.log(err);
      throw(err);
    }
};

  const addFollower = async () => {
    try {
      await axios.post(`/api/followers/add`, {
        params: {
          streamerUsername: streamerUsername,
          followerUsername: followerUsername
        }
      }).then((response) => {
        setAlreadyFollowing(response.data);
        onFollowerCountChange(prevCount => prevCount + 1);
      });
    } catch(err){
      console.error(err);
      throw(err);
    }
  };
  const localTooltips = {
    loggedOutFollow:"Login to follow this streamer and receive notifications when they go live.",
    follow:"Follow this streamer in order to receive notifications when they go live.",
    unfollow:"Click Unfollow to stop receiving notifications when this streamer goes live."
  };

  useEffect(() => {
    if(followerUsername){
      const getIsFollowing = async () => {
        await axios
          .get(`/api/followers/${streamerUsername}/${followerUsername}`)
          .then((response) => {
            setAlreadyFollowing(response.data);
          });
      };
      getIsFollowing()
    }
  },[alreadyFollowing])

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  if(followerUsername){
    return (
     <div className="follow-btn-container">
      <button
        data-fromuser={followerUsername}
        data-foruser={streamerUsername}
        className={alreadyFollowing?"unfollow-btn":"follow-btn"} 
        onClick={alreadyFollowing?removeFollower:addFollower}
      >
        {alreadyFollowing?"Unfollow":"Follow"} 
      </button>
      {hasTooltip && (
      <FontAwesomeIcon
        icon={faInfoCircle}
        color="#57bad8"
        onClick={() => toggleTooltip('followInfo')}
      />
      )}
      {hasTooltip && tooltip === 'followInfo' ? (
         <p className="tooltip shadow-lg">
           {alreadyFollowing?localTooltips["unfollow"]:localTooltips["follow"]}
         </p>
        ):null}
     </div>
    );
  }
  return (
    <div className="follow-btn-container">
      <a href="/login">
        <button className="disabled-follow-btn">
          Follow
        </button>
      </a>
      {hasTooltip && (
      <FontAwesomeIcon
        icon={faInfoCircle}
        color="#57bad8"
        onClick={() => toggleTooltip("followInfo")}
      />
      )}
      {hasTooltip && tooltip === "followInfo" && (
      <p className="tooltip shadow-lg">
        {localTooltips["loggedOutFollow"]}
      </p>
      )}
    </div>
  );
}

export default FollowButton;
