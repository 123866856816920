import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import FadeLoader from 'react-spinners/FadeLoader';
import Header from '../components/header';
import useAuth from '../hooks/useAuth';
import FormAlert from '../components/form-alert';
import Captcha from '../components/captcha';
import '../css/signup.css';

const Signup = () => {
  const [user, setUser] = useState({
    email: '',
    username: '',
    password: '',
    passwordConfirmation: '',
  });
  const [alertState, setAlertState] = useState({
    message: '',
    success: false,
  });
  const [showAlert, setShowAlert] = useState(false);
  const [captchaDone, setCaptchaDone] = useState(false);
  const [captchaToken, setCaptchaToken] = useState(false);
  const { signup, loading, error } = useAuth();

  const captchaCallback = async (token) => {
    setCaptchaDone(true);
    setCaptchaToken(token);
  };

  const triggerAlert = (message, show = true) => {
    setAlertState({
      message,
      success: false,
    });
    setShowAlert(show);
  };

  useEffect(() => {
    if (error) {
      setCaptchaDone(false);
      if (error.response.data.length > 0) {
        triggerAlert(error.response.data[0]);
      } else {
        triggerAlert('Registration failed.');
      }
    } else {
      triggerAlert('', false);
    }
  }, [error]);

  const isValidEmail = (email) => {
    return email.match(/[A-Z0-9._%+-]+@[A-Z0-9.-]+/i);
  };

  const handleChange = (event) => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    });
  };

  const isAlphanumeric = (string) => {
    return string.match(/^[a-z0-9]+$/i);
  };

  const hasNoSpaces = (string) => {
    return string.match(/^[^ ]+$/);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!captchaDone) {
      console.error('Cannot sign up. Captcha incomplete.');
      return;
    }
    if (!checkFormValid()) {
      return;
    }
    const { email, username, password, passwordConfirmation } = user;
    triggerAlert('', false);
    signup({
      email,
      username,
      password,
      passwordConfirmation,
      captchaToken,
    });
  };

  const checkFormValid = () => {
    if (user.email == '') {
      triggerAlert('Email required');
    } else if (!isValidEmail(user.email)) {
      triggerAlert('Email invalid');
    } else if (user.username == '') {
      triggerAlert('Username required');
    } else if (user.username.length > 16) {
      triggerAlert('Username must be 16 characters or fewer');
    } else if (!isAlphanumeric(user.username)) {
      triggerAlert('Username cannot have spaces or special characters');
    } else if (user.password == '') {
      triggerAlert('Password required');
    } else if (!hasNoSpaces(user.password)) {
      triggerAlert('Password cannot have spaces');
    } else if (user.password != user.passwordConfirmation) {
      triggerAlert('Password must match');
    } else {
      triggerAlert('', false);
      return true;
    }
    return false;
  };

  return (
    <div className="page-container signup-container">
      <Header size="small" />
      <form onSubmit={handleSubmit}>
        <h3>Signup:</h3>
        <input
          className="email-input"
          name="email"
          placeholder="Email"
          value={user.email}
          autoComplete="new-password"
          type="email"
          onChange={handleChange}
        />
        <input
          name="username"
          placeholder="Username"
          autoComplete="new-password"
          value={user.username}
          maxLength={16}
          type="text"
          onChange={handleChange}
        />
        <input
          name="password"
          type="password"
          value={user.password}
          placeholder="Password"
          autoComplete="new-password"
          onChange={handleChange}
        />
        <input
          name="passwordConfirmation"
          type="password"
          value={user.passwordConfirmation}
          placeholder="Confirm Password"
          autoComplete="new-password"
          onChange={handleChange}
        />
        {!captchaDone && (
          <Captcha
            captchaDone={captchaDone}
            captchaCallback={captchaCallback}
          />
        )}
        <button disabled={!captchaDone || loading}>Signup</button>
        <div className="link-container">
          <div className="signup-link">
            <span>Already have an account?</span>
            <Link to="/login">Login</Link>
          </div>
        </div>
        <div>
          <FadeLoader color="#ffffff" loading={loading} size={20} />
        </div>
        {showAlert && (
          <FormAlert
            alertState={alertState}
            width={265}
            margin={'0'}
            duration={-1}
          />
        )}
      </form>
    </div>
  );
};

export default Signup;
