import React, { useState, useEffect } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import axios from 'axios';
import ProgressBar from '../components/progress-bar';
import '../css/goal.css';
import '../css/fonts.css';
import Sockette from 'sockette';

const defaultProperties = {
  theme: 'default',
  primaryColor: '#375a7f',
  secondaryColor: '#ffffff',
  tertiaryColor: 'transparent',
  backgroundColor: 'rgba(10, 10, 10, 0.4)',
  isMonetary: false,
};

const GoalOverlay = () => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const { username } = useParams();
  const location = useLocation();
  const pathSegments = location.pathname.split('/').filter(Boolean);
  const goalType = pathSegments[pathSegments.length - 1];

  const [goalName, setGoalName] = useState('');
  const [goalCurrent, setGoalCurrent] = useState(0);
  const [goalMax, setGoalMax] = useState(1);
  const [properties, setProperties] = useState({
    ...defaultProperties,
  });

  useEffect(() => {
    const fetchInitialSettings = async () => {
      try {
        const response = await axios.get('/pubapi/goal', {
          params: { username, goalType },
        });
        const settings = response.data.goalSettings || {};
        applySettings(settings);
      } catch (error) {
        console.error('Error getting goal settings:', error);
      }
    };

    const applySettings = (settings) => {
      const searchParams = new URLSearchParams(location.search);
      setGoalName(settings.goalName || '');
      setGoalCurrent(parseFloat(settings.goalCurrent) || 0);
      let max = parseFloat(settings.goalMax);
      setGoalMax(max === 0 ? 0 : max || 1);

      const overrides = {
        ...properties,
        ...settings,
        tertiaryColor: searchParams.get('tertiaryColor') || settings.tertiaryColor || properties.tertiaryColor,
        secondaryColor: searchParams.get('secondaryColor') || settings.secondaryColor || properties.secondaryColor,
        primaryColor: searchParams.get('primaryColor') || settings.primaryColor || properties.primaryColor,
        backgroundColor: searchParams.get('backgroundColor') || settings.backgroundColor || properties.backgroundColor,
        theme: searchParams.get('theme') || settings.theme || properties.theme,
        fontStyle: searchParams.get('fontStyle') || settings.fontStyle || properties.fontStyle,
        isMonetary: searchParams.has('isMonetary') ? searchParams.get('isMonetary') === 'true' : settings.isMonetary || properties.isMonetary,
        length: searchParams.get('length') || `${settings.goalLength}${settings.goalLengthUnit}` || `${properties.goalLength}${properties.goalLengthUnit}`,
      };
    
      if (overrides.goalCurrent) {
        overrides.goalCurrent = parseFloat(overrides.goalCurrent);
      }
      if (overrides.goalMax) {
        overrides.goalMax = parseFloat(overrides.goalMax);
      }
    
      let exposedSettings = overrides;
      delete exposedSettings.goalType;
      delete exposedSettings.goalLength;
      delete exposedSettings.goalLengthUnit;

      if (overrides.textValue) {
        overrides.textValue = overrides.textValue
          .replace(/{goal_name}/g, overrides.goalName ? overrides.goalName : '')
          .replace(/{current}/g, overrides.isMonetary ? `$${overrides.goalCurrent.toFixed(2)}` : `${overrides.goalCurrent.toFixed(0)}`)
          .replace(/{max}/g, overrides.isMonetary ? `$${overrides.goalMax.toFixed(2)}` : `${overrides.goalMax.toFixed(0)}`)
          .replace(/{percentage}/g, overrides.goalMax > 0 ? `${((overrides.goalCurrent / overrides.goalMax) * 100).toFixed(0)}%` : "100%");
      }

      setProperties(overrides);
    };

    fetchInitialSettings();

    const sockette = new Sockette(`wss://${baseUrl}/${username.toLowerCase()}_${goalType}`,
      {
        onopen: function (event) {
          event.target.send(`Remote client log - ${username} - ${goalType} overlay opened`);
        },
        onmessage: function (event) {
          event.target.send(`Remote client log - ${username} ${goalType} - [message] Data received from server: ${event.data}`);
          if (event.data == 'pong') {
            return;
          }
          const update = JSON.parse(event.data);
          if (update) fetchInitialSettings();
        },
        onclose: function (event) {
          if (event.wasClean) {
            console.info(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
          } else {
            console.error(`[close] Connection died`);
          }
        },
        onerror: function (error) {
          console.error(`[error] ${error.message}`);
        },
      }
    );
    setInterval(() => {
      sockette && sockette.send(`ping from: ${username} - ${goalType}`);
    }, 30 * 1000);
  }, [username, goalType, location.search]);

  return (
    <div className={`goal-content ${properties.fontStyle} ${properties.orientation}`} style={{
      backgroundColor: 'transparent',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      boxSizing: 'border-box',
      position: 'relative',
    }}>
      <ProgressBar
        goalName={goalName}
        value={goalCurrent}
        max={goalMax}
        text={properties.textValue}
        theme={properties.theme}
        primaryColor={properties.primaryColor}
        secondaryColor={properties.secondaryColor}
        tertiaryColor={properties.tertiaryColor}
        backgroundColor={properties.backgroundColor}
        textColor={properties.textColor}
        isMonetary={properties.isMonetary}
        fontSize={properties.fontSize}
        orientation={properties.orientation}
        length={`${properties.length}`}
      />
    </div>
  );
};

export default GoalOverlay;
