import React, { useState, useEffect } from 'react';
import axios from 'axios';

const StripePay = ({
  getPowerchat,
  reloadCaptcha,
  completed,
  stripeAccount,
  disabled,
  setLoading,
  googlePayLoaded,
  captchaToken,
}) => {
  const [paymentsClient, setPaymentsClient] = useState(null);

  /**
   * Define the version of the Google Pay API referenced when creating your configuration
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|apiVersion in PaymentDataRequest}
   */
  const baseRequest = {
    apiVersion: 2,
    apiVersionMinor: 0,
  };

  /**
   * Card networks supported by your site and your gateway
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
   * @todo confirm card networks supported by your site and gateway
   */
  const allowedCardNetworks = [
    'AMEX',
    'DISCOVER',
    'INTERAC',
    'JCB',
    'MASTERCARD',
    'VISA',
  ];

  /**
   * Card authentication methods supported by your site and your gateway
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
   * @todo confirm your processor supports Android device tokens for your * supported card networks
   */
  const allowedCardAuthMethods = ['PAN_ONLY', 'CRYPTOGRAM_3DS'];

  /**
   * Describe your site's support for the CARD payment method and its required fields
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#CardParameters|CardParameters}
   */
  const baseCardPaymentMethod = {
    type: 'CARD',
    parameters: {
      allowedAuthMethods: allowedCardAuthMethods,
      allowedCardNetworks: allowedCardNetworks,
    },
  };

  useEffect(() => {
    if (googlePayLoaded) addGooglePayButton();
  }, [googlePayLoaded, disabled]);

  const cardPaymentMethod = () => {
    return Object.assign({}, baseCardPaymentMethod, {
      tokenizationSpecification: tokenizationSpecification(),
    });
  };

  /**
   * Identify your gateway and your site's gateway merchant identifier
   *
   * The Google Pay API response will return an encrypted payment method capable
   * of being charged by a supported gateway after payer authorization
   *
   * @todo check with your gateway on the parameters to pass
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#gateway|PaymentMethodTokenizationSpecification}
   */
  const tokenizationSpecification = () => {
    return {
      type: 'PAYMENT_GATEWAY',
      parameters: {
        gateway: 'stripe',
        'stripe:version': '2018-10-31',
        'stripe:publishableKey':
          process.env.STRIPE_PUBLIC + '/' + stripeAccount,
      },
    };
  };

  /**
   * Configure support for the Google Pay API
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#PaymentDataRequest|PaymentDataRequest}
   * @returns {object} PaymentDataRequest fields
   */
  const getGooglePaymentDataRequest = () => {
    const paymentDataRequest = Object.assign({}, baseRequest);
    paymentDataRequest.allowedPaymentMethods = [cardPaymentMethod()];
    paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
    paymentDataRequest.merchantInfo = {
      // @todo a merchant ID is available for a production environment after approval by Google
      // See {@link https://developers.google.com/pay/api/web/guides/test-and-deploy/integration-checklist|Integration checklist}
      merchantName: process.env.STRIPE_MERCHANT_NAME,
    };

    if (process.env.ENVIRONMENT == 'prod') {
      paymentDataRequest.merchantInfo['merchantId'] =
        process.env.STRIPE_GPAY_MERCHANT_ID;
    }
    return paymentDataRequest;
  };

  /**
   * Return an active PaymentsClient or initialize
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/client#PaymentsClient|PaymentsClient constructor}
   * @returns {google.payments.api.PaymentsClient} Google Pay API client
   */
  const getGooglePaymentsClient = () => {
    if (paymentsClient === null) {
      let client = null;
      if (process.env.ENVIRONMENT == 'prod') {
        client = new window.google.payments.api.PaymentsClient({
          environment: 'PRODUCTION',
        });
      } else {
        client = new window.google.payments.api.PaymentsClient({
          environment: 'TEST',
        });
      }
      setPaymentsClient(client);
      return client;
    } else {
      return paymentsClient;
    }
  };

  /**
   * Add a Google Pay purchase button alongside an existing checkout button
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#ButtonOptions|Button options}
   * @see {@link https://developers.google.com/pay/api/web/guides/brand-guidelines|Google Pay brand guidelines}
   */
  const addGooglePayButton = () => {
    const paymentsClient = getGooglePaymentsClient();
    const button = paymentsClient.createButton({
      buttonColor: 'white',
      buttonSizeMode: 'fill',
      buttonType: 'long',
      //if onClick is set here, it will not listen for React state changes and may end up with stale data
      //it is set in the HTML element instead
      //onClick: onGooglePaymentButtonClicked
    });
    document.getElementById('googlePayButton').replaceChildren(button);
  };

  /**
   * Provide Google Pay API with a payment amount, currency, and amount status
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/request-objects#TransactionInfo|TransactionInfo}
   * @returns {object} transaction info, suitable for use as transactionInfo property of PaymentDataRequest
   */
  const getGoogleTransactionInfo = () => {
    let amount = getPowerchat().amount;
    return {
      countryCode: 'US',
      currencyCode: 'USD',
      totalPriceStatus: 'FINAL',
      //set to cart total
      totalPrice: `${amount}`,
    };
  };

  /**
   * Prefetch payment data to improve performance
   *
   * @see {@link https://developers.google.com/pay/api/web/reference/client#prefetchPaymentData|prefetchPaymentData()}
   */
  /*const prefetchGooglePaymentData = () => {
    const paymentDataRequest = getGooglePaymentDataRequest();
    // transactionInfo must be set but does not affect cache
    paymentDataRequest.transactionInfo = {
      totalPriceStatus: 'NOT_CURRENTLY_KNOWN',
      currencyCode: 'USD',
    };
    const paymentsClient = getGooglePaymentsClient();
    paymentsClient.prefetchPaymentData(paymentDataRequest);
  };*/

  /**
   * Show Google Pay payment sheet when Google Pay payment button is clicked
   */
  const onGooglePaymentButtonClicked = () => {
    if (disabled) {
      throw 'Payment not allowed in current state';
    }
    try {
      setLoading(true);
      const process = processPayment;
      const paymentDataRequest = getGooglePaymentDataRequest();
      paymentDataRequest.transactionInfo = getGoogleTransactionInfo();
      const paymentsClient = getGooglePaymentsClient();
      paymentsClient
        .loadPaymentData(paymentDataRequest)
        .then(async function (paymentData) {
          // handle the response
          await process(paymentData);
          reloadCaptcha();
        })
        .catch(function (err) {
          // show error in developer console for debugging
          console.error(err);
          reloadCaptcha();
        });
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  /**
   * Process payment data returned by the Google Pay API
   *
   * @param {object} paymentData response from Google Pay API after user approves payment
   * @see {@link https://developers.google.com/pay/api/web/reference/response-objects#PaymentData|PaymentData object reference}
   */
  const processPayment = async (paymentData) => {
    // show returned data in developer console for debugging
    // @todo pass payment token to your gateway to process payment
    const paymentToken = JSON.parse(
      paymentData.paymentMethodData.tokenizationData.token
    );
    let powerchat = getPowerchat();
    try {
      await axios.post('/pubapi/stripe', {
        captchaToken,
        stripeUserId: stripeAccount,
        paymentToken: paymentToken,
        ...powerchat,
      });
      completed('Stripe payment completed');
    } catch (err) {
      console.error(err);
    }
  };

  const disabledStyle = {
    pointerEvents: 'none',
  };

  return (
    <div className="google-pay-container">
      <span className="image-container">
        <img className="icon" src="/static/img/stripe.svg" />
      </span>
      <div
        style={disabled ? disabledStyle : {}}
        id="googlePayButton"
        onClick={onGooglePaymentButtonClicked}
      ></div>
    </div>
  );
};

export default StripePay;
