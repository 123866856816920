import React, { useState, useEffect } from 'react';
import '../css/form-alert.css';

const FormAlert = ({
  alertState,
  width = width ? width + 'px' : 'inherit',
  height = height ? height + 'px' : 'inherit',
  margin = '40px 0 0',
  duration = 3000,
}) => {
  const [state, setState] = useState({
    message: alertState.message,
    success: alertState.success,
    show: false,
  });

  useEffect(() => {
    if (!alertState.message) {
      return;
    }
    setState({
      message: alertState.message,
      success: alertState.success,
      show: true,
    });
    if (duration >= 0) {
      setTimeout(() => {
        setState({ ...state, success: alertState.success, show: false });
      }, duration);
    }
  }, [alertState]);

  return (
    <div
      style={{ maxWidth: width, margin, maxHeight: height }}
      className={`form-alert ${state.success ? 'success' : 'failure'}
        ${state.show ? '' : 'invisible'} ${
        state.disappearing ? 'disappearing' : ''
      }`}
    >
      <p>{state.message}</p>
    </div>
  );
};

export default FormAlert;
