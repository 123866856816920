import React, { useEffect, useState, useRef, useMemo, Fragment } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { DatePicker } from '@mui/x-date-pickers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import '../css/moderation.css';
import Card from '../components/card';
import Pagination from '../components/pagination';
import { sortAlphabetically } from '../constants';
import UserModeration from '../components/dialogs/user-moderation';

const pageSize = 10;

const Moderation = () => {
  const [initialUsers, setInitialUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState(0);
  const [userDialogOpen, setUserDialogOpen] = useState(false);
  const [userId, setUserId] = useState(null);
  const [searchText, setSearchText] = useState('');
  const searchTextRef = useRef('');
  const [minDate, setMinDate] = useState(null);
  const minDateRef = useRef(null);
  const [maxDate, setMaxDate] = useState(null);
  const maxDateRef = useRef(null);
  const [filteredTotal, setFilteredTotal] = useState(0);

  const getAllUsers = async () => {
    try {
      const { data } = await axios.get('/modapi/users');
      setInitialUsers(data);
      setTotal(data.length);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    setUsers(initialUsers);
  }, [initialUsers]);

  const openUserDialog = (id) => {
    setUserId(id);
    setUserDialogOpen(true);
  };

  const closeUserDialog = () => setUserDialogOpen(false);

  const [currentPage, setCurrentPage] = useState(1);

  const currentPageUsers = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * pageSize;
    const lastPageIndex = firstPageIndex + pageSize;
    setFilteredTotal(users.filter(x => x.hidden !== true).length);
    return users.filter(x => x.hidden !== true).slice(firstPageIndex, lastPageIndex);
  }, [currentPage, users]);

  const searchTextChanged = (event) => {
    setSearchText(event.target.value);
  };

  useEffect(() => {
    searchTextRef.current = searchText;
  }, [searchText]);

  useEffect(() => {
    maxDateRef.current = maxDate;
  }, [maxDate]);

  useEffect(() => {
    minDateRef.current = minDate;
  }, [minDate]);

  const filterSubmit = (event) => {
    event.preventDefault();
    setUsers((users) => {
      let newUsers = users.map(x => ({
        ...x,
        hidden: shouldFilter(x),
      }));
      return newUsers;
    });
    setCurrentPage(1);
  };

  const shouldFilter = (user) => {
    let lowerSearch = searchTextRef.current.toLowerCase();
    return (lowerSearch != '' &&
      !user.username.toLowerCase().includes(lowerSearch) &&
      !user.email.toLowerCase().includes(lowerSearch))
      || (!!minDateRef.current && minDateRef.current > new Date(user.created))
      || (!!maxDateRef.current && maxDateRef.current < new Date(new Date(user.created).toLocaleDateString()));
  };

  const clearFilter = () => {
    setSearchText('');
    setMinDate(null);
    setMaxDate(null);
  };

  return (
    <div className="moderation-container page-container">
      <Card title="Moderation" lineClass="moderation-line">
        <div>
          <div className="search-wrapper">
            <form className="event-filter-controls" onSubmit={filterSubmit}>
              <div className="flex-row">
                <input className="search" type="search" name="searchText" placeholder="Filter by username or email"
                  onChange={searchTextChanged} value={searchText} />
                <div className="date-pick">
                  <DatePicker
                    format="MM/DD/YYYY"
                    label="Start Date"
                    value={minDate}
                    maxDate={maxDate}
                    sx={{
                      width: 200,
                    }}
                    slotProps={{
                      field: { clearable: true, onClear: () => setMinDate(null) }
                    }}
                    onChange={(date) => setMinDate(date)} />
                </div>
                <div className="date-pick">
                  <DatePicker
                    format="MM/DD/YYYY"
                    label="End Date"
                    value={maxDate}
                    minDate={minDate}
                    sx={{
                      width: 200,
                    }}
                    slotProps={{
                      field: { clearable: true, onClear: () => setMaxDate(null) }
                    }}
                    onChange={(date) => setMaxDate(date)} />
                </div>
              </div>
              <div className="form-container">
                <button className="primary-btn" onClick={filterSubmit}>
                  Search&nbsp;
                  <FontAwesomeIcon icon={faSearch} size="1x" color="#fff" />
                </button>
                <button className="secondary-btn" onClick={clearFilter}>
                  Clear
                </button>
              </div>
            </form>
          </div>
          <table>
            <thead>
              <tr>
                <th>User ID</th>
                <th>Username</th>
                <th>Email</th>
                <th className="text-center">Created Date</th>
                <th className="text-center">Modified Date</th>
                <th className="text-center">Notes</th>
                <th />
              </tr>
            </thead>
            <tbody>
            {currentPageUsers.length ? (
              <Fragment>
            {currentPageUsers.sort(sortAlphabetically).map(
              (
                {
                  id,
                  username,
                  email,
                  avatar,
                  created,
                  mod_date,
                  mod_notes,
                },
                idx
              ) => (
                <tr key={username + String(idx)}>
                  <td>{id}</td>
                  <td>
                    <img src={avatar || '/static/img/user-default.jpg'} />
                    {username}
                  </td>
                  <td>{email}</td>
                  <td className="text-center">
                    {format(new Date(created), 'MMM d, yyyy kk:mm:ss')}
                  </td>
                  <td className="text-center">
                    {mod_date && format(new Date(mod_date), 'MMM d, yyyy kk:mm:ss')}
                  </td>
                  <td colSpan="2" style={{ maxWidth: 100 }} title={mod_notes}>
                    {mod_notes}
                  </td>
                  <td>
                    <button>
                      <FontAwesomeIcon
                        icon={faEdit}
                        color="#57bad8"
                        size="2x"
                        onClick={() => openUserDialog(id)}
                      />
                    </button>
                  </td>
                </tr>
                )
              )}
             </Fragment>
            ) : total > 0 && (
              <div>No users fit the search criteria</div>
            )}
            </tbody>
          </table>
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filteredTotal}
            pageSize={pageSize}
            onPageChange={(page) => setCurrentPage(page)}
          />
        </div>
      </Card>
      <UserModeration
        userId={userId}
        open={userDialogOpen}
        onClose={closeUserDialog}
      />
    </div>
  );
};

export default Moderation;
