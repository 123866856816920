import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../css/emote-wall.css';
import FireworksEmote from './animations/fireworks-emote.jsx';
import ExplosionEmote from './animations/explosion-emote.jsx';
import ScreenSaverEmote from './animations/screen-saver-emote.jsx';
import RainfallEmote from './animations/rainfall-emote.jsx';
import DecompressionEmote from './animations/decompression-emote.jsx';
import axios from 'axios';
import Sockette from 'sockette';

const Emote = (props) => {
  const { id, emote, animation, maxSize, speed, containerRef, containerWidth, containerHeight } = props;

  switch(animation) {
    case 'decompression':
      return <DecompressionEmote eId={id} emote={emote} maxSize={maxSize} containerWidth={containerWidth} containerHeight={containerHeight} speed={speed} containerRef={containerRef} />;

    case 'fireworks':
      return <FireworksEmote eId={id} emote={emote} maxSize={maxSize} containerWidth={containerWidth} containerHeight={containerHeight} speed={speed} containerRef={containerRef} />;

    case 'explosion':
      return <ExplosionEmote eId={id} emote={emote} maxSize={maxSize} containerWidth={containerWidth} containerHeight={containerHeight} speed={speed} containerRef={containerRef} />;
      
    case 'screensaver':
      return <ScreenSaverEmote eId={id} emote={emote} maxSize={maxSize} containerWidth={containerWidth} containerHeight={containerHeight} speed={speed} containerRef={containerRef} />;

    case 'rainfall':
    default:
      return <RainfallEmote eId={id} emote={emote} maxSize={maxSize} containerWidth={containerWidth} containerHeight={containerHeight} speed={speed} containerRef={containerRef} />;
  }
};

const EmoteWall = () => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const pingTime = 1 * 60 * 1000; // 1 minute
  const { username } = useParams();
  const [settings, setSettings] = useState({
    animation: 'rainfall',
    maxEmoteSize: 120,
    speed: 1.0,
    errorMessage: '',
  });
  const [emotes, setEmotes] = useState([]);
  const containerRef = useRef();
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  useEffect(() => {
    const updateContainerSize = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
        setContainerHeight(containerRef.current.offsetHeight);
      }
    };

    updateContainerSize();

    window.addEventListener('resize', updateContainerSize);

    return () => {
      window.removeEventListener('resize', updateContainerSize);
    };
  }, []);

  useEffect(() => {
    async function loadSettings() {
      try {
        const response = await axios.get(`https://${baseUrl}/pubapi/emote-wall-settings`, { params: { username } });
        setSettings(response.data);
        console.info('Emote Wall settings:', response.data)
      } catch (error) {
        console.error('An error occurred:', error);
        setSettings({
          ...settings,
          errorMessage: ``, // will send to 404 in future
        });
      }
    }    

    const intervalId = setInterval(() => {
      loadSettings();
    }, pingTime);

    loadSettings();

    return () => {
      clearInterval(intervalId);
    };
  }, [baseUrl, username]);

  useEffect(() => {
    const startWebsockets = () => {
      const protocol = 'wss';
      let socket = new Sockette(
        `${protocol}://${baseUrl}/${username.toLowerCase()}_emote`,
        {
          onopen: function (event) {
            event.target.send(
              `Remote client log - ${username} - Open Emote Wall - ${username}`
            );
            console.info(`[open] Listening for emotes for ${username}...`)
          },
  
          onmessage: function (event) {
            event.target.send(
              `Remote client log - ${username} - [message] Data received from server: ${event.data}`
            );
            if (event.data == 'pong') {
              return;
            }
            const data = JSON.parse(event.data);
            receiveMessage(data);
          },
  
          onclose: function (event) {
            if (event.wasClean) {
              console.info(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`
              );
            } else {
              console.info('[close] Connection died');
            }
          },
  
          onerror: function (error) {
            console.error(`[error] ${error.message}`);
          },
        }
      );
  
      setInterval(() => {
        socket.send(`ping from: ${username} - Emote Wall Overlay`);
      }, 30 * 1000);
    };

    startWebsockets();
  }, []);

  useEffect(() => {
    const loadUser = async () => {
      try {
        const chatroomSettings = await axios.get(`https://${baseUrl}/pubapi/chat-settings`, { params: { username } });
        let { cozyUser, youtubeUser, rumbleUser } = chatroomSettings.data;
        if(cozyUser || youtubeUser || rumbleUser) {
          chatPing({ cozyUser, youtubeUser, rumbleUser });
          setInterval(() => chatPing({ cozyUser, youtubeUser, rumbleUser }), pingTime*5);
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    const chatPing = async ({ cozyUser, youtubeUser, rumbleUser }) => {
      //console.info('Pinging chats for:', username, 'Cozy:', cozyUser, 'YouTube:',youtubeUser);
      await axios.post('/pubapi/chat-ping', { username, cozyUser, youtubeUser, rumbleUser });
    };

    loadUser();
  }, []);

  const receiveMessage = async (data) => {
    const newEmote = {
      id: Date.now(),
      url: data.emoteUrl,
    };

    setEmotes((prevEmotes) => {
      const updatedEmotes = [...prevEmotes, newEmote]
      return updatedEmotes;
    });
  };

  return (
    <div className={`emotewall`} ref={containerRef}>
      {settings.errorMessage ? (
        <div className="error-message" dangerouslySetInnerHTML={{ __html: settings.errorMessage }}></div>
      ) : (
        <div className={`emote-wall-container`} >
          {emotes.map((emote) => (
            <Emote key={emote.id} id={emote.id} emote={emote.url} animation={settings.animation} maxSize={settings.maxEmoteSize} speed={settings.speed} containerRef={containerRef} containerWidth={containerWidth} containerHeight={containerHeight} />
          ))}
        </div>
      )}
    </div>
  );
};

export default EmoteWall;
