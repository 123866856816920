import React, { useState } from 'react';

function formatCurrency(value, currency = 'USD') {
  return isNaN(value) ? '' : new Intl.NumberFormat('en-US', { style: 'currency', currency }).format(value);
}

const MonetaryInput = ({ value, onChange, is_monetary = true, currency = 'USD', disabled = false }) => {
  // Initialize displayValue based on is_monetary flag
  const [displayValue, setDisplayValue] = useState(
    is_monetary ? formatCurrency(value, currency) : value.toString()
  );

  const handleInputChange = (event) => {
    const input = event.target.value;

    if (is_monetary) {
      // Handle monetary input
      const cleanInput = input.replace(/[^0-9.-]/g, '');
      setDisplayValue(cleanInput); // Keep the display value clean but unformatted for editing
      
      const parsedValue = parseFloat(cleanInput);
      onChange(isNaN(parsedValue) ? 0 : parsedValue);
    } else {
      // Handle non-monetary input as integer
      const cleanInput = input.replace(/[^0-9]/g, ''); // Only allow digits
      setDisplayValue(cleanInput);
      
      const parsedValue = parseInt(cleanInput, 10);
      onChange(isNaN(parsedValue) ? 0 : parsedValue);
    }
  };

  const handleBlur = () => {
    // Only format monetary values on blur
    if (is_monetary) {
      setDisplayValue(formatCurrency(value, currency));
    }
  };

  return (
    <input
      type={is_monetary ? "text" : "number"}
      value={displayValue}
      onChange={handleInputChange}
      onBlur={handleBlur}
      step={is_monetary ? undefined : "1"}
      disabled={disabled}
    />
  );
};

export default MonetaryInput;
