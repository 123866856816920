import React, { useMemo } from 'react';
import { getRandomNumber } from '../../../util/random-number.js';
import '../../css/emote-wall.css';

const RainfallEmote = React.memo((props) => {
  const { eId, emote, maxSize, containerWidth, speed } = props;
  const emoteStyles = useMemo(() => {
    const size = getRandomNumber(maxSize / 2, maxSize);
    const startingPositionX = getRandomNumber(0, containerWidth - size);
    const animationSpeed = getRandomNumber(speed / 2, speed) + 's';
    const opacity = getRandomNumber(0.5, 1.0);
    const rotationSpeed = getRandomNumber(-5, 5);
    const delay = getRandomNumber(0, 2) + 's';

    return {
      size,
      startingPositionX,
      animationSpeed,
      opacity,
      rotationSpeed,
      delay
    };
  }, [maxSize, containerWidth, speed]);

  const handleAnimationEnd = (e) => {
    e.target.remove();
  };

  return (
    <div
      id={`emote_${eId}`}
      className="emote-container rainfall"
      onAnimationEnd={handleAnimationEnd}
      style={{
        maxWidth: `${emoteStyles.size}px`,
        maxHeight: `${emoteStyles.size}px`,
        top: `-${emoteStyles.size}px`,
        left: `${emoteStyles.startingPositionX}px`,
        animationDuration: emoteStyles.animationSpeed,
        opacity: emoteStyles.opacity,
        animationDelay: emoteStyles.delay,
        animationName: 'rainfall',
        position: 'absolute'
      }}
    >
      <img
        className="emote"
        src={emote}
        alt=""
        style={{
          maxWidth: `${emoteStyles.size}px`,
          maxHeight: `${emoteStyles.size}px`,
          animationDuration: `${speed}s`,
          animationIterationCount: 'infinite',
          animationName: `rotateEmote${emoteStyles.rotationSpeed < 0 ? 'Left' : 'Right'}`
        }}
      />
    </div>
  );  
});

RainfallEmote.displayName = 'RainfallEmote';

export default RainfallEmote;