import { createTheme } from '@mui/material/styles';

const commonStyles = {
  palette: {
    colors: {
      white: 'var(--white)',
      black: 'var(--black)',
      grey: 'var(--grey)',
      lightGrey: 'var(--light-grey)',
      darkGrey: 'var(--dark-grey)',
      mediumGrey: 'var(--medium-grey)',
      lightBlue: 'var(--light-blue)',
      lighterBlue: 'var(--lighter-blue)',
    },
    backgrounds: {
      darkGrey: 'var(--dark-grey)',
      mediumGrey: 'var(--medium-grey)',
      transparent: 'transparent !important',
    },
  },
  typography: {
    commonFontSize: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
      color: 'var(--white)',
      textOverflow: 'ellipsis',
    },
  },
  transitions: {
    common: {
      transition: 'transform 0.2s',
      '&:hover': {
        transform: 'scale(1.03)',
      },
    },
  },
  borders: {
    borderRadius: 'var(--border-radius)',
    lightGrey: {
      borderColor: 'var(--light-grey)',
    },
    lightBlue: {
      borderColor: 'var(--light-blue)',
    },
  },
  effects: {
    hoverLightBlueBackground: {
      '&:hover': {
        backgroundColor: 'var(--light-blue)',
      },
    },
  },
};

const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&.MuiCard-root': {
            width: '145px',
            height: '200x',
            ...commonStyles.transitions.common,
            backgroundColor: commonStyles.palette.backgrounds.mediumGrey,
            borderRadius: commonStyles.borders.borderRadius,
            boxShadow: 'none',
          },
          '&.MuiCard-root.css-1v5z4dq': {
            backgroundColor: commonStyles.palette.backgrounds.transparent,
          },
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          ...commonStyles.typography.commonFontSize,
          backgroundColor: commonStyles.palette.backgrounds.transparent,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          ...commonStyles.typography.commonFontSize,
          width: '80%',
          padding: '5px 10px',
          '&.add-to-soundboard': {
            width: '60%',
            padding: '3px 5px',
            fontSize: '0.65rem',
          },
          '&:hover': {
            ...commonStyles.effects.hoverLightBlueBackground,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          width: '40px',
          height: '20px',
          padding: 0,
          display: 'flex',
          marginRight: '10px',
        },
        switchBase: {
          padding: 2,
          '&.Mui-checked': {  // Correct class targeting for checked state
            transform: 'translateX(20px)',
            color: 'var(--white)', // Ensure this variable is defined
            '+ .MuiSwitch-track': {  // Correct sibling selector for the track
              backgroundColor: 'var(--light-blue)', // Using CSS variable for light blue
              opacity: 1,
              border: 'none',
            },
          },
        },
        thumb: {
          width: 16,
          height: 16,
          boxShadow: 'none',
          backgroundColor: 'var(--white)', // Ensure this variable is defined
        },
        track: {
          borderRadius: 20,
          backgroundColor: 'rgb(134, 133, 133)', // Default state background
          opacity: 1,
          transition: 'background-color 0.2s',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            color: commonStyles.palette.colors.white,
          },
          '& .MuiOutlinedInput-input': {
            color: commonStyles.palette.colors.white,
          },
          '& .MuiInputLabel-root': {
            color: commonStyles.palette.colors.white,
            '&.Mui-focused': {
              color: commonStyles.palette.colors.lightBlue,
            },
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: commonStyles.palette.colors.lightGrey,
            },
            '&:hover fieldset': {
              borderColor: commonStyles.palette.colors.lightBlue,
            },
            '&.Mui-focused fieldset': {
              borderColor: commonStyles.palette.colors.lightBlue,
            },
          },
          '& .MuiSelect-select': {
            color: commonStyles.palette.colors.white,
            '& option': {
              color: commonStyles.palette.colors.black,
            },
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        paper: {
          backgroundColor: commonStyles.palette.backgrounds.darkGrey,
          color: commonStyles.palette.colors.white,
        },
        noOptions: {
          color: commonStyles.palette.colors.white,
        },
        root: {
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: commonStyles.borders.lightGrey.borderColor,
            },
            '&:hover fieldset': {
              borderColor: commonStyles.borders.lightBlue.borderColor,
            },
            '&.Mui-focused fieldset': {
              borderColor: commonStyles.borders.lightBlue.borderColor,
            },
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundColor: commonStyles.palette.backgrounds.darkGrey,
          color: commonStyles.palette.colors.white,
          padding: '20px',
          borderRadius: commonStyles.borders.borderRadius,
          width: '100%',
          maxWidth: '345px',
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.lightBlue,
        },
        thumb: {
          color: commonStyles.palette.colors.white,
        },
        track: {
          color: commonStyles.palette.colors.white,
        },
        rail: {
          color: commonStyles.palette.colors.white,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.lightBlue,
          '&:hover': {
            color: commonStyles.palette.colors.lightGrey,
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.white,
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: commonStyles.palette.colors.white,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          position: 'relative',
          backgroundColor: commonStyles.palette.backgrounds.darkGrey,
          color: commonStyles.palette.colors.white,
          width: '100%',
          maxWidth: '345px',
          cursor: 'pointer',
          borderRadius: commonStyles.borders.borderRadius,
        },
      },
    },
    MuiCardMedia: {
      styleOverrides: {
        root: {
          height: '135px',
          width: '99px',
          objectFit: 'cover',
          marginTop: '25px',
          borderRadius: commonStyles.borders.borderRadius,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          '&:last-child': {
            paddingBottom: '0px',
          },
          padding: '9px',
        },
      },
    },
  },
});

export default theme;
