import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
const axios = require('axios');
import '../../css/crypto-pay-dialog.css';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCircleXmark, faGreaterThan, faLessThan } from '@fortawesome/free-solid-svg-icons';
import ClipLoader from 'react-spinners/ClipLoader';
import AnimatedSuccess from '../animated-success';
import CoinSelection from '../coin-selection';
import CopyButton from '../copy-button';
import QRCode from 'qrcode';
import Sockette from 'sockette';
import Lodash from 'lodash';

const CryptoPayDialog = ({ open, /*onComplete,*/ onClose, getPowerchat, captchaToken, initialCoin }) => {
  const baseUrl = process.env.BASE_URL.replace(/.*\/\//g, '');
  const { username } = useParams();
  const [success, setSuccess] = useState(false);
  const [failed, setFailed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [captchaError, setCaptchaError] = useState(false);
  const [badConversion, setBadConversion] = useState(false);
  const powerchat = useRef(getPowerchat());
  const [usdAmount, setUsdAmount] = useState(0);
  const [coinAmount, setCoinAmount] = useState(0);
  const [selectedCoin, setSelectedCoin] = useState(null);
  const [coinOptions, setCoinOptions] = useState([]);
  //const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectingCoin, setSelectingCoin] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [selectedQrCode, setSelectedQrCode] = useState(null);
  const [isBelowMinimum, setIsBelowMinimum] = useState(false);
  const [minAmount, setMinAmount] = useState(0);
  const [minAmountUsd, setMinAmountUsd] = useState(0);
  const paymentIds = useRef({});
  const coinAmounts = useRef({});
  const addresses = useRef({});
  const qrCodes = useRef({});
  const socket = useRef(null);

  useEffect(() => {
    const loadCoins = async () => {
      let response = await axios.get('/pubapi/crypto/coins');
      setCoinOptions(response.data.map(x => ({
        ...x,
        visible: true,
      })));
    };
    loadCoins();
    startWebsocket();
  }, []);

  useEffect(() => {
    if (coinOptions.length > 0) {
      if (initialCoin) {
        let option = coinOptions.find(x => x.code == initialCoin);
        if (option != null) {
          setSelectedCoin(option);
          return;
        }
      }
      setSelectedCoin(coinOptions.find(x => x.code == 'LTC'));
    }
  }, [coinOptions]);

  const receiveMessage = (data) => {
    if (Object.values(paymentIds.current).includes(data.paymentId.toString())) {
      if (data.success) {
        setSuccess(true);
        setTimeout(() => window.location.reload(), 5000);
      } else {
        setFailed(true);
      }
    }
  };

  const paymentLink = () => selectedCoin.name.toLowerCase() + ':' + selectedAddress;

  useEffect(() => {
    console.log(powerchat.current, getPowerchat(),
      Lodash.isEqual(powerchat.current, getPowerchat())
    );
    if (open && (selectedAddress == null || !Lodash.isEqual(powerchat.current, getPowerchat()))) {
      createPayment(selectedCoin);
    }
  }, [open, selectedAddress]);

  const createPayment = async (coin) => {
    try {
      setLoading(true);
      setIsBelowMinimum(false);
      setServerError(false);
      setBadConversion(false);
      setCaptchaError(false);
      let chat = getPowerchat();
      powerchat.current = chat;
      setUsdAmount(chat.amount);
      let response = await axios.post('/pubapi/crypto-pay', {
        amount: chat.amount,
        coin: coin.code,
        captchaToken,
        metadata: chat,
      });
      if (response.data.belowMinimum === true) {
        return belowMinimum(response.data.minAmount);
      }
      let { address } = response.data;
      setCoinAmount(response.data.coinAmount);
      coinAmounts.current[coin.code] = response.data.coinAmount;
      setSelectedAddress(address);
      addresses.current[coin.code] = address;
      paymentIds.current[coin.code] = response.data.paymentId;
      let qr = await QRCode.toDataURL(address);
      setSelectedQrCode(qr);
      qrCodes.current[coin.code] = qr;
    } catch (err) {
      console.error(err);
      if (err.response && err.response.data == 'Failed captcha') {
        setCaptchaError(true);
      }
      else if (err.response && err.response.status == 500) {
        switch (err.response.data) {
          case 'conversion':
            setBadConversion(true);
            break;
          default:
            setServerError(true);
            break;
        }
      }
    } finally {
      setLoading(false);
    }
  };

  const belowMinimum = (data) => {
    setIsBelowMinimum(true);
    setMinAmount(data['min_amount']);
    setMinAmountUsd(data['fiat_equivalent']);
  };

  const startWebsocket = () => {
    let sockette = new Sockette(
      `wss://${baseUrl}/${username.toLowerCase()}_pay`,
      {
        onopen: function (event) {
          event.target.send(`Remote client log - ${username} - Open paid chat`);
        },
        onmessage: function (event) {
          event.target.send(`Remote client log - ${username} - [message] Data received from server: ${event.data}`);
          if (event.data == 'pong') {
            return;
          }
          const data = JSON.parse(event.data);
          if (data.cryptoEvent) {
            receiveMessage(data.cryptoEvent);
          }
        },
        onclose: function (event) {
          if (event.wasClean) {
            console.log(`[close] Connection closed cleanly, code=${event.code} reason=${event.reason}`);
          } else {
            console.log('[close] Connection died');
          }
        },
        onerror: function (error) {
          console.log(`[error] ${error.message}`);
        },
      }
    );
    socket.current = sockette;
    setInterval(() => {
      sockette && sockette.send(`ping from: ${username} - Paid Chat`);
    }, 30 * 1000);
  };

  const onSelectedCoinChanged = (coin) => {
    setSelectingCoin(false);
    setSelectedCoin(coin);
    if (addresses.current[coin.code]) {
      setSelectedAddress(addresses.current[coin.code]);
      setSelectedQrCode(qrCodes.current[coin.code]);
      setCoinAmount(coinAmounts.current[coin.code]);
    } else {
      createPayment(coin);
    }
  };

  const usdRate = () => (usdAmount / coinAmount).toFixed(2);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: 'crypto-dialog' }}
    >
      <DialogTitle>
        <div className="dialog-title">
          <img src="/static/img/powerchat-full-text-logo.webp" />
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <FontAwesomeIcon icon={faTimes} />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="dialog-container">
          { selectingCoin ? (
            <div style={{overflow:'hidden'}}>
              <button className="select-coin" onClick={() => setSelectingCoin(false)}>
                <FontAwesomeIcon icon={faLessThan} color="ddd" size="lg" />
              </button>
              <CoinSelection
                options={coinOptions}
                selectedCoin={selectedCoin}
                onCoinSelected={onSelectedCoinChanged}
              />
            </div>
          ) : (
            <div className="dialog-container">
              <div className="select-container">
                <div className="title">Pay with</div>
                <button className="select-coin" onClick={() => setSelectingCoin(true)}>
                  {selectedCoin && selectedCoin.name}
                  <FontAwesomeIcon icon={faGreaterThan} size="lg" />
                </button>
              </div>
              <div className="powerchat-container">
                <div className="title">Powerchat</div>
                {(!loading && !!coinAmount && !!selectedCoin) ? (
                  <div>
                    <div className="amount-row">
                      <CopyButton text={coinAmount} size="lg" />
                      <div>{coinAmount} {selectedCoin.code}</div>
                    </div>
                    <div>1 {selectedCoin.code} = ${usdRate()} (USD)</div>
                  </div>
                ) : (<div><br/><br/></div>)}
              </div>
              <div className="tab-container">
                <span onClick={() => setSelectedTab(0)} className={"tab " + (selectedTab == 0 ? 'selected' : '')}>
                  Scan
                </span>
                <span onClick={() => setSelectedTab(1)} className={"tab " + (selectedTab == 1 ? 'selected' : '')}>
                  Copy
                </span>
              </div>
              { serverError ? (
                <div className="address-container failed">
                  Server unavailable.<br/>
                  Try again later.
                </div>
              ) : badConversion ? (
                <div className="address-container failed">
                  This coin is not convertible to the destination wallet.
                </div>
              ) : captchaError ? (
                <div className="address-container failed">
                  Captcha expired.<br/>
                  Please close and reopen.
                </div>
              ) : isBelowMinimum ? (
                <div className="belowmin-container">
                  <div>
                    Payment amount is below minimum. Increase amount or select another coin.
                  </div>
                  <div className="minamount-container">
                    <div>Min Amount:</div>
                    <div>{minAmount} {selectedCoin.code} <br/>
                    ${minAmountUsd.toFixed(2)}
                    </div>
                  </div>
                </div>
              ) : success ? (
                <div className="address-container">
                  <AnimatedSuccess />&nbsp;
                  Payment successful!
                </div>
              ) : failed ? (
                <div className="address-container failed">
                  <FontAwesomeIcon icon={faCircleXmark} color="#ff0000" />&nbsp;
                  Failed
                </div>
              ) : selectedTab == 0 ? (
                <div className="address-container">
                  <ClipLoader color="#fff" loading={loading} size={20} />
                  {!loading && <img src={selectedQrCode} />}
                </div>
              ) : !loading ? (
                <div className="address-container">
                  <span>Address</span>
                  <div className="flex-row">
                    <CopyButton text={selectedAddress} size="lg" />
                    <input type="text" readOnly={true} value={selectedAddress} />
                  </div>
                  <span>Payment Link</span>
                  <div className="flex-row">
                    <CopyButton text={paymentLink()} size="lg" />
                    <input type="text" readOnly={true} value={paymentLink()} />
                  </div>
                </div>
              ) : (
                <div className="address-container">
                  <ClipLoader color="#fff" loading={loading} size={20} />
                </div>
              )}
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default CryptoPayDialog;
