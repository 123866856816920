import React from 'react';
import HeroSection from '../components/hero-section';
import ContentBoxes from '../components/content-boxes';
import Description from '../components/description';

const featuresContentBoxes = [
  {
    header: 'Real Time Interactions',
    content: <p>Receive Text-To-Speech (TTS) messages from your audiences in real time. Discover the exciting various voice patterns Powerchat has to offer, and say hello to your new audience driven Co-Host. Turn your dead air into a live audience driven jukebox with Media Powerchats, or receive video react content directly from your viewers. Play timely audio and video clips on your stream using the Powerchat Soundboard, powered by <a href="https://www.voicy.network/">Voicy</a>. Choose from over 500,000 sounds and clips, or upload your own clips to Voicy directly and use them in Powerchat.</p>,
    imgUrl: '/static/img/features-page-assets/PC_Features-02.webp',
  },
  {
    header: 'Rich Integrations',
    content: <p>Integrate with all of your favorite livestreaming sites. Combine your live chats into one overlay, receive platform specific alerts, get notified when you receive a Twitch Prime Subscription, YouTube Superchat, and even when someone casts a Trovo Spell.</p>,
    imgUrl: '/static/img/features-page-assets/PC_Features-03.webp',
  },
  {
    header: 'Get Discovered',
    content: <p>Become part of Powerchat&apos;s growing roster of world-class livestreamers, influencers, and content creators. The Powerchat Network allows users to discover your streams, interact with your content, and even for you to tap into a completely new audience of avid community members and fans.</p>,
    imgUrl: '/static/img/features-page-assets/PC_Features-04.webp',
  },
  {
    header: 'Build a Community',
    content: <p>Build a following of viewers, fans, and subscribers on Powerchat. When a Powerchat user follows your Powerchat page, they&apos;ll automatically receive email notifications when you go live on any of your linked platforms. Build a subscriber base and create a loyal following who can follow you to any streaming platform of your choice making your content truly platform independent.</p>,
    imgUrl: '/static/img/features-page-assets/PC_Features-05.webp',
  },
];

const Features = () => {
  return (
    <div className="page-container content-box-page-container">
      <HeroSection imgUrl="/static/img/features-page-assets/PC_Features-01.webp">
        <p>Level up your streaming</p>
        <br />
        <p>
          career with<b>powerchat</b>
        </p>
      </HeroSection>
      <Description>
        <p>
          Go Live.<b>Get Paid</b>
        </p>
        <p className="description-text">
          Get paid for your livestreaming and video content by utilizing
          Powerchat for paid chats (Powerchats) and Subscribers. Secure and
          trusted major payment gateway partners include Square and various Cryptocurrencies!
        </p>
      </Description>
      <ContentBoxes contentBoxes={featuresContentBoxes} />
    </div>
  );
};

export default Features;
