//Disable eslint checking for deprecated ReactDOM.render (as of React 18)
/* eslint react/no-deprecated: 0 */
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';
import history from './history';
import App from './app';

ReactDOM.render(
  <Router history={history}>
    <App />
  </Router>,
  document.getElementById('app')
);
