import React from 'react';
import '../css/user-agreement.css';

const TermsOfService = () => (
  <div className="page-container">
    <div className="user-agreement-container">
      <h3>Terms of Service</h3>

      <h4>Introduction</h4>
      <p>
        These Terms of Use are an Agreement between You, the User (“You”) and
        Powerchat.live. The Services are offered to you conditioned on your
        acceptance without modification of the terms, conditions, and notices
        contained herein. Your use of the Services constitutes your agreement to
        all such terms, conditions, and notices. If you do not agree to any of
        these terms, please do not use the Services.
      </p>

      <h4>Links to Third Party Sites</h4>
      <p>
        The Services contain links to other sites or services (&quot;Third Party
        Sites&quot;), including but not limited to PayPal.com, YouTube.com, and
        Robotstreamer.com. The Third Party Sites are not under the control of
        Powerchat.live, and we are not responsible for the contents of any Third
        Party Site, including without limitation any link contained in a Third
        Party Site, any changes or updates to a Third Party Site, or for any
        information transmitted by Powerchat.live to a Third Party Site. The
        inclusion of any link to a Third Party Site does not imply endorsement
        by Powerchat.live of the site or any association with its operators.
      </p>

      <h4>Integration with Third Party Sites</h4>
      <p>
        By choosing to integrate your Powerchat.live account with your account(s) on Third Party Sites,
        or using the services of Third Party Sites via Powerchat.live,
        you agree to the terms of service of those Third Party Sites. You may read those
        terms of service through the following links:
        <ul>
          <li><a href="https://www.youtube.com/t/terms">YouTube Terms of Service</a></li>
          <li><a href="https://payments.google.com/payments/apis-secure/u/0/get_legal_document?ldo=0&ldt=googlepaytos&ldl=und">Google Pay Terms of Service</a></li>
          <li><a href="https://trovo.live/policy/terms-of-service.html">Trovo Terms of Service</a></li>
          <li><a href="https://robotstreamer.com/policy.html">Robotstreamer Terms of Service</a></li>
          <li><a href="https://streamlabs.com/terms">Streamlabs Terms of Service</a></li>
          <li><a href="https://squareup.com/us/en/legal/general/ua">Square Terms of Service</a></li>
          <li><a href="https://www.paypal.com/us/legalhub/useragreement-full">PayPal Terms of Service</a></li>
        </ul>
      </p>

      <h4>No Unlawful or Prohibited Uses</h4>
      <p>
        As a condition of your use of the Services, you warrant that you will
        not use the Services for any purpose that is unlawful or prohibited by
        these terms, conditions, and notices. You agree that when using the
        Services, you will not: - Defame, abuse, harass, stalk, threaten or
        otherwise violate the legal rights (such as rights of privacy and
        publicity) of others. Publish, post, upload, distribute or disseminate
        any inappropriate, profane, defamatory, infringing, obscene, indecent or
        unlawful topic, name, material or information. - Engage in any illegal
        activity, adult content, or hate speech. - Upload files that
        contain software or other material protected by intellectual property
        laws (or by rights of privacy of publicity) unless you own or control
        the rights thereto or have received all necessary consents. - Upload
        files that contain viruses, corrupted files, or any other similar
        software or programs that may damage the operation of another&apos;s
        computer. - Advertise or offer to sell or buy any goods or services. -
        Use the Services in any manner which could damage, disable, overburden,
        or impair the Services or interfere with any other party&apos;s use and
        enjoyment of the Services. - Violate any code of conduct or other
        guidelines which may be applicable for any particular Service. - Obtain
        or attempt to obtain any materials or information through any means not
        intentionally made available or provided for through the Services by
        Powerchat.live. - Violate any applicable laws or regulations.
      </p>

      <h4>Monitoring of Communications</h4>
      <p>
        Powerchat has no obligation to monitor the Services. However, Powerchat
        reserves the right to review any communications transmitted using the
        Service and to remove any materials in its sole discretion with may be
        unlawful, and/or to monitor for security purposes. Powerchat reserves
        the right at all times to disclose any information as necessary to
        satisfy any applicable law, regulation, legal process or governmental
        request, or to edit, refuse to post or to remove any information or
        materials, in whole or in part, in Powerchat, sole discretion, as
        further described in the Privacy Policy.
      </p>

      <h4>Submissions Provided to or Posted Using the Services</h4>
      <p>
        Powerchat.live does not claim ownership of the materials you provide,
        post, upload, input, or submit to the Services (collectively
        &quot;Submissions&quot;). However, by posting, uploading, inputting, providing, or
        submitting your Submission you are granting Powerchat.live, its
        affiliated companies, and necessary sub-licensees, permission to use
        your Submission in connection with the operation of their businesses,
        including, without limitation, the rights to: copy, distribute,
        transmit, publicly display, publicly perform, reproduce, edit, translate
        and reformat your Submission. You grant Powerchat.live a perpetual,
        paid-up, irrevocable, worldwide, transferrable license to use and create
        derivative works from any Submission which constitutes a suggestion for
        improvement to any Powerchat.live Services or services. No compensation
        will be paid with respect to the use of your Submission, as provided
        herein. Powerchat.live is under no obligation to post or use any
        Submission you may provide and may remove any Submission at any time in
        Powerchat.live&apos;s sole discretion. By posting, uploading, inputting,
        providing or submitting your Submission you warrant and represent that
        you own or otherwise control all of the rights to your Submission as
        described in this section including, without limitation, all the rights
        necessary for you to provide, post, upload, input or submit the
        Submissions.
      </p>

      <h4>Service Disclaimers</h4>
      <p>
        Without limiting Powerchat.live rights or any other disclaimers in these
        Terms, You understand and agree that Powerchat.live specifically
        disclaims all responsibility, and shall not be liable in any way, for: -
        Unforeseen attacks, hacks, exploits, data leaks, delays of service, or
        downtime - Taxes related to individual users - User related errors, not
        limited to, but including typos and incorrect information
      </p>

      <h4>LIABILITY DISCLAIMER</h4>
      <p>
        Powerchat.live, ITS PARENT AND AFFILIATES, AND/OR ITS SUPPLIERS MAKE NO
        REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY,
        TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS,
        SERVICES AND RELATED GRAPHICS CONTAINED ON THE SERVICES FOR ANY PURPOSE.
        TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SERVICES ARE
        PROVIDED &quot;AS IS&quot; WITHOUT WARRANTY OR CONDITION OF ANY KIND.
        Powerchat.live, ITS PARENT AND AFFILIATES, AND/OR ITS SUPPLIERS HEREBY
        DISCLAIM ALL WARRANTIES AND CONDITIONS WITH REGARD TO THIS INFORMATION,
        SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS, INCLUDING ALL IMPLIED
        WARRANTIES OR CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR
        PURPOSE, TITLE AND NON-INFRINGEMENT. TO THE MAXIMUM EXTENT PERMITTED BY
        APPLICABLE LAW, IN NO EVENT SHALL Powerchat.live, ITS PARENT AND
        AFFILIATE, AND/OR ITS SUPPLIERS BE LIABLE FOR ANY DIRECT, INDIRECT,
        PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL DAMAGES OR ANY DAMAGES
        WHATSOEVER INCLUDING, WITHOUT LIMITATION, DAMAGES FOR LOSS OF USE, DATA
        OR PROFITS, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OR
        PERFORMANCE OF THE SERVICES, WITH THE DELAY OR INABILITY TO USE THE
        SERVICES, THE PROVISION OF OR FAILURE TO PROVIDE SERVICES, OR FOR ANY
        INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS OBTAINED
        THROUGH THE SERVICES, OR OTHERWISE ARISING OUT OF THE USE OF THE
        SERVICES, WHETHER BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY
        OR OTHERWISE, EVEN IF Powerchat.live, ITS PARENT AND AFFILIATES, AND/OR
        ANY OF ITS SUPPLIERS HAS BEEN ADVISED OF THE POSSIBILITY OF DAMAGES.
      </p>

      <h4>Termination or Restriction of Access</h4>
      <p>
        Powerchat.live reserves the right, in its sole discretion, to terminate
        your access to the Services and the related services or any portion
        thereof at any time, without notice. In addition, your rights
        automatically terminate if You in any way breach any of these Terms.
      </p>

      <h4>General Provisions</h4>
      <p>
        This agreement is governed by the laws of the Country of the United
        States and you hereby consent to the exclusive jurisdiction and venue of
        courts of the United States, in all disputes arising out of or relating
        to the use of the Services. You agree that no joint venture,
        partnership, employment, or agency relationship exists between you and
        Powerchat.live as a result of this agreement or use of the Services. If
        any part of this agreement is determined to be invalid or unenforceable
        pursuant to applicable law including, but not limited to, the warranty
        disclaimers and liability limitations set forth above, then the invalid
        or unenforceable provision will be deemed superseded by a valid,
        enforceable provision that most closely matches the intent of the
        original provision and the remainder of the agreement shall continue in
        effect. Unless otherwise specified herein, this agreement constitutes
        the entire agreement between the user and Powerchat.live with respect to
        the Services and it supersedes all prior or contemporaneous
        communications and proposals, whether electronic, oral or written,
        between the user and Powerchat.live with respect to the Services.
      </p>

      <h4>Modification of the Terms of Use</h4>
      <p>
        Powerchat.live reserves the right to change the terms, conditions, and
        notices under which the Services are offered, including but not limited
        to the charges associated with the use of the Services.
      </p>

      <h4>Fees</h4>
      <p>
        Powerchat LLC may charge a service fee to each transaction on the
        platform in addition to the payment processor fees. Powerchat LLC aims
        to have a total fee rate (excluding any flat charges from the payment
        processors) of approximately 10%. As such Powerchat LLC may charge fees
        as follows: - Square: 7.1% Powerchat
        LLC Fee - Powerchat Crypto: 10% Powerchat LLC Fee These fees are subject to change
        at any time and without notice. These are target fees and may not
        reflect our current fees. Fees will not exceed these percentages unless
        reflected here first.
      </p>

      <h4>Copyright</h4>
      <p>
        Unless otherwise indicated, all contents of the Services are: Copyright
        2024 by Powerchat.live. All rights reserved.
      </p>

      <h4>Contact</h4>
      <p>
        If you have questions or concerns about these Terms, you can contact
        contact@powerchat.live
      </p>
    </div>
  </div>
);

export default TermsOfService;
