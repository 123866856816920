import React from 'react';
import EmoteWall from '../components/emote-wall.jsx';

const EmoteWallOverlay = () => {
  return (
    <EmoteWall />
  );
};

export default EmoteWallOverlay;
