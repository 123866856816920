import React from 'react';
import usePagination, { DOTS } from '../hooks/usePagination';
import '../css/pagination.css';

const Pagination = ({
  onPageChange,
  totalCount,
  siblingCount = 1,
  currentPage,
  pageSize,
}) => {
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  // If there are less than 2 times in pagination range we shall not render the component
  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <ul className="pagination-container pagination-bar">
      {/* Left navigation arrow */}
      <li
        className={`pagination-item ${currentPage === 1 && 'disabled'}`}
        onClick={onPrevious}
      >
        <div className="arrow left" />
      </li>

      {paginationRange.map((pageNumber, idx) => (
        <div key={pageNumber + String(idx)}>
          {/* If the pageItem is a DOT, render the DOTS unicode character */}
          {pageNumber === DOTS ? (
            <li className="pagination-item dots">{DOTS}</li>
          ) : (
            <li
              className={`pagination-item ${
                pageNumber === currentPage && 'selected'
              }`}
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </li>
          )}
        </div>
      ))}

      {/*  Right Navigation arrow */}
      <li
        className={`pagination-item ${currentPage === lastPage && 'disabled'}`}
        onClick={onNext}
      >
        <div className="arrow right" />
      </li>
    </ul>
  );
};

export default Pagination;
