
/* * voiceTypeId:
 * 1 - Default
 * 2 - Newscaster
 * 3 - Whisper
 * 4 - Pitch Altered
 * 9 - Speak-TTS Voices
 * 10 - Fake You
 * */
const sortVoiceOptions = (voiceOptions) => {
  return voiceOptions.sort((a, b) => {
    if (a.value === 'Default') return -1;
    if (b.value === 'Default') return 1;
    return a.name.localeCompare(b.name);
  });
};

const ttsFreeVoiceOptions = sortVoiceOptions([
  { value: 'Matthew', name: 'Matthew', label: 'Matthew\nUS Male', voiceTypeId: 1, icon: 'neural' },
  { value: 'Matthew Newscaster', name: 'Matthew', label: 'Matthew\nUS Male', voiceTypeId: 2, icon: 'newscaster' },
  { value: 'Matthew Whisper', name: 'Matthew', label: 'Matthew\nUS Male', voiceTypeId: 3, icon: 'whisper' },
  { value: 'Joey', name: 'Joey', label: 'Joey\nUS', voiceTypeId: 1, icon: 'male' },
  { value: 'Joanna', name: 'Joanna', label: 'Joanna\nUS', voiceTypeId: 1, icon: 'female' },
  { value: 'Kendra', name: 'Kendra', label: 'Kendra\nUS', voiceTypeId: 1, icon: 'female' },
  { value: 'Kimberly', name: 'Kimberly', label: 'Kimberly\nUS', voiceTypeId: 1, icon: 'female' },
  { value: 'Salli', name: 'Salli', label: 'Salli\nUS', voiceTypeId: 1, icon: 'female' },
  { value: 'Justin', name: 'Justin', label: 'Justin\nUS Male Child', voiceTypeId: 1, icon: 'neural' },
  { value: 'Ivy', name: 'Ivy', label: 'Ivy\nUS Female Child', voiceTypeId: 1, icon: 'neural' },
  { value: 'Brian', name: 'Brian', label: 'Brian\nBritish Male', voiceTypeId: 1, icon: 'neural' },
  { value: 'Amy', name: 'Amy', label: 'Amy\nBritish Female', voiceTypeId: 1, icon: 'neural' },
  { value: 'Amy Newscaster', name: 'Amy', label: 'Amy\nBritish Female', voiceTypeId: 2, icon: 'newscaster' },
  { value: 'Emma', name: 'Emma', label: 'Emma\nBritish', voiceTypeId: 1, icon: 'female' },
  { value: 'Russell', name: 'Russell', label: 'Russell\nAustralian', voiceTypeId: 1, icon: 'male' },
  { value: 'Nicole', name: 'Nicole', label: 'Nicole\nAustralian', voiceTypeId: 1, icon: 'female' },
  { value: 'Nicole Whisper', name: 'Nicole', label: 'Nicole\nAustralian Female', voiceTypeId: 3, icon: 'whisper' },
  { value: 'Olivia', name: 'Olivia', label: 'Olivia\nAustralian Female', voiceTypeId: 1, icon: 'neural' },
  { value: 'Ayanda', name: 'Ayanda', label: 'Ayanda\nSouth African Female', voiceTypeId: 1, icon: 'neural' },
  { value: 'Geraint', name: 'Geraint', label: 'Geraint\nWelsh', voiceTypeId: 1, icon: 'male' },
  { value: 'Mathieu', name: 'Mathieu', label: 'Mathieu\nFrench', voiceTypeId: 1, icon: 'male' },
  { value: 'Chantal', name: 'Chantal', label: 'Chantal\nFrench', voiceTypeId: 1, icon: 'female' },
  { value: 'Tatyana', name: 'Tatyana', label: 'Tatyana\nRussian', voiceTypeId: 1, icon: 'female' },
  { value: 'Hans', name: 'Hans', label: 'Hans\nGerman', voiceTypeId: 1, icon: 'male' },
  { value: 'Marlene', name: 'Marlene', label: 'Marlene\nGerman', voiceTypeId: 1, icon: 'female' },
  { value: 'Elin', name: 'Elin', label: 'Elin\nSwedish', voiceTypeId: 1, icon: 'female' },
  { value: 'Miguel', name: 'Miguel', label: 'Miguel\nSpanish', voiceTypeId: 1, icon: 'male' },
  { value: 'Lucia', name: 'Lucia', label: 'Lucia\nSpanish', voiceTypeId: 1, icon: 'female' },
  { value: 'Lucia', name: 'Mia', label: 'Mia\nSpanish', voiceTypeId: 1, icon: 'female' },
  { value: 'Raveena', name: 'Raveena', label: 'Raveena\nIndian', voiceTypeId: 1, icon: 'female' },
  { value: 'Zeina', name: 'Zeina', label: 'Zeina\nArabic', voiceTypeId: 1, icon: 'female' },
  { value: 'Takumi', name: 'Takumi', label: 'Takumi\nJapanese', voiceTypeId: 1, icon: 'male' },
  { value: 'Mizuki', name: 'Mizuki', label: 'Mizuki\nJapanese', voiceTypeId: 1, icon: 'female' },
  { value: 'Seoyeon', name: 'Seoyeon', label: 'Seoyeon \nKorean', voiceTypeId: 1, icon: 'female' },
  { value: 'Zhiyu', name: 'Zhiyu', label: 'Zhiyu\nChinese', voiceTypeId: 1, icon: 'female' },
  { value: 'Zhiyu Whisper', name: 'Zhiyu', label: 'Zhiyu\nChinese Female', voiceTypeId: 3, icon: 'whisper' },
  { value: 'Zhiyu Altered', name: 'Zhiyu', label: 'Zhiyu\nChinese Female', voiceTypeId: 4, icon: 'pitch' },
  { value: 'Hiujin', name: 'Hiujin', label: 'Hiujin\nCantonese Female', voiceTypeId: 1, icon: 'neural' },
  { value: 'Elon Musk', name: 'Elon Musk', label: 'Elon Musk', voiceTypeId: 10, icon: 'personality' },
  { value: 'Gilbert Gottfried', name: 'Gilbert Gottfried', label: 'Gilbert Gottfried', voiceTypeId: 10, icon: 'star' },
  { value: 'Homer Simpson', name: 'Homer Simpson', label: 'Homer Simpson', voiceTypeId: 10, icon: 'character' },
  { value: 'Patrick Stewart', name: 'Patrick Stewart', label: 'Patrick Stewart', voiceTypeId: 10, icon: 'star' },
  { value: 'Mr. Krabs', name: 'Mr. Krabs', label: 'Mr. Krabs', voiceTypeId: 10, icon: 'character' },
  { value: 'Morty Smith', name: 'Morty Smith', label: 'Morty Smith', voiceTypeId: 10, icon: 'character' },
  { value: 'Puss in Boots', name: 'Puss in Boots', label: 'Puss in Boots', voiceTypeId: 10, icon: 'character' },
  { value: 'Shrek', name: 'Shrek', label: 'Shrek', voiceTypeId: 10, icon: 'character' },
  { value: 'Andrew Tate', name: 'Andrew Tate', label: 'Andrew Tate', voiceTypeId: 10, icon: 'personality' },
  { value: 'Yoda', name: 'Yoda', label: 'Yoda', voiceTypeId: 10, icon: 'character' },
  { value: 'Ben Shapiro', name: 'Ben Shapiro', label: 'Ben Shapiro', voiceTypeId: 10, icon: 'political' },
  { value: 'Alex Jones', name: 'Alex Jones', label: 'Alex Jones', voiceTypeId: 10, icon: 'political' },
  { value: 'Donald Trump', name: 'Donald Trump', label: 'Donald Trump', voiceTypeId: 10, icon: 'political'},
  { value: 'SpongeBob', name: 'SpongeBob', label: 'SpongeBob', voiceTypeId: 10, icon: 'character'},
  { value: 'Peter Griffin', name: 'Peter Griffin', label: 'Peter Griffin', voiceTypeId: 10, icon: 'character' },
  { value: 'Mr. Beast', name: 'Mr. Beast', label: 'Mr. Beast', voiceTypeId: 10, icon: 'personality' },
  { value: 'Tucker Carlson', name: 'Tucker Carlson', label: 'Tucker Carlson', voiceTypeId: 10, icon: 'political' },
  { value: 'PewDiePie', name: 'PewDiePie', label: 'PewDiePie', voiceTypeId: 10, icon: 'personality' },
  { value: 'Walter White', name: 'Walter White', label: 'Walter White', voiceTypeId: 10, icon: 'character' },
  { value: 'Joe Biden', name: 'Joe Biden', label: 'Joe Biden', voiceTypeId: 10, icon: 'political' },
  { value: 'Will Smith', name: 'Will Smith', label: 'Will Smith', voiceTypeId: 10, icon: 'star' },
  { value: 'Arnold Schwarzenegger', name: 'Arnold Schwarzenegger', label: 'Arnold Schwarzenegger', voiceTypeId: 10, icon: 'star' },
  { value: 'Joker', name: 'Joker', label: 'Joker', voiceTypeId: 10, icon: 'character' },
  { value: 'Kermit the Frog', name: 'Kermit the Frog', label: 'Kermit the Frog', voiceTypeId: 10, icon: 'character' },
  { value: 'Louis Theroux', name: 'Louis Theroux', label: 'Louis Theroux', voiceTypeId: 10, icon: 'personality' },
  { value: 'Morgan Freeman', name: 'Morgan Freeman', label: 'Morgan Freeman', voiceTypeId: 10, icon: 'star' },
  { value: 'Patrick Star', name: 'Patrick Star', label: 'Patrick Star', voiceTypeId: 10, icon: 'character' },
  { value: 'Ye West', name: 'Ye West', label: 'Ye West', voiceTypeId: 10, icon: 'star' },
  { value: 'Bernie Sanders', name: 'Bernie Sanders', label: 'Bernie Sanders', voiceTypeId: 10, icon: 'political' },
  { value: 'Dale Gribble', name: 'Dale Gribble', label: 'Dale Gribble', voiceTypeId: 10, icon: 'character' },
  { value: 'Duke Nukem', name: 'Duke Nukem', label: 'Duke Nukem', voiceTypeId: 10, icon: 'character' },
  { value: 'Eric Cartman', name: 'Eric Cartman', label: 'Eric Cartman', voiceTypeId: 10, icon: 'character' },
  { value: 'Forrest Gump', name: 'Forrest Gump', label: 'Forrest Gump', voiceTypeId: 10, icon: 'character' },
  { value: 'GLaDOS', name: 'GLaDOS', label: 'GLaDOS', voiceTypeId: 10, icon: 'character' },
  { value: 'Gordon Ramsey', name: 'Gordon Ramsey', label: 'Gordon Ramsey', voiceTypeId: 10, icon: 'star' },
  { value: 'Hank Hill', name: 'Hank Hill', label: 'Hank Hill', voiceTypeId: 10, icon: 'character' },
  { value: 'Hillary Clinton', name: 'Hillary Clinton', label: 'Hillary Clinton', voiceTypeId: 10, icon: 'political' },
  { value: 'Lamar Davis', name: 'Lamar Davis', label: 'Lamar Davis', voiceTypeId: 10, icon: 'character' },
  { value: 'Mario', name: 'Mario', label: 'Mario', voiceTypeId: 10, icon: 'character' },
  { value: 'Mickey Mouse', name: 'Mickey Mouse', label: 'Mickey Mouse', voiceTypeId: 10, icon: 'character' },
  { value: 'Ned Flanders', name: 'Ned Flanders', label: 'Ned Flanders', voiceTypeId: 10, icon: 'character' },
  { value: 'Rick Sanchez', name: 'Rick Sanchez', label: 'Rick Sanchez', voiceTypeId: 10, icon: 'character' },
  { value: 'Sonic the Hedgehog', name: 'Sonic the Hedgehog', label: 'Sonic the Hedgehog', voiceTypeId: 10, icon: 'character' },
  { value: 'Stone Cold Steve Austin', name: 'Stone Cold Steve Austin', label: 'Stone Cold Steve Austin', voiceTypeId: 10, icon: 'star' },
  { value: 'Tom Cruise', name: 'Tom Cruise', label: 'Tom Cruise', voiceTypeId: 10, icon: 'star' },
]);

const ttsSubVoiceOptions = sortVoiceOptions([
  //{ value: 'Tom Cruise', name: 'Tom Cruise', label: 'Tom Cruise', voiceTypeId: 10, icon: 'star', subsOnly: true }, //Example of a sub only voice
]);

const ttsVoiceOptions = [
  {
    label: 'Free To Use',
    options: ttsFreeVoiceOptions
  },
  {
    label: 'Sub Only',
    options: ttsSubVoiceOptions
  }
];

const ttsVoiceOptionsDisableSubs = [
  {
    label: 'Free To Use',
    options: ttsFreeVoiceOptions
  },
  {
    label: 'Sub Only',
    options: ttsSubVoiceOptions.map(x => { return { ...x, isDisabled: true }})
  }
];

const findTtsVoice = (voice) => {
  const foundVoice = ttsVoiceOptions
    .flatMap(x => x.options)
    .find(x => x.value === voice);
  return foundVoice || ttsFreeVoiceOptions.find(x => x.value === 'Default');
};

module.exports = {
  ttsFreeVoiceOptions,
  ttsSubVoiceOptions,
  ttsVoiceOptions,
  ttsVoiceOptionsDisableSubs,
  findTtsVoice,
};
