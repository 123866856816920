import React from 'react';
import '../css/home.css';
import useAuth from '../hooks/useAuth';
// import FeaturedStreamers from '../components/featured-streamers';

const Home = () => {
  const { user } = useAuth();

  const getStarted = () => {
    window.location.href = !user ? '/signup' : 'creator-dashboard';
  };

  return (
    <div className="page-container">
      <div className="home-container">
        <div
          className="image-container"
          style={{ backgroundImage: 'url(/static/img/PC_Home_Header.webp)' }}
        >
          <div className="overlay-text corner-bottom-left">
            Helping
            <br />
            streamers
            <br />
            <b>get paid.</b>
            <br />
            <button type="button" onClick={getStarted}>
              GET STARTED
            </button>
          </div>
        </div>
        <div className="grad-background">
          <div className="home-section overlay-text centered">
            <b>Welcome to the Next Generation of Stream Monetization.</b>
            <p>
              Say hello to your new audience driven co-host with real-time TTS
              and Media Powerchats. Access robust integrations with your
              favorite streaming platforms like Youtube, Twitch, and Trovo. Get
              paid with major platform integrations like Square, or
              take your streaming career onto the blockchain with Crypto
              monetization options.
            </p>
          </div>
        </div>
        <div className="home-section image-row reverse">
          <img
            style={{ width: '80%' }}
            src="/static/img/PC_Home_Streamer-1.webp"
          />
          <div className="text-container">
            <div className="title">FEATURES</div>
            <b>
              Enhance your livestream
              <br />
              with Powerchat.
            </b>
            <p>
              Bring new life to your content with interactive TTS and Media
              Powerchats that transform your stream into a live spectacle with
              your most loyal fans in real time.
            </p>
            <div className="flex-row">
              <ul>
                <li>Paid chats</li>
                <li>Text-to-speech</li>
                <li>Media</li>
                <li>Overlays</li>
              </ul>
              <ul>
                <li>Integrations</li>
                <li>Subscriptions</li>
                <li>Crypto Transactions</li>
              </ul>
            </div>
            <div className="btn-container">
              {/*<button type="button" onClick={() => window.location.href = '/features'}>LEARN MORE</button>*/}
            </div>
          </div>
        </div>
        <div className="home-section image-row">
          <img
            style={{ width: '80%' }}
            src="/static/img/PC_Home_Streamer-2.webp"
          />
          <div className="text-container">
            <div className="title">PRICING</div>
            <b>
              Powerchat.live is free
              <br />
              to use!
            </b>
            <p>
              Accept payments immediately, no subscription necessary.
              <br />
              Support for major payment gateways accepted.
            </p>
            {/*<button type="button" onClick={() => window.location.href = '/pricing'}>PRICING DETAILS</button>*/}
          </div>
        </div>
        <div className="home-section image-row reverse white">
          <img src="/static/img/PC_Home_Tutorials.webp" className="landscape" />
          <div className="text-container">
            <div className="title">LEARN</div>
            <b>
              Explore everything
              <br />
              Powerchat.live has
              <br />
              to offer.
            </b>
            <p>
              Learn all the basics of streaming. Get started fast with
              Powerchat.
            </p>
            {/*<button type="button" onClick={() => window.location.href = '/learn'}>WATCH TUTORIALS</button>*/}
          </div>
        </div>
        {/* <FeaturedStreamers /> */}
      </div>
    </div>
  );
};

export default Home;
