import React from 'react';

const VerticalLeftProgressBar = ({
  goalName,
  value,
  max,
  fontSize = 16,
  length = '100%',
  widthRatio = 1,
  primaryColor = '#375a7f',
  secondaryColor = '#444',
  textColor = '#fff',
  backgroundColor = 'transparent',
  uid = '',
  isMonetary = false,
}) => {
  const actualFontSize = fontSize / 16;
  const widthInEm = `${actualFontSize * widthRatio}rem`;
  const numericValue = typeof value === 'number' ? value : Number.parseFloat(value);
  const numericMax = typeof max === 'number' ? max : Number.parseFloat(max);

  if (isNaN(numericValue) || isNaN(numericMax)) {
    return null;
  }

  let percentageValue = (numericValue / numericMax) * 100;
  if (!isFinite(percentageValue)) {
    percentageValue = 100;
  }

  const valueDisplay = isMonetary ? `$${numericValue.toFixed(2)}` : numericValue.toFixed(0);
  const maxDisplay = isMonetary ? `$${numericMax.toFixed(2)}` : numericMax.toFixed(0);

  return (
    <div className={`progress-container-vertical-left${uid}`} style={{
      height: `calc(${length} - 30px)`, display: 'flex', alignItems: 'flex-end',
      backgroundColor,
      padding: '15px',
    }}>
      <style>{`
        .text-container-vertical-left${uid} {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          height: 100%;
          margin-right: 4px;
          font-weight: bold;
        }
        .text-vertical-left${uid} {
          color: ${textColor};
          text-align: right;
          font-size: ${actualFontSize}rem;
        }
        .progress-bar-vertical-left${uid} {
          width: ${widthInEm};
          background-color: ${primaryColor};
          border-radius: ${widthInEm};
          position: relative;
          overflow: hidden;
          height: 100%;
        }
        .progress-vertical-left${uid} {
          width: 100%;
          background-color: ${secondaryColor};
          border-radius: ${widthInEm};
          transition: height 0.3s ease;
          position: absolute;
          bottom: 0;
        }
      `}</style>
      <div className={`text-container-vertical-left${uid}`}>
        <div className={`text-vertical-left${uid}`}>{goalName}</div>
        <div className={`text-vertical-left${uid}`}>{isNaN(percentageValue) ? '0' : percentageValue.toFixed(0)}%</div>
        <div className={`text-vertical-left${uid}`}>
          {`${valueDisplay} / ${maxDisplay}`}
        </div>
      </div>
      <div className={`progress-bar-vertical-left${uid}`}>
        <div
          className={`progress-vertical-left${uid}`}
          style={{ height: `${percentageValue}%` }}
        />
      </div>
    </div>
  );
};

export default VerticalLeftProgressBar;
