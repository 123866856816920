import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../css/soundboard.css';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import SoundboardItem from './soundboard-item.jsx';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Card from '@mui/material/Card';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import CardMedia from '@mui/material/CardMedia';
import Slider from './slider';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Autocomplete from '@mui/material/Autocomplete';
import AddBoxIcon from '@mui/icons-material/AddBox';
import DeleteIcon from '@mui/icons-material/Delete';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import Tooltip from '@mui/material/Tooltip';
import CloseIcon from '@mui/icons-material/Close';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import SettingsIcon from '@mui/icons-material/Settings';
import ToggleSwitch from './toggle-switch';
import { ThemeProvider } from '@mui/material/styles';
import theme from '../static/theme';
import sbSearchTheme from '../static/sbSearchTheme';
import DraggableSoundboardList from './draggable-soundboard-list.jsx';
import CommonAlert from './common-alert.jsx';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

const Soundboard = ({ user, isMod = false }) => {
  const [soundClips, setSoundClips] = useState([]);
  const [soundGIFs, setSoundGIFs] = useState([]);
  const [searchTermClips, setSearchTermClips] = useState('');
  const [searchTermGIFs, setSearchTermGIFs] = useState('');
  const [searchType, setSearchType] = useState('clips');
  const [userSoundboards, setUserSoundboards] = useState([]);
  const [selectedSoundboard, setSelectedSoundboard] = useState(null);
  const [newSoundboardName, setNewSoundboardName] = useState('');
  const [editSoundboardName, setEditSoundboardName] = useState('');
  const [editSoundboardDescription, setEditSoundboardDescription] = useState('');
  const [soundboardSounds, setSoundboardSounds] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [isReorderDialogOpen, setIsReorderDialogOpen] = useState(false);
  const [addSoundboardModalOpen, setAddSoundboardModalOpen] = useState(false);
  const [editSoundboardModalOpen, setEditSoundboardModalOpen] = useState(false);
  const [sortDirection, setSortDirection] = useState('asc');
  const [formData, setFormData] = useState({
    sbVolume: 100,
    videoEnabled: false,
    queueEnabled: false // Added queueEnabled to formData
  });
  const [searchParams, setSearchParams] = useState({
    quantity: 9,
    index: 0,
    sort: 1,
  });
  const [alertInfo, setAlertInfo] = useState({ open: false, message: '', severity: 'info' });
  const [soundboardTableCount, setSoundboardTableCount] = useState(0);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [draggingItem, setDraggingItem] = useState(null);
  const [draggingOverItem, setDraggingOverItem] = useState(null);
  const currentMediaRef = useRef(null);
  const [tooltip, setTooltip] = useState('');

  useEffect(() => {
    fetchSoundData();
    fetchUserSoundboards();
  }, [searchTermClips, searchTermGIFs, searchType, searchParams.index, searchParams.sort]);

  useEffect(() => {
    if (selectedSoundboard) {
      fetchSoundboardSounds(selectedSoundboard.id);
    } else {
      setSoundboardSounds([]);
    }
  }, [selectedSoundboard]);

  const openReorderDialog = () => {
    setIsReorderDialogOpen(true);
  };

  const closeReorderDialog = () => {
    setIsReorderDialogOpen(false);
    fetchUserSoundboards();
  };

  const handleSelectSoundboard = (soundboardId) => {
    setSelectedSoundboard(userSoundboards.find(board => board.id === soundboardId));
    closeReorderDialog();
  };

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const updateSoundCounts = (soundboardId, sounds) => {
    setUserSoundboards(prevSoundboards => prevSoundboards.map(soundboard => {
      if (soundboard.id === soundboardId) {
        return { ...soundboard, soundsCount: sounds.length };
      }
      return soundboard;
    }));
  };

  const fetchUserSoundboards = async () => {
    try {
      const response = await axios.get('/api/soundboards');
      const soundboardsData = response.data.map(soundboard => ({
        ...soundboard,
        soundsCount: soundboard.sounds ? soundboard.sounds.length : 0,
        videoEnabled: soundboard.videoEnabled
      }));
      setUserSoundboards(soundboardsData);

      const totalTables = soundboardsData.reduce((acc, curr) => acc + (curr.sounds ? curr.sounds.length : 0), 0);
      setSoundboardTableCount(totalTables);

      if (soundboardsData.length > 5) {
        setAlertInfo({ open: true, message: 'Maximum of 5 soundboards allowed.', severity: 'error' });
      }

      if (soundboardsData.length > 0) {
        const firstSoundboard = soundboardsData[0];

        if (!selectedSoundboard || !soundboardsData.some(board => board.id === selectedSoundboard.id)) {
          setSelectedSoundboard(firstSoundboard);
          setFormData(prevFormData => ({
            ...prevFormData,
            sbVolume: firstSoundboard.volume,
            videoEnabled: firstSoundboard.videoEnabled,
            queueEnabled: firstSoundboard.queueEnabled
          }));
        }
        setNewSoundboardName(generateUniqueSoundboardName(soundboardsData));
      } else {
        setSelectedSoundboard(null);
        setSoundboardSounds([]);
      }
    } catch (error) {
      console.error('Error fetching user soundboards:', error);
    }
  };

  const generateUniqueSoundboardName = (soundboardsData) => {
    let defaultNameIndex = 1;
    let newName;
    do {
      newName = `Default (${defaultNameIndex})`;
      defaultNameIndex++;
    } while (soundboardsData.some(soundboard => soundboard.name === newName));
    return newName;
  };

  const fetchSoundboardSounds = async (soundboardId) => {
    if (!soundboardId) {
      console.error('No soundboard selected for fetching sounds.');
      setSoundboardSounds([]);
      return;
    }
    try {
      const response = await axios.get(`/api/soundboards/${soundboardId}/sounds`);
      const soundsData = response.data;
      setSoundboardSounds(soundsData);
      updateSoundCounts(soundboardId, soundsData);
    } catch (error) {
      console.error('Error fetching sounds for soundboard:', error);
    }
  };

  const refreshSounds = () => {
    if (selectedSoundboard) {
      fetchSoundboardSounds(selectedSoundboard.id);
    } else {
      setSoundboardSounds([]);
    }
  };

  const fetchSoundData = async () => {
    const apiPath = searchType === 'clips' ? '/api/voicy/clips' : '/api/voicy/soundgifs';
    try {
      const response = await axios.get(apiPath, {
        params: {
          search: searchType === 'clips' ? searchTermClips : searchTermGIFs,
          quantity: searchParams.quantity,
          index: searchParams.index,
          sort: searchParams.sort,
          rating: searchParams.rating
        }
      });
      if (searchType === 'clips') {
        setSoundClips(response.data.clips || []);
      } else {
        setSoundGIFs(response.data.soundGIFs || []);
      }
    } catch (error) {
      console.error(`Error fetching ${searchType}:`, error);
    }
  };

  const handleSearchChange = (type, e) => {
    if (type === 'clips') {
      setSearchTermClips(e.target.value);
    } else {
      setSearchTermGIFs(e.target.value);
    }
    setShowModal(true);
  };

  const playSound = (soundUrl) => {
    if (currentMediaRef.current) {
      currentMediaRef.current.pause();
      currentMediaRef.current = null;
    }

    if (soundUrl.endsWith('.mp4') || soundUrl.includes('video')) {
      const video = document.createElement('video');
      video.src = soundUrl;
      video.volume = formData.sbVolume / 100;
      video.setAttribute('type', 'video/mp4');
      document.body.appendChild(video);
      video.play()
        .then(() => {
        })
        .catch(error => {
          console.error('Error playing video:', error);
        });
      video.onended = () => {
        document.body.removeChild(video);
      };
    } else {
      const audio = new Audio(soundUrl);
      audio.volume = formData.sbVolume / 100;
      audio.play()
        .catch(error => {
          console.error('Error playing sound:', error);
        });
      currentMediaRef.current = audio;
    }
  };

  const addToSoundboard = async (item) => {
    if (!selectedSoundboard || soundboardSounds.length >= 20) {
      setAlertInfo({ open: true, message: 'This soundboard has reached the maximum number of sounds (20).', severity: 'error' });
      return;
    }

    const newSound = {
      name: item.name,
      sound_url: item.sound || '',
      thumbnail_url: item.thumbnail,
      video: item.video || "",
      order: soundboardSounds.length + 1,
      voicy_id: item.id
    };

    try {
      const response = await axios.patch(`/api/soundboards/${selectedSoundboard.id}/sounds`, {
        soundboardSounds: [newSound],
      });

      if (response.status === 200) {
        fetchSoundboardSounds(selectedSoundboard.id);
        setAlertInfo({ open: true, message: 'Sound added to soundboard successfully.', severity: 'success' });

        setUserSoundboards(prevSoundboards => prevSoundboards.map(soundboard => {
          if (soundboard.id === selectedSoundboard.id) {
            return { ...soundboard, soundsCount: soundboard.soundsCount + 1 };
          }
          return soundboard;
        }));
      } else {
        setAlertInfo({ open: true, message: 'Failed to add sound to soundboard.', severity: 'error' });
      }
    } catch (error) {
      console.error('Error adding sound to soundboard:', error);
      setAlertInfo({ open: true, message: 'Error adding sound to soundboard.', severity: 'error' });
    }
  };

  const createSoundboard = async () => {
    if (!newSoundboardName.trim()) {
      return;
    }

    if (userSoundboards.length >= 5) {
      setAlertInfo({ open: true, message: 'Cannot create more than 5 soundboards.', severity: 'error' });
      return;
    }

    try {
      const response = await axios.post('/api/soundboards', {
        createNewSoundboard: true,
        soundboard: {
          name: newSoundboardName
        }
      });
      await fetchUserSoundboards();
      setSoundboardTableCount(soundboardTableCount + 1);
      setAlertInfo({ open: true, message: 'New soundboard created successfully.', severity: 'success' });
      const newSoundboard = response.data;
      setSelectedSoundboard(newSoundboard);
    } catch (error) {
      console.error('Error creating new soundboard:', error);
    }
  };

  const deleteSoundboard = async (soundboardId) => {
    if (!soundboardId) {
      setAlertInfo({ open: true, message: 'Soundboard ID is required for deletion.', severity: 'error' });
      return;
    }

    setConfirmDeleteOpen(true);
    setItemToDelete({ type: 'soundboard', id: soundboardId });
  };

  const confirmDelete = async () => {
    if (!itemToDelete) return;

    try {
      await axios.delete(`/api/soundboards/${itemToDelete.id}`);
      await fetchUserSoundboards();
      const remainingSoundboards = userSoundboards.filter(sb => sb.id !== itemToDelete.id);
      if (remainingSoundboards.length > 0) {
        const firstSoundboard = remainingSoundboards[0];
        setSelectedSoundboard(firstSoundboard);
        fetchSoundboardSounds(firstSoundboard.id);
      } else {
        setSelectedSoundboard(null);
        setSoundboardSounds([]);
        setUserSoundboards([]);
        setSoundboardTableCount(0);
      }
      setAlertInfo({ open: true, message: 'Soundboard deleted successfully.', severity: 'success' });

      setUserSoundboards(prevSoundboards => prevSoundboards.filter(soundboard => soundboard.id !== itemToDelete.id));
    } catch (error) {
      console.error(`Error deleting ${itemToDelete.type}:`, error);
    }

    setConfirmDeleteOpen(false);
    setItemToDelete(null);
  };

  const updateSoundboard = async () => {
    if (!selectedSoundboard || !editSoundboardName.trim()) {
      return;
    }

    const payload = {
      soundboardId: selectedSoundboard.id,
      soundboard: {
        name: editSoundboardName,
        description: editSoundboardDescription
      },
      updateSoundboardDetails: true
    };

    try {
      await axios.patch(`/api/soundboards/${selectedSoundboard.id}`, payload);
      setEditSoundboardName('');
      setEditSoundboardDescription('');
      fetchUserSoundboards();
      setAlertInfo({ open: true, message: 'Soundboard updated successfully.', severity: 'success' });
    } catch (error) {
      console.error('Error updating soundboard:', error);
    }
  };

  const handleSlider = (evt) => {
    let value = evt.target.valueAsNumber;
    if (evt.target.name == 'sbVolume') {
      value = Math.round(value);
      if (selectedSoundboard) {
        axios.patch(`/api/soundboards/${selectedSoundboard.id}`, {
          volume: value
        })
          .then(() => {
            setAlertInfo({ open: true, message: 'Volume updated successfully.', severity: 'success' });

            setUserSoundboards(prevSoundboards => prevSoundboards.map(soundboard => {
              if (soundboard.id === selectedSoundboard.id) {
                return { ...soundboard, volume: value };
              }
              return soundboard;
            }));
          })
          .catch(error => console.error('Error updating volume:', error));
      }
    }
    setFormData({
      ...formData,
      [evt.target.name]: value,
    });
  };

  const handleSoundSent = (message) => {
    setAlertInfo({ open: true, message: message, severity: 'success' });
  };

  const handleVideoEnabledChange = (event) => {
    const newVideoEnabled = event.target.checked;
    setFormData(prevFormData => ({
      ...prevFormData,
      videoEnabled: newVideoEnabled
    }));

    if (selectedSoundboard) {
      axios.patch(`/api/soundboards/${selectedSoundboard.id}`, {
        video_enabled: newVideoEnabled
      })
        .then(() => {
          setAlertInfo({ open: true, message: 'Video setting updated successfully.', severity: 'success' });
          setUserSoundboards(prevSoundboards => prevSoundboards.map(soundboard => {
            if (soundboard.id === selectedSoundboard.id) {
              return { ...soundboard, videoEnabled: newVideoEnabled };
            }
            return soundboard;
          }));
          fetchUserSoundboards();
        })
        .catch(error => {
          console.error('Error updating video setting:', error);
          setAlertInfo({ open: true, message: 'Failed to update video setting.', severity: 'error' });
        });
    }
  };

  const handleQueueEnabledChange = (event) => {
    const newQueueEnabled = event.target.checked;
    setFormData(prevFormData => ({
      ...prevFormData,
      queueEnabled: newQueueEnabled
    }));

    if (selectedSoundboard) {
      axios.patch(`/api/soundboards/${selectedSoundboard.id}`, {
        queue_enabled: newQueueEnabled
      })
        .then(() => {
          setAlertInfo({ open: true, message: 'Queue setting updated successfully.', severity: 'success' });
          setUserSoundboards(prevSoundboards => prevSoundboards.map(soundboard => {
            if (soundboard.id === selectedSoundboard.id) {
              return { ...soundboard, queueEnabled: newQueueEnabled };
            }
            return soundboard;
          }));
          fetchUserSoundboards();
        })
        .catch(error => {
          console.error('Error updating queue setting:', error);
          setAlertInfo({ open: true, message: 'Failed to update queue setting.', severity: 'error' });
        });
    }
  };

  const handleDragStart = (e, index) => {
    setDraggingItem(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDraggingOverItem(index);
  };

  const handleDrop = async (e, index) => {
    e.preventDefault();
    const items = Array.from(soundboardSounds);
    const [draggedItem] = items.splice(draggingItem, 1);
    items.splice(index, 0, draggedItem);

    setSoundboardSounds(items);
    setDraggingItem(null);
    setDraggingOverItem(null);

    const soundOrderUpdate = items.map((sound, idx) => ({
      id: sound.id,
      order: idx + 1
    }));

    try {
      await axios.patch(`/api/soundboards/${selectedSoundboard.id}/sounds`, {
        soundOrderUpdate,
        soundboardId: selectedSoundboard.id,
        updateType: 'reorderSounds'
      });
      refreshSounds();
      setAlertInfo({ open: true, message: 'Sounds reordered successfully.', severity: 'success' });
    } catch (error) {
      console.error('Error reordering sounds:', error);
      setAlertInfo({ open: true, message: 'Failed to reorder sounds.', severity: 'error' });
    }
  };

  return (
    <div className="soundboard-container">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <h2 style={{ margin: '0 20px 0 10px', textAlign: 'left' }}>Soundboards ({userSoundboards.length}/5)</h2>
        <a href="https://www.voicy.network/" target="_blank" rel="noopener noreferrer">
          <img src="/static/img/voicylogo.webp" alt="Voicy Logo" style={{ height: '50px' }} />
        </a>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '20px' }}>
        <div style={{ marginLeft: '10px' }}>
          <ThemeProvider theme={theme}>
            <Slider name="sbVolume"
              label="Volume"
              width="25vw"
              min={0}
              max={100}
              step={10}
              unit="%"
              value={formData.sbVolume}
              handleChange={handleSlider}
              list="volumeMarkers"
              disabled={isMod}
            />
            <datalist id="volumeMarkers">
              {[...Array(11).keys()].map(i => (
                <option key={"volume" + i} value={10 * i} />
              ))}
            </datalist>
            <div className="form-container" style={{ display: 'flex' }}>
              <div className="flex-form-row toggle-switch-row">
                <ToggleSwitch
                  checked={formData.videoEnabled}
                  onChange={handleVideoEnabledChange}
                  name="videoEnabled"
                  disabled={isMod}
                />
                <label htmlFor="videoEnabled">Video Enabled</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('videoEnabled')}
                  />
                  {tooltip === 'videoEnabled' && (
                    <p className="tooltip shadow-lg">
                      Show the video of clips. If disabled, only the sound will play.
                    </p>
                  )}
                </div>
              </div>
              <div className="flex-form-row toggle-switch-row">
                <ToggleSwitch
                  checked={formData.queueEnabled}
                  onChange={handleQueueEnabledChange}
                  name="queueEnabled"
                  disabled={isMod}
                />
                <label htmlFor="queueEnabled">Queue Enabled</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('queueMode')}
                  />
                  {tooltip === 'queueMode' && (
                    <p className="tooltip shadow-lg">
                      Clicking a new sound will play it after the current sound.
                      If disabled, current sound will stop and new sound will play immediately.
                    </p>
                  )}
                </div>
              </div>
            </div>
          </ThemeProvider>
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <Dialog
          open={editSoundboardModalOpen}
          onClose={() => setEditSoundboardModalOpen(false)}
          aria-labelledby="edit-soundboard-dialog-title"
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle id="edit-soundboard-dialog-title">
            <IconButton
              aria-label="close"
              onClick={() => setEditSoundboardModalOpen(false)}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
            Edit Soundboard</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="edit-soundboard-name"
              label={`Edit soundboard name (${editSoundboardName ? editSoundboardName.length : 0}/25)`}
              type="text"
              fullWidth
              variant="outlined"
              inputProps={{ maxLength: 25 }}
              value={editSoundboardName || ''}
              onChange={(e) => setEditSoundboardName(e.target.value)}
            />
            <TextField
              margin="dense"
              id="edit-soundboard-description"
              label={`Edit soundboard description (${editSoundboardDescription ? editSoundboardDescription.length : 0}/100)`}
              type="text"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              inputProps={{ maxLength: 100 }}
              value={editSoundboardDescription || ''}
              onChange={(e) => setEditSoundboardDescription(e.target.value)}
            />
          </DialogContent>
          <ThemeProvider theme={sbSearchTheme}>
            <DialogActions style={{ justifyContent: 'center', width: '60%', margin: '0 auto' }}>
              <Button onClick={() => { updateSoundboard(); setEditSoundboardModalOpen(false); }} variant="contained" color="primary">
                Confirm
              </Button>
            </DialogActions>
          </ThemeProvider>
        </Dialog>
      </ThemeProvider>
      <React.Fragment>
        <ThemeProvider theme={theme}>
          <Tooltip
            title={
              selectedSoundboard
                ? (
                  <React.Fragment>
                    Name: {selectedSoundboard.name}
                    {selectedSoundboard.description && (
                      <React.Fragment>
                        <br />
                        Description: {selectedSoundboard.description}
                      </React.Fragment>
                    )}
                  </React.Fragment>
                )
                : ''
            }
            placement="bottom" arrow
            componentsProps={{
              tooltip: {
                sx: {
                  fontSize: '1rem',
                },
              },
            }}>
            <Autocomplete
              fullWidth
              style={{ marginBottom: '10px' }}
              options={userSoundboards}
              getOptionLabel={(option) => {
                const soundCount = option.soundsCount ? option.soundsCount : 0;
                let label = option.name;
                if (option.description) {
                  label += ` - ${option.description}`;
                }
                if (soundCount > 0) {
                  label += ` (${soundCount}/20)`;
                }
                return label;
              }}
              value={userSoundboards.find(board => board.id === (selectedSoundboard && selectedSoundboard.id)) || null}
              onChange={(event, newValue) => {
                setSelectedSoundboard(newValue || null);
                if (newValue) {
                  setFormData({ ...formData, sbVolume: newValue.volume, videoEnabled: newValue.videoEnabled, queueEnabled: newValue.queueEnabled });
                }
              }}
              renderOption={(props, option) => {
                const soundCount = option.soundsCount ? option.soundsCount : 0;
                let optionLabel = option.name;
                if (option.description) {
                  optionLabel += ` - ${option.description}`;
                }
                if (soundCount > 0) {
                  optionLabel += ` (${soundCount}/20)`;
                }
                return (
                  <li {...props}>
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', alignItems: 'center' }}>
                      <div style={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', marginRight: '10px' }}>
                        <span title={optionLabel}>{optionLabel}</span>
                      </div>
                      {option.name !== "Default" && (
                        <div>
                          <IconButton
                            title="Edit soundboard"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              setSelectedSoundboard(option);
                              setEditSoundboardModalOpen(true);
                              setEditSoundboardName(option.name);
                              setEditSoundboardDescription(option.description);
                            }}
                            disabled={isMod}
                          >
                            <SettingsIcon />
                          </IconButton>
                          <IconButton
                            title="Delete soundboard"
                            size="small"
                            onClick={(e) => {
                              e.stopPropagation();
                              deleteSoundboard(option.id, option.name);
                            }}
                            disabled={isMod}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </li>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Soundboard"
                  variant="outlined"
                  sx={{ width: '100%' }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {params.InputProps.endAdornment}
                        {userSoundboards.length < 5 && (
                          <IconButton title="Add soundboard" disabled={isMod}
                            size="small" onClick={() => setAddSoundboardModalOpen(true)}>
                            <AddBoxIcon />
                          </IconButton>
                        )}
                        <IconButton title="Reorder soundboards" disabled={isMod}
                          onClick={openReorderDialog} color="primary">
                          <DragIndicatorIcon />
                        </IconButton>
                        <IconButton
                          title="Sort soundboards by name"
                          size="small"
                          onClick={() => {
                            setSortDirection(prevDirection => {
                              const newDirection = prevDirection === 'asc' ? 'desc' : 'asc';
                              setUserSoundboards(userSoundboards.sort((a, b) =>
                                newDirection === 'asc' ? a.name.localeCompare(b.name) : b.name.localeCompare(a.name)
                              ));
                              return newDirection;
                            });
                          }}
                        >
                          {sortDirection === 'asc' ? <ArrowUpwardIcon /> : <ArrowDownwardIcon />}
                        </IconButton>
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Tooltip>
        </ThemeProvider>
      </React.Fragment>
{selectedSoundboard && (
  <ThemeProvider theme={theme}>
    <div className="soundboard-grid">
      {soundboardSounds.map((sound, index) => (
        <div
          key={sound.id}
          draggable
          onDragStart={(e) => handleDragStart(e, index)}
          onDragOver={(e) => handleDragOver(e, index)}
          onDrop={(e) => handleDrop(e, index)}
          className={`sound-item ${draggingItem === index ? 'dragging' : ''} ${draggingOverItem === index ? 'dragging-over' : ''}`}
        >
          <SoundboardItem
            sound={{
              id: sound.id,
              name: sound.name,
              soundUrl: sound.soundUrl,
              thumbnailUrl: sound.thumbnailUrl,
              video: sound.video,
              likes: sound.likes,
              views: sound.views,
            }}
            user={user}
            selectedSoundboardVolume={formData.sbVolume}
            soundboardId={selectedSoundboard.id}
            soundboardDetails={{
              id: selectedSoundboard.id,
              volume: formData.sbVolume,
              name: selectedSoundboard.name,
              description: selectedSoundboard.description,
              tableOrder: selectedSoundboard.tableOrder,
            }}
            videoEnabled={formData.videoEnabled}
            queueEnabled={formData.queueEnabled}
            refreshSounds={refreshSounds}
            onSoundSent={handleSoundSent}
            disabled={isMod}
          />
        </div>
      ))}
      {soundboardSounds.length < 20 && (
        <div
          className="sound-item"
          onClick={() => !isMod && setShowModal(true)}
          style={{
            cursor: 'pointer',
            visibility: draggingItem !== null ? 'hidden' : 'visible'
          }}
        >
          <img src="/static/img/sb-add.svg" alt="Search Sounds" style={{ width: '145px', height: '187px', borderRadius: '10px', objectFit: 'cover' }} />
        </div>
      )}
    </div>
  </ThemeProvider>
)}
      {showModal && (
        <ThemeProvider theme={sbSearchTheme}>
          <Dialog
            open={showModal}
            onClose={() => setShowModal(false)}
            aria-labelledby="search-dialog-title"
            aria-describedby="search-dialog-description"
            scroll="paper"
          >
            <DialogTitle>
              <span style={{ width: '100%', textAlign: 'center' }}>Search Sounds and Clips</span>
              <IconButton
                aria-label="close"
                onClick={() => setShowModal(false)}
                sx={{
                  position: 'absolute',
                  right: 8,
                  top: 0,
                }}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent style={{ overflow: 'visible', paddingTop: '5px' }}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={searchType === 'clips' ? 0 : 1} onChange={(event, newValue) => {
                  setSearchType(newValue === 0 ? 'clips' : 'gifs');
                }} aria-label="search tabs">
                  <Tab label="Sounds" />
                  <Tab label="Clips" />
                </Tabs>
              </Box>
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginTop: '10px' }}>
                {searchType === 'clips' ? (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="search-clips"
                    label="Search Sounds"
                    type="text"
                    variant="outlined"
                    value={searchTermClips}
                    onChange={(e) => handleSearchChange('clips', e)}
                    style={{ flexGrow: 1, marginRight: '10px' }}
                  />
                ) : (
                  <TextField
                    autoFocus
                    margin="dense"
                    id="search-gifs"
                    label="Search Clips"
                    type="text"
                    variant="outlined"
                    value={searchTermGIFs}
                    onChange={(e) => handleSearchChange('gifs', e)}
                    style={{ flexGrow: 1, marginRight: '10px' }}
                  />
                )}
                <Autocomplete
                  id="sort-autocomplete"
                  options={[{ label: 'Trending', value: 1 }, { label: 'New', value: 0 }]}
                  getOptionLabel={(option) => option.label}
                  style={{ flexGrow: 0.2, marginRight: '20px' }}
                  value={searchParams.sort === 1 ? { label: 'Trending', value: 1 } : { label: 'New', value: 0 }}
                  onChange={(event, newValue) => {
                    setSearchParams({ ...searchParams, sort: newValue.value });
                  }}
                  renderInput={(params) => <TextField {...params} label="Sort" variant="outlined" />}
                  disableClearable
                  freeSolo
                />
              </div>
            </DialogContent>
            <DialogContent style={{ overflow: 'auto' }}>
              <Grid container spacing={2}>
                {(searchType === 'clips' ? soundClips : soundGIFs).map((item) => (
                  <Grid item xs={12} sm={6} md={4} key={item.id}>
                    <Tooltip title={item.name} placement="bottom" arrow>
                      <Card
                        onClick={() => item.video ? undefined : playSound(item.sound)}
                      >
                        {item.video ? (
                          <div style={{ position: 'relative' }}>
                            <CardMedia
                              component="video"
                              src={item.video}
                              alt={item.name}
                              controls
                            />
                          </div>
                        ) : (
                          <div style={{ position: 'relative' }}>
                            <CardMedia
                              component="img"
                              image={item.thumbnail}
                              alt={item.name}
                            />
                            <PlayCircleIcon style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: '3rem', opacity: '0.5' }} />
                          </div>
                        )}
                        {soundboardSounds.length < 20 && (
                          <IconButton
                            title="Add to soundboard"
                            onClick={(e) => { e.stopPropagation(); addToSoundboard(item); setShowModal(true); }}
                            sx={{
                              position: 'absolute',
                              top: '-3px',
                              right: '-3px'
                            }}
                          >
                            <AddBoxIcon />
                          </IconButton>
                        )}
                        <Typography variant="h6" component="div">
                          {item.name}
                        </Typography>
                      </Card>
                    </Tooltip>
                  </Grid>
                ))}
              </Grid>
            </DialogContent>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '5px', padding: '5px' }}>
              <IconButton onClick={() => setSearchParams({ ...searchParams, index: Math.max(searchParams.index - 1, 0) })} color="primary">
                <ArrowBackIcon />
              </IconButton>
              <IconButton onClick={() => setSearchParams({ ...searchParams, index: searchParams.index + 1 })} color="primary">
                <ArrowForwardIcon />
              </IconButton>
            </div>
          </Dialog>
        </ThemeProvider>
      )}
      <ThemeProvider theme={theme}>
        <Dialog
          open={addSoundboardModalOpen}
          onClose={() => setAddSoundboardModalOpen(false)}
          aria-labelledby="form-dialog-title"
          fullWidth={true}
          maxWidth={"md"}
        >
          <DialogTitle>
            <IconButton
              title="Close"
              aria-label="close"
              onClick={() => setAddSoundboardModalOpen(false)}
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
              }}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <DialogContentText>
              Enter the name for the new soundboard.
            </DialogContentText>
            <TextField
              autoFocus
              margin="dense"
              id="name"
              label={`Soundboard Name (${newSoundboardName ? newSoundboardName.length : 0}/25)`}
              type="text"
              fullWidth
              inputProps={{ maxLength: 25 }}
              value={newSoundboardName || ''}
              onChange={(e) => setNewSoundboardName(e.target.value)}
            />
          </DialogContent>
          <ThemeProvider theme={sbSearchTheme}>
            <DialogActions style={{ justifyContent: 'center', width: '60%', margin: '0 auto' }}>
              <Button title="Add soundboard" onClick={() => { createSoundboard(); setAddSoundboardModalOpen(false); }} variant="contained" color="primary" disabled={isMod}>
                Add
              </Button>
            </DialogActions>
          </ThemeProvider>
        </Dialog>
      </ThemeProvider>
      {isReorderDialogOpen && (
        <DraggableSoundboardList
          soundboards={userSoundboards}
          selectedSoundboard={selectedSoundboard ? selectedSoundboard.id : null}
          onSelect={handleSelectSoundboard}
          onClose={closeReorderDialog}
        />
      )}
      <CommonAlert open={alertInfo.open} autoHideDuration={6000} onClose={() => setAlertInfo({ ...alertInfo, open: false })} severity={alertInfo.severity} message={alertInfo.message} />
      <ThemeProvider theme={theme}>
        <Dialog
          open={confirmDeleteOpen}
          onClose={() => setConfirmDeleteOpen(false)}
          aria-labelledby="confirm-delete-dialog-title"
          aria-describedby="confirm-delete-dialog-description"
        >
          <DialogTitle id="confirm-delete-dialog-title">{"Confirm Delete"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="confirm-delete-dialog-description">
              Are you sure you want to delete this {itemToDelete && itemToDelete.type}? This action cannot be undone.
            </DialogContentText>
          </DialogContent>
          <ThemeProvider theme={sbSearchTheme}>
            <DialogActions>
              <Button onClick={confirmDelete} variant="contained" color="primary">
                Confirm
              </Button>
              <Button onClick={() => setConfirmDeleteOpen(false)} variant="contained"
                sx={{
                  color: 'white',
                  backgroundColor: 'var(--light-grey)',
                  '&:hover': {
                    backgroundColor: 'var(--red)',
                  }
                }}>
                Cancel
              </Button>
            </DialogActions>
          </ThemeProvider>
        </Dialog>
      </ThemeProvider>
    </div>
  );
};

export default Soundboard;
