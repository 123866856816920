import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const unitOptions = [
  { value: '%', label: '%' },
  { value: 'px', label: 'px' },
  { value: 'rem', label: 'rem' },
];

const SizeSelector = ({ name, onSizeChange, styles = {}, value, unit, disabled = false }) => {
  const [sizeValue, setSizeValue] = useState(value || '100');
  const [selectedUnit, setSelectedUnit] = useState(() =>
    unitOptions.find(option => option.value === unit.value) || unitOptions[0]
  );

  useEffect(() => {
    setSizeValue(value);
    setSelectedUnit(unitOptions.find(option => option.value === unit.value) || unitOptions[0]);
  }, [value, unit]);

  const handleSizeChange = (e) => {
    const newSizeValue = e.target.value;
    setSizeValue(newSizeValue);
    if (onSizeChange) {
      onSizeChange(newSizeValue, selectedUnit.value, { name });
    }
  };

  const handleUnitChange = (selectedOption) => {
    setSelectedUnit(selectedOption);
    if (onSizeChange) {
      onSizeChange(sizeValue, selectedOption.value, { name });
    }
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      ...styles.control ? styles.control(provided) : {},
      minWidth: '120px',
    }),
    option: (provided, state) => ({
      ...provided,
      color: 'black',
      backgroundColor: state.isSelected ? 'lightgray' : 'white',
    }),
  };

  return (
      <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
          <input
              type="number"
              name={`${name}-size`}
              value={sizeValue}
              onChange={handleSizeChange}
              style={{ ...styles.input }}
              disabled={disabled}
          />
          <Select
              value={selectedUnit}
              onChange={handleUnitChange}
              options={unitOptions}
              styles={customStyles}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.value}
              isClearable={false}
              isDisabled={disabled}
          />
      </div>
  );
};

export default SizeSelector;
