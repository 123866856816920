import React, { useState, useEffect } from 'react';
import { usePayPalScriptReducer, PayPalButtons } from '@paypal/react-paypal-js';

const PaypalButtons = ({
  paidChatData,
  validForm,
  reloadCaptcha,
  confirmTransaction,
  completed,
  setLoading,
  numberAmount,
  captchaToken,
  setUsingPaypalCard,
}) => {
  const [paymentSource, setPaymentSource] = useState('');
  const [{ options }, dispatch] = usePayPalScriptReducer();
  useEffect(() => {
    dispatch({
      type: 'resetOptions',
      value: {
        ...options,
        'merchant-id': paidChatData.paypalMerchantId,
      },
    });
  }, [paidChatData.paypalMerchantId]);

  useEffect(() => {
    setUsingPaypalCard(paymentSource == 'card');
  }, [paymentSource]);

  return (
    <PayPalButtons
      forceReRender={[paidChatData, validForm, captchaToken, numberAmount]}
      createOrder={(data) => {
        setPaymentSource(data.paymentSource);
        setLoading(true);
        return fetch('/paypal/create', {
          method: 'post',
          headers: {
            'content-type': 'application/json',
          },
          body: JSON.stringify({
            amount: numberAmount,
            merchantId: paidChatData.paypalMerchantId,
            merchantEmail: paidChatData.paypalEmail,
            token: captchaToken,
          }),
        })
          .then(function (res) {
            return res.json();
          })
          .then(function (data) {
            return data.orderID; // Use the key sent by your server's response, ex. 'id' or 'token'
          });
      }}
      onClick={(data, actions) => {
        if (!validForm) {
          return actions.reject();
        }
        return actions.resolve();
      }}
      onApprove={(data, actions) =>
        actions.order
          .capture()
          .then(function (details) {
            reloadCaptcha();
            // self function shows a transaction success message to your buyer.
            confirmTransaction(
              data.orderID,
              details.purchase_units[0].amount.value
            );
            setLoading(false);
            completed('PayPal payment completed');
          })
          .catch(function (err) {
            console.error('Paypal error');
            console.error(err);
          })
      }
      onCancel={() => {
        reloadCaptcha();
        setLoading(false);
      }}
      onError={() => {
        reloadCaptcha();
        setLoading(false);
      }}
    />
  );
};

export default PaypalButtons;
