import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Select from 'react-select';
import ToggleSwitch from './toggle-switch';
import CurrencyInput from 'react-currency-input-field';
import FormAlert from './form-alert';
import ColorInput from './color-input';
import FontSelector from './font-selector';
import '../css/settings-form.css';
import '../css/settings-overlay.css';
import '../css/fonts.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { ttsFreeVoiceOptions } from '../static/tts-voices';
import VoiceSelect from './voice-select';

const SettingsForm = ({ updateData, isMod=false, ...props }) => {
  // Sanitize props to prevent null values warning.
  const sanitizedProps = { ...props };
  Object.entries(sanitizedProps).forEach(([key, value]) => {
    if (value === null) {
      sanitizedProps[key] = '';
    }
  });

  if (props.customDonationAmounts && Array.isArray(props.customDonationAmounts)) {
    props.customDonationAmounts.forEach((amount, index) => {
      sanitizedProps[`donationAmount${index + 1}`] = amount;
    });
  }

  const initialFormState = { ...sanitizedProps };

  //console.info('initialFormState:', initialFormState);

  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [tooltip, setTooltip] = useState('');
  const [formData, setFormData] = useState(initialFormState);
  const [formValid, setFormValid] = useState({
    blacklist: true,
    maxTtsSeconds: true,
  });

  const fontOptions = [
    { value: 'default', label: 'Default' },
    { value: 'bold', label: 'Bold' },
    { value: 'comicbook', label: 'Comic Book' },
    { value: 'consolas', label: 'Consolas' },
    { value: 'cursive', label: 'Cursive' },
    { value: 'futuristic', label: 'Futuristic' },
    { value: 'groovy', label: 'Groovy' },
    { value: 'jaldi', label: 'Jaldi' },
    { value: 'professional', label: 'Professional' },
    { value: 'spooky', label: 'Spooky' },
    { value: 'three-d', label: '3D' },
  ];

  const animationOptions = [
    { value: 'default', label: 'Default' },
    { value: 'bounce', label: 'Bounce' },
    { value: 'rubberBand', label: 'Rubber Band' },
    { value: 'shakeY', label: 'Shake Y' },
    { value: 'swing', label: 'Swing' },
    { value: 'tada', label: 'Tada' },
    { value: 'none', label: 'None' },
  ];

  const voiceStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '40px',
      margin: '10px 20px 0 0',
      border: 'none',
      outline: 'none',
    }),
    option: (styles) => ({ ...styles, color: 'black', width: '100%' }),
    input: (styles) => ({
      ...styles,
      cursor: 'text',
    }),
  };

  const fontStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '40px',
      margin: '10px 0 0 0',
      border: 'none',
      outline: 'none',
    }),
    option: (styles) => ({ ...styles, color: 'black' }),
    input: (styles) => ({
      ...styles,
    }),
  };

  const selectStyles = {
    control: (styles) => ({
      ...styles,
      width: '100%',
      height: '40px',
      margin: '10px 0 0 0',
      border: 'none',
      outline: 'none',
    }),
    option: (styles) => ({
      ...styles,
      color: 'black',
      ':active': {
        ...styles[':active'],
      },
    }),
    input: (styles) => ({
      ...styles,
      cursor: 'text',
    }),
  };

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleSelectChange = (option, action) => {
    setFormData({
      ...formData,
      [action.name]: option.value,
    });
  };

  const handleToggle = (name) => {
    const value = formData[name] ? false : true;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    let valid =
      !formData.blacklist || formData.blacklist.match(/^( *[^: ]+:[^: ]+ *)*$/);
    setFormValid({
      ...formValid,
      blacklist: valid,
    });
  }, [formData.blacklist]);

  useEffect(() => {
    let maxTtsSeconds = parseInt(formData.maxTtsSeconds);
    setFormValid({
      ...formValid,
      maxTtsSeconds: maxTtsSeconds >= 10 && maxTtsSeconds <= 60,
    });
  }, [formData.maxTtsSeconds]);

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const parseField = (field) => {
    if (typeof field == 'string') {
      return parseFloat(field.replaceAll('$', ''));
    } else {
      return field;
    }
  };

  const fieldNotNull = (field) => {
    return field != null && field != '';
  };

  const handleSubmit = async (event) => {
    const updatedSettings = Object.fromEntries(
      Object.entries(formData).filter(([, v]) => v != null)
    );
    event.preventDefault();
    const customDonationAmounts = [1, 2, 3, 4].map(index =>
      formData[`donationAmount${index}`]
    ).filter(v => v != null);
    updatedSettings.customDonationAmounts = customDonationAmounts;
    const blacklist = formData.blacklist
      ? formData.blacklist.replace(/ +/g, ' ')
      : null;
    if (
      fieldNotNull(updatedSettings.donationLimit) &&
      parseField(updatedSettings.donationLimit) < 1
    ) {
      triggerAlert(
        'Minimum Powerchat limit must be greater than $1.00',
        false
      );
      return;
    }
    if (
      fieldNotNull(updatedSettings.donationLimit) &&
      fieldNotNull(updatedSettings.mediaLimit) &&
      parseField(updatedSettings.donationLimit) >
        parseField(updatedSettings.mediaLimit)
    ) {
      triggerAlert(
        'Minimum Powerchat limit must be less than the media limit.',
        false
      );
      return;
    }
    if (
      fieldNotNull(updatedSettings.donationLimit) &&
      fieldNotNull(updatedSettings.ttsLimit) &&
      parseField(updatedSettings.donationLimit) >
        parseField(updatedSettings.ttsLimit)
    ) {
      triggerAlert(
        'Minimum Powerchat limit must be less than the TTS limit.',
        false
      );
      return;
    }
    if (
      fieldNotNull(updatedSettings.mediaLimit) &&
      fieldNotNull(updatedSettings.extendedMediaLimit) &&
      parseField(updatedSettings.extendedMediaLimit) <=
        parseField(updatedSettings.mediaLimit)
    ) {
      triggerAlert(
        'Extended media limit must be greater than media limit',
        false
      );
      return;
    }
    if (
      fieldNotNull(updatedSettings.mediaDuration) &&
      fieldNotNull(updatedSettings.extendedMediaDuration) &&
      parseField(updatedSettings.extendedMediaDuration) <=
        parseField(updatedSettings.mediaDuration)
    ) {
      triggerAlert(
        'Extended media duration must be greater than media duration',
        false
      );
      return;
    }

    if (fieldNotNull(blacklist) && !formValid.blacklist) {
      triggerAlert('Blacklist must be in correct format', false);
      return;
    }

    if (!formValid.maxTtsSeconds) {
      triggerAlert('Max TTS seconds out of range.', false);
      return;
    }

    try {
      const { data } = await axios.put('/api/ttsSettings', {
        ...updatedSettings,
        blacklist,
      });
      if (data === 'OK') {
        triggerAlert('Settings successfully updated');
        updateData(formData);
      } else {
        triggerAlert('Could not save settings', false);
      }
    } catch (err) {
      console.error(err);
      triggerAlert('Could not save settings', false);
    }
  };

  const toggleTooltip = (fieldName) => {
    if (tooltip === fieldName) {
      setTooltip('');
    } else {
      setTooltip(fieldName);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.path ||
        !event.path.find((x) => x.classList && x.classList.contains('tooltip'))
      ) {
        setTooltip('');
      }
    };
    const handleKeyUp = (event) => {
      if (event.keyCode === 27) {
        setTooltip('');
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const cancelSettingsUpdate = () => {
    setFormData({ ...initialFormState });
    triggerAlert('Changes discarded');
  };

  return (
    <div className="settings-container form-container">
      <form onSubmit={handleSubmit}>
        <div className="settings-sections-wrapper">
          <div className="settings-section">
            <h4 className="text-light-blue">TTS & Media Limits:</h4>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="donationLimit">Minimum Powerchat Limit ($)</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('donationLimit')}
                  />
                  {tooltip === 'donationLimit' && (
                    <p className="tooltip shadow-lg">
                      Minimum price to send a paid chat.
                    </p>
                  )}
                </div>
              </div>
              <div>
                <CurrencyInput
                  name="donationLimit"
                  placeholder="Powerchat Limit"
                  value={formData.donationLimit || ''}
                  prefix="$"
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  allowNegativeValue={false}
                  decimalScale={2}
                  onValueChange={(value, name) =>
                    handleChange({ target: { value, name } })
                  }
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="ttsLimit">TTS Limit ($)</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('ttsLimit')}
                  />
                  {tooltip === 'ttsLimit' && (
                    <p className="tooltip shadow-lg">
                      Minimum price to read Text-to-Speech. Lower paid chats
                      should still display on screen.
                    </p>
                  )}
                </div>
              </div>
              <div>
                <CurrencyInput
                  name="ttsLimit"
                  placeholder="TTS Limit"
                  value={formData.ttsLimit || ''}
                  prefix="$"
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  allowNegativeValue={false}
                  decimalScale={2}
                  onValueChange={(value, name) =>
                    handleChange({ target: { value, name } })
                  }
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="mediaLimit">Media Limit ($)</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('mediaLimit')}
                  />
                  {tooltip === 'mediaLimit' && (
                    <p className="tooltip shadow-lg">
                      Optional. Allow users to autoplay audio from a YouTube
                      video after TTS. Enabled/Disabled by button below.
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <CurrencyInput
                  name="mediaLimit"
                  placeholder="Media Limit"
                  value={formData.mediaLimit || ''}
                  prefix="$"
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  allowNegativeValue={false}
                  decimalScale={2}
                  onValueChange={(value, name) =>
                    handleChange({ target: { value, name } })
                  }
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="extendedMediaLimit">
                  Extended Media Limit ($)
                </label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('extendedMediaLimit')}
                  />
                  {tooltip === 'extendedMediaLimit' && (
                    <p className="tooltip shadow-lg">
                      Optional. Set a LONGER media duration for a HIGHER price.
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <CurrencyInput
                  name="extendedMediaLimit"
                  placeholder="Extended Media Limit"
                  value={formData.extendedMediaLimit || ''}
                  prefix="$"
                  intlConfig={{ locale: 'en-US', currency: 'USD' }}
                  allowNegativeValue={false}
                  decimalScale={2}
                  onValueChange={(value, name) =>
                    handleChange({ target: { value, name } })
                  }
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row tooltip-wrapper">
              <label htmlFor="mediaDuration">Media Duration (seconds)</label>
              <div className="tooltip-input">
                <input
                  name="mediaDuration"
                  placeholder="Media Duration"
                  onChange={handleChange}
                  value={formData.mediaDuration || ''}
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row tooltip-wrapper">
              <label htmlFor="extendedMediaDuration">
                Extended Media Duration (seconds)
              </label>
              <div className="tooltip-input">
                <input
                  name="extendedMediaDuration"
                  placeholder="Extended Media Duration in Seconds"
                  onChange={handleChange}
                  value={formData.extendedMediaDuration || ''}
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row">
              <label htmlFor="maxTtsSeconds">TTS Duration (seconds)</label>
              <div className="tooltip-input">
                <input
                  name="maxTtsSeconds"
                  placeholder="TTS Duration in Seconds"
                  onChange={handleChange}
                  value={formData.maxTtsSeconds || ''}
                  disabled={isMod}
                />
                {!formValid.maxTtsSeconds && (
                  <div className="invalid-message">
                    Must be between 10 and 60 seconds.
                  </div>
                )}
              </div>
            </div>
            <div className="input-row">
              <div className="flex-row" style={{justifyContent: 'space-between'}}>
                {[1, 2, 3, 4].map((index) => (
                  <div key={`donationAmount${index}`} style={{padding: '0 5px'}}>
                  <label htmlFor={`donationAmount${index}`}>
                    Donation Amount {index}
                  </label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip(`donationAmount${index}`)}
                      />
                      {tooltip === `donationAmount${index}` && (
                        <p className="tooltip shadow-lg">
                          Set a custom recommended donation amount between $1 and $999.
                        </p>
                      )}
                    </div>
                    <CurrencyInput
                      name={`donationAmount${index}`}
                      placeholder={`$`}
                      value={formData[`donationAmount${index}`] || ''}
                      prefix="$"
                      intlConfig={{ locale: 'en-US', currency: 'USD' }}
                      allowNegativeValue={false}
                      decimalScale={0}
                      allowDecimals={false}
                      maxLength={3}
                      onValueChange={(value, name) =>
                        handleChange({ target: { value: value <= 999 ? value : '', name } })
                      }
                      disabled={isMod}
                    />
                  </div>
                ))}
              </div>
            </div>
          </div>
          <div className="settings-section">
            <h4 className="text-light-blue">TTS Customization:</h4>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="ttsImage">Alert Image URL</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('ttsImage')}
                  />
                  {tooltip === 'ttsImage' && (
                    <p className="tooltip shadow-lg">
                      Shows for donation alerts. e.g.
                      https://i.imgur.com/rKsgOrw.jpeg
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <input
                  name="ttsImage"
                  placeholder="Alert Image URL"
                  onChange={handleChange}
                  value={formData.ttsImage || ''}
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="ttsSound">Alert Sound URL</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('ttsSound')}
                  />
                  {tooltip === 'ttsSound' && (
                    <p className="tooltip shadow-lg">
                      Plays for donation alerts. e.g.
                      https://i.imgur.com/rKsgOrw.jpeg
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <input
                  name="ttsSound"
                  placeholder="Alert Sound URL"
                  onChange={handleChange}
                  value={formData.ttsSound || ''}
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="subscribeImage">Subscribe Image URL</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('subscribeImage')}
                  />
                  {tooltip === 'subscribeImage' && (
                    <p className="tooltip shadow-lg">
                      Shows for subscribe alerts. e.g.
                      https://i.imgur.com/rKsgOrw.jpeg
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <input
                  name="subscribeImage"
                  placeholder="Subscribe Image URL"
                  onChange={handleChange}
                  value={formData.subscribeImage || ''}
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="subscribeSound">Subscribe Sound URL</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('subscribeSound')}
                  />
                  {tooltip === 'subscribeSound' && (
                    <p className="tooltip shadow-lg">
                      Plays for subscribe alerts. e.g.
                      https://www.mboxdrive.com/coin.mp3
                    </p>
                  )}
                </div>
              </div>
              <div className="input-toggle-row">
                <div className="tooltip-input">
                  <input
                    name="subscribeSound"
                    placeholder="Subscribe Sound URL"
                    onChange={handleChange}
                    value={formData.subscribeSound || ''}
                    disabled={isMod}
                  />
                </div>
                <div className="flex-row">
                  <div className="tooltip-input">
                    <ToggleSwitch
                      name="subscribeMute"
                      onChange={() => handleToggle('subscribeMute')}
                      checked={formData.subscribeMute}
                      disabled={isMod}
                    />
                  </div>
                  <label htmlFor="subscribeMute">Mute</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('subscribeMute')}
                    />
                    {tooltip === 'subscribeMute' && (
                      <p className="tooltip right shadow-lg">
                        Turns off sound for subscribe alerts.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="ttsSound">Follow Image URL</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('followImage')}
                  />
                  {tooltip === 'followImage' && (
                    <p className="tooltip shadow-lg">
                      Shows for follow alerts. e.g.
                      https://i.imgur.com/rKsgOrw.jpeg
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <input
                  name="followImage"
                  placeholder="Follow Image URL"
                  onChange={handleChange}
                  value={formData.followImage || ''}
                  disabled={isMod}
                />
              </div>
              <div className="input-row">
                <div className="flex-row">
                  <label htmlFor="followSound">Follow Sound URL</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('followSound')}
                    />
                    {tooltip === 'followSound' && (
                      <p className="tooltip shadow-lg">
                        Plays for follow alerts. e.g.
                        https://www.mboxdrive.com/coin.mp3
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="input-toggle-row">
                <div className="tooltip-input">
                  <input
                    name="followSound"
                    placeholder="Follow Sound URL"
                    onChange={handleChange}
                    value={formData.followSound || ''}
                    disabled={isMod}
                  />
                </div>
                <div className="flex-row">
                  <div className="tooltip-input">
                    <ToggleSwitch
                      name="followMute"
                      onChange={() => handleToggle('followMute')}
                      checked={formData.followMute}
                      disabled={isMod}
                    />
                  </div>
                  <label htmlFor="followMute">Mute</label>
                  <div className="icon-btn tooltip-wrapper">
                    <FontAwesomeIcon
                      icon={faInfoCircle}
                      color="#57bad8"
                      onClick={() => toggleTooltip('followMute')}
                    />
                    {tooltip === 'followMute' && (
                      <p className="tooltip right shadow-lg">
                        Turns off sound for follow alerts.
                      </p>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="input-row" style={{ marginRight: '2em' }}>
                  <div className="flex-row">
                    <label htmlFor="ttsSubscriberColor">Subscriber Color</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('ttsSubscriberColor')}
                      />
                      {tooltip === 'ttsSubscriberColor' && (
                        <p className="tooltip shadow-lg">
                          <strong>
                            Color of Powerchat Subscriber&apos;s name.
                          </strong>
                          <br />
                          You can type in a hexadecimal color code or click on
                          the color box to the left to select a color via the
                          picker.
                          <br />
                          Default: #ffd700
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <ColorInput
                      name="ttsSubscriberColor"
                      value={formData.ttsSubscriberColor || '#ffd700'}
                      onValueChange={(value, name) =>
                        handleChange({ target: { value, name } })
                      }
                      disabled={isMod}
                    />
                  </div>
                </div>
                <div className="input-row">
                  <div className="flex-row">
                    <label htmlFor="ttsDonatorColor">Donator Color</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('ttsDonatorColor')}
                      />
                      {tooltip === 'ttsDonatorColor' && (
                        <p className="tooltip shadow-lg">
                          <strong>Color of Donator&apos;s name.</strong>
                          <br />
                          You can type in a hexadecimal color code or click on
                          the color box to the left to select a color via the
                          picker.
                          <br />
                          Default: #71cceb
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <ColorInput
                      name="ttsDonatorColor"
                      value={formData.ttsDonatorColor || '#71cceb'}
                      onValueChange={(value, name) =>
                        handleChange({ target: { value, name } })
                      }
                      disabled={isMod}
                    />
                  </div>
                </div>
              </div>
              <div className="flex-row">
                <div className="input-row" style={{ marginRight: '2em' }}>
                  <div className="flex-row">
                    <label htmlFor="ttsMessageColor">Message Color</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('ttsMessageColor')}
                      />
                      {tooltip === 'ttsMessageColor' && (
                        <p className="tooltip shadow-lg">
                          <strong>Color of tts message text.</strong>
                          <br />
                          You can type in a hexadecimal color code or click on
                          the color box to the left to select a color via the
                          picker.
                          <br />
                          Default: #ffffff (white)
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="tooltip-input">
                    <ColorInput
                      name="ttsMessageColor"
                      value={formData.ttsMessageColor || '#ffffff'}
                      onValueChange={(value, name) =>
                        handleChange({ target: { value, name } })
                      }
                      disabled={isMod}
                    />
                  </div>
                </div>
                <div className="input-toggle-row">
                  <div className="flex-row">
                    <ToggleSwitch
                      name="ttsDefaultColors"
                      onChange={() => handleToggle('ttsDefaultColors')}
                      checked={formData.ttsDefaultColors}
                      disabled={isMod}
                    />
                    <label htmlFor="ttsDefaultColors">Use Default Colors</label>
                    <div className="icon-btn tooltip-wrapper">
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        color="#57bad8"
                        onClick={() => toggleTooltip('ttsDefaultColors')}
                      />
                      {tooltip === 'ttsDefaultColors' && (
                        <p className="tooltip shadow-lg">
                          Use default platform specific colors for TTS alerts.
                          Ignores the custom settings set here, when enabled.
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="select-row tooltip-wrapper">
              <div className="input-row" style={{ width: '100%' }}>
                <label htmlFor="ttsVoice">Default TTS Voice</label>
                <VoiceSelect
                  name="ttsVoice"
                  options={ttsFreeVoiceOptions}
                  styles={voiceStyles}
                  placeholder={'Select a default Voice'}
                  onChange={handleSelectChange}
                  value={ttsFreeVoiceOptions.find(
                    (x) => x.value == formData.ttsVoice
                  )}
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="select-row">
              <div className="input-row">
                <label htmlFor="ttsFont">Font</label>
                  <FontSelector
                    name="ttsFont"
                    fonts={fontOptions}
                    styles={fontStyles}
                    placeholder="Font"
                    onFontChange={handleSelectChange}
                    value={fontOptions.find((x) => x.value == formData.ttsFont)}
                    disabled={isMod}
                  />
                </div>
                <div className="font blank-info-icon"></div>
                <div className="input-row tooltip-wrapper">
                  <label htmlFor="animation">Animation</label>
                  <Select
                    name="ttsAnimation"
                    options={animationOptions}
                    styles={selectStyles}
                    placeholder="Animation"
                    onChange={handleSelectChange}
                    value={animationOptions.find(
                      (x) => x.value == formData.ttsAnimation
                    )}
                    isDisabled={isMod}
                  />
                </div>
              </div>
            </div>
          </div>

        <hr className="hr-light-blue" />
          
        <div className="settings-sections-wrapper">
          <div className="settings-section">
            <h4 className="text-light-blue">TOS Protection & Toggles:</h4>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="blacklist">TTS Blacklist Words</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('blacklist')}
                  />
                  {tooltip === 'blacklist' && (
                    <p className="tooltip shadow-lg">
                      Block words from TTS (e.g. hell:heck replaces every
                      instance of &apos;hell&apos; with the word
                      &apos;heck&apos;) Enter blacklisted terms seperated by a
                      space. Use underscore for phrases. E.g. fuck:love
                      fuck_you:love_you
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <input
                  name="blacklist"
                  placeholder="TTS Blacklist Words"
                  onChange={handleChange}
                  value={formData.blacklist}
                  disabled={isMod}
                />
                {!formValid.blacklist && (
                  <div className="invalid-message">
                    Blacklist not in correct format.{' '}
                    <a onClick={() => toggleTooltip('blacklist')}>
                      Learn more.
                    </a>
                  </div>
                )}
              </div>
            </div>
            <div className="input-row tooltip-wrapper">
              <div className="flex-row">
                <label htmlFor="mediaBlacklist">Media Blacklist </label>
                <div className="icon-btn">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('mediaBlacklist')}
                  />
                  {tooltip === 'mediaBlacklist' && (
                    <p className="tooltip shadow-lg">
                      Optional moderation tool. Block any words or phrases in
                      YouTube video titles for media. Does not scam user. Enter
                      blacklisted terms seperated by a space. Use underscore for
                      phrases. E.g. fuck shit sex_noises
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <input
                  name="mediaBlacklist"
                  placeholder="Media Blacklist"
                  onChange={handleChange}
                  value={formData.mediaBlacklist}
                  disabled={isMod}
                />
              </div>
            </div>
            <div className="input-row">
              <div className="flex-row">
                <label htmlFor="mediaMinViews">Minimum Media Views</label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('mediaMinViews')}
                  />
                  {tooltip === 'mediaMinViews' && (
                    <p className="tooltip shadow-lg">
                      Optional moderation tool that allows you to set the
                      minimum number of YouTube video views required for media.
                      Does not scam user.
                    </p>
                  )}
                </div>
              </div>
              <div className="tooltip-input">
                <input
                  name="mediaMinViews"
                  placeholder="Minimum View Count"
                  onChange={handleChange}
                  value={formData.mediaMinViews}
                  disabled={isMod}
                />
              </div>
            </div>
          </div>
          <div className="settings-section radio-inputs">
            <div className="input-row">
              <div className="flex-row">
                <ToggleSwitch
                  name="useDefaultProfanity"
                  onChange={() => handleToggle('useDefaultProfanity')}
                  checked={formData.useDefaultProfanity}
                  disabled={isMod}
                />
                <label htmlFor="useDefaultProfanity">
                  Enable TTS Profanity Filter
                </label>
                <div className="icon-btn tooltip-wrapper">
                  <FontAwesomeIcon
                    icon={faInfoCircle}
                    color="#57bad8"
                    onClick={() => toggleTooltip('useDefaultProfanity')}
                  />
                  {tooltip === 'useDefaultProfanity' && (
                    <p className="tooltip shadow-lg">
                      Block words from TTS overlay using the default list of
                      profane words, replacing with asterisks (e.g.
                      &apos;shit&apos; becomes ****). This will be applied{' '}
                      <i>after</i> the TTS blacklist is evaluated.
                    </p>
                  )}
                </div>
              </div>
            </div>
            <div className="flex-row">
              <ToggleSwitch
                name="useDefaultMediaBlacklist"
                onChange={() => handleToggle('useDefaultMediaBlacklist')}
                checked={formData.useDefaultMediaBlacklist}
                disabled={isMod}
              />
              <label htmlFor="useDefaultMediaBlacklist">
                Enable Profanity Media Blacklist
              </label>
              <div className="icon-btn tooltip-wrapper">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="#57bad8"
                  onClick={() => toggleTooltip('useDefaultMediaBlacklist')}
                />
                {tooltip === 'useDefaultMediaBlacklist' && (
                  <p className="tooltip shadow-lg">
                    Block media that has words from the default profanity list
                    in the title. Does not scam user. This will be applied in
                    addition to the custom Media Blacklist.
                  </p>
                )}
              </div>
            </div>
            <div className="flex-row">
              <ToggleSwitch
                name="useDefaultChatFilter"
                onChange={() => handleToggle('useDefaultChatFilter')}
                checked={formData.useDefaultChatFilter}
                disabled={isMod}
              />
              <label htmlFor="useDefaultChatFilter">
                Enable Chat Profanity Filter
              </label>
              <div className="icon-btn tooltip-wrapper">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="#57bad8"
                  onClick={() => toggleTooltip('useDefaultChatFilter')}
                />
                {tooltip === 'useDefaultChatFilter' && (
                  <p className="tooltip shadow-lg">
                    Block words from live chat using the default list of profane
                    words, replacing with asterisks (e.g. &apos;shit&apos;
                    becomes ****).
                  </p>
                )}
              </div>
            </div>
            <div className="flex-row">
              <ToggleSwitch
                name="mediaEnabled"
                onChange={() => handleToggle('mediaEnabled')}
                checked={formData.mediaEnabled}
                disabled={isMod}
              />
              <label htmlFor="mediaEnabled">Media Enabled</label>
              <div className="icon-btn tooltip-wrapper">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="#57bad8"
                  onClick={() => toggleTooltip('mediaEnabled')}
                />
                {tooltip === 'mediaEnabled' && (
                  <p className="tooltip shadow-lg">
                    Allow users to attach a Youtube video that autoplays the
                    audio after the TTS message.
                  </p>
                )}
              </div>
            </div>
            <div className="flex-row">
              <ToggleSwitch
                name="showVideo"
                onChange={() => handleToggle('showVideo')}
                checked={formData.showVideo}
                disabled={isMod}
              />
              <label htmlFor="showVideo">Show Media Video</label>
              <div className="icon-btn tooltip-wrapper">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="#57bad8"
                  onClick={() => toggleTooltip('showVideo')}
                />
                {tooltip === 'showVideo' && (
                  <p className="tooltip shadow-lg">
                    Plays media video in overlay instead of just the audio.
                  </p>
                )}
              </div>
            </div>
            <div className="flex-row">
              <ToggleSwitch
                name="playTtsTitle"
                onChange={() => handleToggle('playTtsTitle')}
                checked={formData.playTtsTitle}
                disabled={isMod}
              />
              <label htmlFor="playTtsTitle">Play TTS Title</label>
              <div className="icon-btn tooltip-wrapper">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="#57bad8"
                  onClick={() => toggleTooltip('playTtsTitle')}
                />
                {tooltip === 'playTtsTitle' && (
                  <p className="tooltip shadow-lg">
                    Play TTS username and amount before reading the TTS message.
                  </p>
                )}
              </div>
            </div>
            <div className="flex-row">
              <ToggleSwitch
                name="donatorSelectVoice"
                onChange={() => handleToggle('donatorSelectVoice')}
                checked={formData.donatorSelectVoice}
                disabled={isMod}
              />
              <label htmlFor="chatSelectVoiceEnabled">
                Chat Select Voice Enabled
              </label>
              <div className="icon-btn tooltip-wrapper">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  color="#57bad8"
                  onClick={() => toggleTooltip('chatSelectVoiceEnabled')}
                />
                {tooltip === 'chatSelectVoiceEnabled' && (
                  <p className="tooltip shadow-lg">
                    Allows users to choose the TTS Voice from all voices.
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>

        <hr className="hr-light-blue" />
      {!isMod && (
        <div>
          <button type="submit">Save</button>
          <button
            type="button"
            className="secondary-btn cancel-btn"
            onClick={cancelSettingsUpdate}
          >
            Cancel
          </button>
        </div>
      )}
      </form>
      <FormAlert alertState={alertState} width={450} />
    </div>
  );
};

export default SettingsForm;
