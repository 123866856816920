import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import '../css/donations.css';
import ToggleSwitch from './toggle-switch';
import lodash from 'lodash';
import StripePay from './stripe-pay';
import SquarePay from './square-pay';
import PaypalButtons from './paypal-buttons';
import CryptoPayDialog from './dialogs/crypto-pay-dialog';
import CurrencyInput from 'react-currency-input-field';
import FormAlert from './form-alert';
import Captcha from './captcha';
import FollowButton from '../components/follow-button';
import { PayPalScriptProvider } from '@paypal/react-paypal-js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import FadeLoader from 'react-spinners/FadeLoader';
import {
  findTtsVoice,
  ttsVoiceOptions,
  ttsVoiceOptionsDisableSubs,
} from '../static/tts-voices';
import { containsProfanity } from '../../server/api/textFilters';
import VoiceSelect from './voice-select';

const Donations = ({
  user,
  tipUserId,
  tipUsername,
  paidChatData,
  subscription,
  ttsVoice,
  mediaLimit,
  setDropdownSpace,
  captchaDone,
  setCaptchaDone,
  captchaToken,
  setCaptchaToken,
  profanity,
  onFollowerCountChange,
}) => {
  const pageOwner =
    user &&
    tipUsername &&
    user.username.toLowerCase() === tipUsername.toLowerCase();
  const maxNameLength = 80;
  const maxMessageLength = 250;
  const [alertState, setAlertState] = useState({
    message: '',
    success: true,
  });
  const [loading, setLoading] = useState(false);
  const [googlePayLoaded, setGooglePayLoaded] = useState(false);
  const [squareLoaded, setSquareLoaded] = useState(false);
  const [donationIsAnonymous, setDonationIsAnonymous] = useState(false);
  const [paymentMenuIsVisible, setPaymentMenuIsVisible] = useState(false);
  const [usingPaypalCard, setUsingPaypalCard] = useState(false);
  const [cryptoDialogOpen, setCryptoDialogOpen] = useState(false);

  const [enoughForDonation, setEnoughForDonation] = useState(true);
  const [enoughForMedia, setEnoughForMedia] = useState(true);
  const [mediaTitle, setMediaTitle] = useState('');
  const [numberAmount, setNumberAmount] = useState(0);
  const [validForm, setValidForm] = useState(false);
  const [validMedia, setValidMedia] = useState(true);
  const [validName, setValidName] = useState(true);
  const [hasProfanity, setHasProfanity] = useState(false);
  const [availableVoiceOptions, setAvailableVoiceOptions] = useState([]);

  const [formData, setFormData] = useState({
    username: user ? user.username : null,
    message: '',
    amount: '',
    media: '',
  });

  const scrollRef = useRef();

  useEffect(() => {
    const existingScript = document.getElementById('googlepay');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://pay.google.com/gp/p/js/pay.js';
      script.id = 'googlepay';
      document.body.appendChild(script);
      script.onload = () => {
        setGooglePayLoaded(true);
      };
    } else {
      setGooglePayLoaded(true);
    }
  }, []);

  useEffect(() => {
    const existingScript = document.getElementById('squarepay');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src =
        process.env.ENVIRONMENT === 'prod'
          ? 'https://web.squarecdn.com/v1/square.js'
          : 'https://sandbox.web.squarecdn.com/v1/square.js';
      script.id = 'squarepay';
      document.body.appendChild(script);
      script.onload = () => {
        setSquareLoaded(true);
      };
    } else {
      setSquareLoaded(true);
    }
  }, []);

  const captchaCallback = async (token) => {
    setCaptchaDone(true);
    setCaptchaToken(token);
  };

  const [ttsVoiceSelected, setTtsVoiceSelected] = useState(findTtsVoice());

  useEffect(() => {
    setTtsVoiceSelected(findTtsVoice(ttsVoice));
  }, [ttsVoice]);

  useEffect(() => {
    if (pageOwner || subscription.status === 'active') {
      setAvailableVoiceOptions(ttsVoiceOptions);
    } else {
      setAvailableVoiceOptions(ttsVoiceOptionsDisableSubs);
    }
  }, [subscription, pageOwner]);

  const ttsStyles = {
    control: (styles) => ({
      ...styles,
      width: '150px',
      height: '42px',
      margin: '10px 0 -2px 0',
      border: 'none',
      outline: 'none',
      whiteSpace: 'nowrap',
    }),
    groupHeading: (styles) => ({
      ...styles,
      fontWeight: 'bold',
      fontSize: 13,
    }),
    option: (styles, { isDisabled }) => ({
      ...styles,
      color: isDisabled ? '#ccc' : 'black',
    }),
    input: (styles) => ({
      ...styles,
      cursor: 'text',
    }),
  };

  const donationAmounts = paidChatData && paidChatData.customDonationAmounts && paidChatData.customDonationAmounts.length > 0
  ? paidChatData.customDonationAmounts
  : ['3', '5', '10', '15'];

  const handleChange = (event) => {
    setFormData({
      ...formData,
      [event.target.name]: event.target.value,
    });
  };

  const handleToggle = () => {
    setFormData({
      ...formData,
      username: '',
    });
    setDonationIsAnonymous(!donationIsAnonymous);
  };

  const updateAmount = (amount) => {
    setFormData({
      ...formData,
      amount,
    });
  };

  const scrollToPaymentContainer = () => {
    scrollRef.current.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const togglePaymentMenu = () => {
    if (!validForm) {
      return;
    }
    if (!paymentMenuIsVisible) {
      scrollToPaymentContainer();
    }
    setPaymentMenuIsVisible(!paymentMenuIsVisible);
  };

  useEffect(() => {
    let height = Math.max(0, scrollRef.current.offsetHeight - 50);
    setDropdownSpace(paymentMenuIsVisible ? height : 0);
  }, [paymentMenuIsVisible]);

  useEffect(() => {
    if (!usingPaypalCard) {
      return;
    }
    let height = scrollRef.current.offsetHeight + 250;
    setDropdownSpace(paymentMenuIsVisible ? height : 0);
  }, [usingPaypalCard]);

  useEffect(() => {
    let amount = formData.amount || '0';
    let amountString = String(amount).replace('$', '');
    setNumberAmount(parseFloat(amountString));
  }, [formData.amount]);

  useEffect(() => {
    validateName();
  }, [formData.username]);

  useEffect(() => {
    let valid =
      validName &&
      validMedia &&
      enoughForMedia &&
      enoughForDonation &&
      numberAmount >= 1 &&
      !hasProfanity &&
      captchaDone;
    setValidForm(valid);
    if (!valid) {
      setPaymentMenuIsVisible(false);
    }
  }, [
    validName,
    validMedia,
    enoughForMedia,
    enoughForDonation,
    numberAmount,
    captchaDone,
    hasProfanity,
  ]);

  const validateName = () => {
    let valid = true;
    if (paidChatData.streamlabsLinked && formData.username) {
      // Only alphanumeric characters and spaces are allowed
      let filtered = formData.username.replace(/[^0-9A-Z ]/gi, '');
      // Character limit is 25
      filtered = filtered.substring(0, 25);
      valid = filtered == formData.username;
    }
    setValidName(valid);
  };

  const checkMediaAmount = () => {
    if (formData.media && numberAmount && numberAmount < mediaLimit) {
      setEnoughForMedia(false);
    } else {
      setEnoughForMedia(true);
    }
  };

  const checkDonationAmount = () => {
    if (paidChatData.donationLimit && numberAmount && numberAmount < paidChatData.donationLimit) {
      setEnoughForDonation(false);
    } else {
      setEnoughForDonation(true);
    }
  };

  const validateMedia = async () => {
    checkMediaAmount();
    if (formData.media) {
        try {
            const response = await axios.get('/pubapi/validateMedia', {
                params: { media: formData.media, username: tipUsername },
            });
            setMediaTitle(response.data.mediaTitle);
            setValidMedia(true);
            return true;
        } catch (err) {
            setValidMedia(false);
            return false;
        }
    }
    setValidMedia(true);
    return true;
  };

  const checkProfanity = () => {
    setHasProfanity(
      containsProfanity(formData.message, profanity.current) ||
        (paidChatData.donatorSelectVoice &&
          containsProfanity(formData.username, profanity.current))
    );
  };

  useEffect(() => {
    if (ttsVoiceSelected && ttsVoiceSelected.voiceTypeId == 10) {
      checkProfanity();
    } else {
      setHasProfanity(false);
    }
  }, [formData.username, formData.message, ttsVoiceSelected]);

  useEffect(() => {
    validateMedia();
  }, [formData.media]);

  useEffect(() => {
    checkMediaAmount();
    checkDonationAmount();
  }, [numberAmount]);

  const getDonatorUsername = () =>
    !user || donationIsAnonymous ? formData.username : user.username;

  const getDonatorId = () => !user ? null : user.userId;

  const getPowerchat = () => {
    const { message, media } = formData;
    return {
      amount: numberAmount,
      message: message.substring(0, maxMessageLength),
      username: getDonatorUsername(),
      userId: getDonatorId(),
      media,
      tipUserId,
      mediaTitle,
      tipUsername,
      ttsVoice: ttsVoiceSelected ? ttsVoiceSelected.name : null,
      voiceTypeId: ttsVoiceSelected ? ttsVoiceSelected.voiceTypeId : null,
      anonymous: !user || donationIsAnonymous,
      isSubscriber: subscription.status === 'active',
    };
  };

  const confirmTransaction = (
    orderID,
    amount,
    endpoint = '/confirm',
    paymentPlatform = 'test'
  ) => {
    if (amount < 1) {
      return;
    }
    const powerchat = Object.assign(getPowerchat(), {
      orderID,
      amount,
      paymentPlatform,
    });
    axios.post(endpoint, powerchat);
  };

  const testDonation = () =>
    lodash.throttle(async function () {
      try {
        if (!validForm) {
          throw 'Payment not allowed in current state';
        }
        confirmTransaction(null, numberAmount, '/api/fullTestDonation');
        triggerAlert('Test donation complete!');
      } catch (err) {
        triggerAlert('Could not send test donation', false);
      }
    }, 2000)();

  const triggerAlert = (message, success = true) => {
    setAlertState({
      message,
      success,
    });
  };

  const reloadCaptcha = () => {
    setCaptchaDone(false);
    setCaptchaToken(null);
  };

  const openCryptoDialog = () => {
    if (captchaDone) {
      setCryptoDialogOpen(true);
    }
  };

  const cryptoDialogOnClose = () => {
    setCryptoDialogOpen(false);
    reloadCaptcha();
  };

  /*const cryptoDialogOnComplete = () => {
    setCryptoDialogOpen(false);
  };*/

  const paypalOptions = {
    'client-id': process.env.PAYPAL_CLIENT_ID,
    'merchant-id': paidChatData.paypalMerchantId,
  };

  const spinnerStyle = {
    position: 'relative',
    margin: 'auto',
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-around',
    marginTop: '10px',
  };

  return (
    <div className="donations-container form-container">
      {!!paidChatData.tagLine && paidChatData.tagLine != '' && (
        <div className="tag-line">{paidChatData.tagLine}</div>
      )}
      <div className="flex-form-row">
      {user && (
          <div className="flex-column">
            <div className="details-container">
              <h4>Logged In As:</h4>
              <h4>{user.username}</h4>
            </div>
            <div className="radio-inputs">
              <ToggleSwitch
                name="donationIsAnonymous"
                onChange={handleToggle}
                checked={donationIsAnonymous}
              />
              <label htmlFor="donationIsAnonymous">Send Anonymously</label>
            </div>
          </div>
        )}
        <div>
          {!pageOwner && (
            <FollowButton
            followerUsername={user && user.username}
            streamerUsername={tipUsername}
            hasTooltip={true}
            onFollowerCountChange={onFollowerCountChange}
          />
          )}
        </div>
      </div>
      <form>
        {(!user || donationIsAnonymous) && (
          <div className="input-row">
            <label htmlFor="username">Username</label>
            <input
              name="username"
              placeholder="Leave blank to chat as Anonymous"
              onChange={handleChange}
              maxLength={maxNameLength}
            />
            {!validName && (
              <div className="invalid-message">
                Names with special characters, or over 25 characters, are not
                allowed for this user
              </div>
            )}
          </div>
        )}
        <div className="select-row input-row">
          <div className="input-w100">
            <label htmlFor="message">
              Optional Message
              {paidChatData.ttsLimit && (
                <div className="badge">TTS min: ${paidChatData.ttsLimit}</div>
              )}
            </label>
            <div className="input-group">
              <input
                style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                name="message"
                placeholder="Enter a message"
                maxLength={maxMessageLength}
                onChange={handleChange}
              />
              <div className="input-group-append">
                <span>{maxMessageLength - formData.message.length}</span>
              </div>
            </div>
          </div>

          {paidChatData.donatorSelectVoice && (
            <div className="tts-select-container">
              <label htmlFor="ttsVoice">Choose a voice</label>
              <div className="tts-select" title="Type to search">
                <VoiceSelect
                  options={availableVoiceOptions}
                  styles={ttsStyles}
                  placeholder={ttsVoice || 'Select a voice'}
                  value={ttsVoiceSelected}
                  onChange={(voice) => setTtsVoiceSelected(voice)}
                />
              </div>
            </div>
          )}
        </div>
        {hasProfanity && (
          <div className="invalid-message">
            Slurs are not allowed in this voice.
          </div>
        )}
        {paidChatData.mediaEnabled && (
          <div className="input-row">
            <label htmlFor="media">
              Media
              {paidChatData.mediaLimit && (
                <div className="badge">
                  ${paidChatData.mediaLimit} for {paidChatData.mediaDuration}s
                </div>
              )}
              {paidChatData.extendedMediaLimit && (
                <div className="badge">
                  ${paidChatData.extendedMediaLimit} for{' '}
                  {paidChatData.extendedMediaDuration}s
                </div>
              )}
            </label>
            <input
              name="media"
              placeholder="Enter a YouTube URL"
              onChange={handleChange}
            />
            {!validMedia && (
              <div className="invalid-message">This video is not allowed</div>
            )}
            {!enoughForMedia && (
              <div className="invalid-message">
                You haven&apos;t selected enough for media.
              </div>
            )}
          </div>
        )}
        <div className="amount-container input-row">
          <div className="input-w75">
            <label htmlFor="amount">Amount (USD)</label>
            {paidChatData.donationLimit && (
              <div className="badge">Min: ${paidChatData.donationLimit}</div>
            )}
            <CurrencyInput
              name="amount"
              placeholder="Enter a dollar amount"
              value={formData.amount}
              prefix="$"
              intlConfig={{ locale: 'en-US', currency: 'USD' }}
              allowNegativeValue={false}
              decimalScale={2}
              onValueChange={(value, name) =>
                handleChange({ target: { value, name } })
              }
            />
          </div>
          <div className="donation-amounts-container flex-form-row">
            {donationAmounts.map((donationAmount, idx) => (
              <button
                type="button"
                onClick={() => updateAmount(donationAmount)}
                key={donationAmount + String(idx)}
              >
                ${donationAmount}
              </button>
            ))}
          </div>
        </div>
        {!enoughForDonation && (
          <div className="invalid-message">
            Amount selected is below minimum Powerchat limit.
          </div>
        )}
        {!captchaDone && (
          <div className="paid-chat-captcha">
            <Captcha
              captchaDone={captchaDone}
              captchaCallback={captchaCallback}
            />
          </div>
        )}
        <div>
          <div
            onClick={togglePaymentMenu}
            className={'payment-container' + (validForm ? '' : ' disabled')}
          >
            <div className="dropdown-button">
              <div className="placeholder"></div>
              <p>Select Payment Method</p>
              <div>
                <FontAwesomeIcon icon={faChevronDown} />
              </div>
            </div>
            <div
              ref={scrollRef}
              className={
                'payment-methods-container' +
                (paymentMenuIsVisible ? '' : ' collapsed')
              }
            >
              {process.env.STRIPE_ENABLED == 'true' &&
                paidChatData.stripeLinked && (
                  <StripePay
                    getPowerchat={getPowerchat}
                    reloadCaptcha={reloadCaptcha}
                    stripeAccount={paidChatData.stripeUserId}
                    disabled={!validForm}
                    googlePayLoaded={googlePayLoaded}
                    setLoading={setLoading}
                    completed={triggerAlert}
                    captchaToken={captchaToken}
                  />
                )}
              {paidChatData.squareLinked && (
                <SquarePay
                  getPowerchat={getPowerchat}
                  reloadCaptcha={reloadCaptcha}
                  squareAccount={paidChatData.squareUserId}
                  squareLoaded={squareLoaded}
                  disabled={!validForm}
                  setLoading={setLoading}
                  completed={triggerAlert}
                  captchaToken={captchaToken}
                />
              )}
              {paidChatData.cryptoSetup && paidChatData.cryptoEnabled && (
                <div>
                  <hr
                    style={{
                      maxWidth: 300,
                      margin: '10px auto',
                      opacity: 1,
                      height: 1.5,
                      backgroundColor: 'black',
                    }}
                  />
                  <div
                    onClick={openCryptoDialog}
                    className="crypto-button-wrapper"
                  >
                    <img src="/static/img/powerchat-crypto.jpg" />
                  </div>
                </div>
              )}

              {paidChatData.showPaypal && (
                <div className="paypal-buttons-container">
                  <PayPalScriptProvider options={paypalOptions}>
                    <PaypalButtons
                      paidChatData={paidChatData}
                      validForm={validForm}
                      confirmTransaction={confirmTransaction}
                      completed={triggerAlert}
                      setLoading={setLoading}
                      setUsingPaypalCard={setUsingPaypalCard}
                      reloadCaptcha={reloadCaptcha}
                      numberAmount={numberAmount}
                      captchaToken={captchaToken}
                    />
                  </PayPalScriptProvider>
                </div>
              )}
            </div>
          </div>
        </div>
        {pageOwner && (
          <button
            className={'test-donation-btn' + (validForm ? '' : ' disabled')}
            type="button"
            onClick={testDonation}
          >
            Test Donation Alert
          </button>
        )}
      </form>
      <CryptoPayDialog
        open={cryptoDialogOpen}
        /*onComplete={cryptoDialogOnComplete}*/
        onClose={cryptoDialogOnClose}
        getPowerchat={getPowerchat}
        captchaToken={captchaToken}
        initialCoin={paidChatData.cryptoCoin}
      />
      <FormAlert alertState={alertState} width={400} />
      <div style={spinnerStyle}>
        <FadeLoader color="#ffffff" loading={loading} size={20} />
      </div>
    </div>
  );
};

export default Donations;
