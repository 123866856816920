const { ToWords } = require('to-words');
const { coinNames } = require('../src/static/coin-names');

const urlRegex = require('url-regex');
const moneyRegex = /\$[0-9]+\.?([0-9]{2})?/

const toWords = new ToWords({
  localeCode: 'en-US',
  converterOptions: {
    currency: true,
    ignoreDecimal: false,
    ignoreZeroCurrency: false,
  },
});

const createTtsStrings = (data) => {
  let action;
  if (data.isSubscription) {
    action = data.streak > 1 ? 'resubscribed for' : 'subscribed for';
  }
  else {
    switch (data.paymentPlatform) {
      case 'youtube':
        action = 'superchatted';
        break;
      default:
        action = 'sent';
        break;
    }
  }

  let title;
  if (data.isSubscription && data.streak > 1) {
    title = `${data.donator} is on a ${data.streak}-month streak`;
  } else if (!data.isPlainMessage) {
    let amountWords = data.amount
      ? toWords.convert(Number(data.amount), { currency: true })
      : '';
    title = `${data.donator} ${action} ${amountWords}`;
    if (coinNames[data.paymentPlatform]) {
      title += ` in ${coinNames[data.paymentPlatform]}`;
    }
    title = title.replace(/only/gi, '');
  }

  let speechMessage = data.message;
  if (data.subMessage) {
    speechMessage += '. ' + data.subMessage;
  }
  speechMessage = speechMessage.replace(urlRegex({strict:false}), '');
  speechMessage = speechMessage.replace(moneyRegex, (match) => {
    return toWords.convert(Number(match.replace('$', '')), { currency: true })
      .replace(/only/gi, '');
  });
  let audioText = data.playTTSTitle ? title + ' ' + speechMessage : speechMessage;
  return {
    action,
    title: title,
    audioText: audioText,
  };
};

module.exports = {
  createTtsStrings,
};
