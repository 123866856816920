//Regex for checking for extended characters. MUST include everything in extended_dict
const extended_rgx = /[À-ß!@$|35]/gi;
const extended_dict = {
  0: 'o',
  3: 'e',
  5: 's',
  '¢': 'c',
  '₵': 'C',
  À: 'A',
  Á: 'A',
  Â: 'A',
  Ã: 'A',
  Ä: 'A',
  Å: 'A',
  Æ: 'A',
  Ç: 'C',
  È: 'E',
  É: 'E',
  Ê: 'E',
  Ë: 'E',
  Ì: 'I',
  Í: 'I',
  Î: 'I',
  Ï: 'I',
  Ð: 'D',
  Ñ: 'N',
  Ò: 'O',
  Ó: 'O',
  Ô: 'O',
  Õ: 'O',
  Ö: 'O',
  Ø: 'O',
  Ù: 'U',
  Ú: 'U',
  Û: 'U',
  Ü: 'U',
  Ý: 'Y',
  Þ: 'D',
  à: 'a',
  á: 'a',
  â: 'a',
  ã: 'a',
  ä: 'a',
  å: 'a',
  æ: 'a',
  '@': 'a',
  þ: 'b',
  ß: 'b',
  ç: 'c',
  ð: 'd',
  è: 'e',
  é: 'e',
  ê: 'e',
  ë: 'e',
  ì: 'i',
  í: 'i',
  î: 'i',
  ï: 'i',
  '|': 'i',
  '!': 'i',
  ñ: 'n',
  ò: 'o',
  ó: 'o',
  ô: 'o',
  õ: 'o',
  ö: 'o',
  ø: 'o',
  $: 's',
  ù: 'u',
  ú: 'u',
  û: 'u',
  ü: 'u',
  '×': 'x',
  ÿ: 'y',
  ý: 'y',
};

const containsProfanity = (text, profanity) => {
  if (text == null || text.length == 0) {
    return false;
  }
  const wholeWords = profanity.filter(x => x.match_word).map(x => x.phrase);
  const partWords = profanity.filter(x => !x.match_word).map(x => x.phrase);

  //Replace extended characters with plain alphabet
  let plainText = text;
  let hasExtendedChars = text.match(extended_rgx);
  if (hasExtendedChars) {
    for (let letter in extended_dict) {
      plainText = plainText.replace(new RegExp(letter, 'gi'), extended_dict[letter]);
    }
  }

  let expression = '';
  if (wholeWords.length > 0) {
    expression = '\\b(' + wholeWords.join('|') + ')\\b';
  }
  if (partWords.length > 0) {
    if (expression.length > 0) {
      expression += '|';
    }
    expression += '(' + partWords.join('|') + ')';
  }
  let regex = new RegExp(expression, 'gi');
  return plainText.match(regex) != null;
};

const filterProfanity = (text, profanity) => {
  if (text == null) {
    return text;
  }
  const wholeWords = profanity.filter(x => x.match_word).map(x => x.phrase);
  const partWords = profanity.filter(x => !x.match_word).map(x => x.phrase);

  //Replace extended characters with plain alphabet
  let plainText = text;
  let hasExtendedChars = text.match(extended_rgx);
  if (hasExtendedChars) {
    for (let letter in extended_dict) {
      plainText = plainText.replace(new RegExp(letter, 'gi'), extended_dict[letter]);
    }
  }

  let expression = '';
  if (wholeWords.length > 0) {
    expression = '\\b(' + wholeWords.join('|') + ')\\b';
  }
  if (partWords.length > 0) {
    if (expression.length > 0) {
      expression += '|';
    }
    expression += '(' + partWords.join('|') + ')';
  }
  let regex = new RegExp(expression, 'gi');
  let filteredText = plainText.replace(regex, (match) => '*'.repeat(match.length));

  //Add back any replaced extended characters
  if (hasExtendedChars) {
    let arr = Array.from(text);
    for (let i = 0; i < arr.length; i++) {
      if (filteredText[i] == '*') {
        arr[i] = '*';
      }
    }
    filteredText = arr.join('');
  }
  return filteredText;
};

module.exports = {
  filterProfanity,
  containsProfanity,
};
