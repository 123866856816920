import React, { useState } from 'react';
import '../css/community.css';
import Subscribers from './subscribers.jsx';
import Followers from './followers.jsx';

const Community = ({ community }) => {
  const [subtab, setSubtab] = useState('subscribers');
    
  const selectSubtab = (subtab) => {
    setSubtab(subtab);
    //window.history.replaceState(null,null, '?tab=community&subtab=' + subtab);
  }

  return (
    <div className="form-container community">
      <div className="community-tabs">
        <button
          onClick={() => selectSubtab('subscribers')}
          className={
            'secondary-btn' + `${subtab === 'subscribers' ? ' active' : ''}`
          }
        >
          Subscribers
        </button>
        <button
          onClick={()=> selectSubtab('followers')}
          className={
            'secondary-btn' + `${subtab === 'followers' ? ' active' : ''}`
          }
        >
          Followers
        </button>
        </div>
      {subtab === 'subscribers' && (
        <Subscribers community={community} />
      )}
       {subtab === 'followers' && (
        <Followers list={community.followers} />
      )} 
    </div>
  );
};

export default Community;
