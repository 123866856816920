import React, { useState, useEffect, useMemo } from 'react';
import { getRandomNumber } from '../../../util/random-number.js';

const DecompressionEmote = (props) => {
  const { emote, maxSize, speed } = props;
  
  const [emoteCount, setEmoteCount] = useState(getRandomNumber(5, 15));
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    setEmoteCount(getRandomNumber(5, 15));
  }, [emote]);

  const debrisStyles = useMemo(() => {
    return Array.from({ length: emoteCount }).map(() => {
      const size = getRandomNumber(maxSize / 4, maxSize / 2);
      const randomDirectionX = (Math.random() - 0.5) * 2 * 50; // value between -100% and 100%
      const randomDirectionY = (Math.random() - 0.5) * 2 * 50; // value between -100% and 100%
      return {
        size,
        directionX: `${randomDirectionX}vw`,
        directionY: `${randomDirectionY}vh`
      };
    });
  }, [maxSize, emoteCount]);

  useEffect(() => {
    setTimeout(() => {
      setIsVisible(false);
    }, (speed + 1.5) * 1000); // Add an additional 1.5 seconds for the fade-out
  }, [speed]);

  if (!isVisible) {
    return null;
  }

  return (
    <div className="emote-center-container">
      {debrisStyles.map((debris, index) => (
        <img
          key={index}
          className="emote"
          src={emote}
          alt=""
          style={{
            maxWidth: `${debris.size}px`,
            maxHeight: `${debris.size}px`,
            animation: `fadeIn 0.5s, moveAway ${speed}s forwards, fadeOut 0.8s forwards ${speed + 0.2}s`,
            '--translateX': debris.directionX,
            '--translateY': debris.directionY
          }}
        />
      ))}
    </div>
  );
};

export default React.memo(DecompressionEmote);
